import React from "react";

import { COLOR } from "../component.constants";

export interface Props {
    color: COLOR;
}

const AfterpaySilhouetteOverlay: React.FC<Props> = (props: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            fill="none"
            viewBox="0 0 48 48"
        >
            <path
                fill={props.color}
                fillRule="evenodd"
                d="M13.503 6.495c4.66-8.66 16.334-8.66 20.994 0l11.87 22.06C51 37.16 45.237 48 35.872 48H12.129C2.764 48-2.999 37.16 1.632 28.555l11.871-22.06z"
                clipRule="evenodd"
            />
        </svg>
    );
};

export default AfterpaySilhouetteOverlay;
