import tailwindConfig from "../tailwind.config.js";

export const ACCENT_COLORS = {
    LEMON_DARK: "LemonDark",
    LEMON_PASTEL: "LemonPastel",
    ORCA_DARK: "OrcaDark",
    ORCA_PASTEL: "OrcaPastel",
    NONE: "None",
    PLUM: "Plum",
    BLACK: "Black",
    MINT: "Mint",
    FIRE: "Fire",
} as const;
export type ACCENT_COLOR = typeof ACCENT_COLORS[keyof typeof ACCENT_COLORS];

export const COLORS = tailwindConfig.theme.colors;
export type COLOR = typeof COLORS[keyof typeof COLORS];

export const SIZES = {
    LARGE: "Large",
    SMALL: "Small",
} as const;
export type SIZE = typeof SIZES[keyof typeof SIZES];
