import classNames from "classnames/bind";
import React from "react";

import { ACCENT_COLOR, ACCENT_COLORS as BG_COLORS } from "../component.constants";
import { KINDS } from "./education-grids.constants";
import styles from "./educationGrids.module.scss";

const cx = classNames.bind(styles);

export type Grid = {
    altText: string;
    image: string;
    subheader: string;
    textBody: string;
};

export interface Props {
    bgColor: ACCENT_COLOR;
    kind: KINDS;
    grids: Grid[];
}

export const EducationGrid: React.FC<Props> = (props: Props) => {
    const { bgColor, kind, grids } = props;

    const edGridContainerClassName = cx({
        [styles.edGridContainer]: true,
        [styles.edGridContainerFull]: kind === KINDS.FULL,
        [styles.edGridContainerIndented]: kind === KINDS.INDENTED,
        [styles.bgLemonDark]: bgColor === BG_COLORS.LEMON_DARK,
        [styles.bgLemonPastel]: bgColor === BG_COLORS.LEMON_PASTEL,
        [styles.bgOrcaDark]: bgColor === BG_COLORS.ORCA_DARK,
        [styles.bgOrcaPastel]: bgColor === BG_COLORS.ORCA_PASTEL,
    });
    return (
        <div className={edGridContainerClassName}>
            {grids.map((grid) => {
                return (
                    <div className={styles.edGridChildContainer}>
                        <div className={styles.edGridImageContainer}>
                            <img src={grid.image} alt={grid.altText} />
                        </div>
                        <div className={styles.edGridTextContainer}>
                            <h2 className={styles.edGridSubheader}>{grid.subheader}</h2>
                            <p className={styles.edGridTextBody}>{grid.textBody}</p>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
