import { BuilderConfig } from "@/builder/types";

import HowItWorks, { Props } from "./how-it-works";

export const FullBleedConfig = {
    name: "How It Works",
    inputs: [
        {
            name: "slides",
            type: "list",
            required: true,
            onChange: (options: Map<string, Props["slides"]>): void => {
                if (options.get("slides").length > 5) {
                    options.set("slides", options.get("slides").slice(0, 5));
                    alert("You've reached the maximum number of slides.");
                }
            },
            defaultValue: [
                {
                    header: "Step 1",
                    subheader:
                        "Browse all your favorite places to shop online or in retail stores near you.",
                    body: "Afterpay works online or in-store wherever you are used to shopping.",
                    backgroundImage: "/iphone-body1.png",
                    imageAltText: "Image1",
                },
                {
                    header: "Step 2",
                    subheader:
                        "Browse all your favorite places to shop online or in retail stores near you.",
                    body: "Afterpay works online or in-store wherever you are used to shopping.",
                    backgroundImage: "/iphone-body2.png",
                    imageAltText: "Image2",
                },
            ],
            subFields: [
                {
                    name: "header",
                    type: "string",
                    required: true,
                    regex: {
                        // pattern to test, like "^\/[a-z]$"
                        pattern: "^.{1,10}$",
                        // flags for the RegExp constructor, e.g. "gi"  */
                        options: "g",
                        // message to display to end-users if the regex fails
                        message: "No more than 10 characters allowed",
                    },
                    onChange: `return (${((options) => {
                        const MAX_STRING_LENGTH = 10;
                        if (
                            options.get("header") &&
                            options.get("header").length > MAX_STRING_LENGTH
                        ) {
                            options.set(
                                "header",
                                options.get("header").substring(0, MAX_STRING_LENGTH)
                            );
                        }
                    }).toString()}).apply(this, arguments)`,
                },
                {
                    name: "subheader",
                    type: "string",
                    required: true,
                },
                {
                    name: "body",
                    type: "string",
                    required: false,
                },
                {
                    name: "backgroundImage",
                    type: "file",
                    helperText:
                        "Use vertical images that are 2-3x resolution to ensure images render nicely on retina devices",
                    allowedFileTypes: ["jpg", "jpeg", "png"],
                    required: true,
                },
                {
                    name: "imageAltText",
                    type: "string",
                    required: false,
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: HowItWorks,
    config: FullBleedConfig,
};

export default builderConfig;
