import { useTranslation } from "next-i18next";
import React from "react";

import EVENTS from "../../analytics/events";
import { CarouselHeaderProps } from "../../interfaces/carousel-interface";
import { KINDS, SIZES } from "../buttons/buttons.constants";
import LinkButton from "../buttons/link-button";
import styles from "./carousel.module.scss";

const CardCarouselHeader: React.FC<CarouselHeaderProps> = ({
    title,
    ctaUrl,
    ctaNewTab,
    uiContext,
    eventProps,
    showViewMore = true,
    subTitle,
}) => {
    const { t } = useTranslation();
    return (
        <div className={styles.headerContainer}>
            <h2 className={styles.headerTitle}>
                {title}
                {subTitle && (
                    <>
                        : <span className={styles.subTitle}>{subTitle}</span>
                    </>
                )}
            </h2>

            <div className={styles.headerButton}>
                {showViewMore && (
                    <LinkButton
                        disabled={false}
                        fluid={false}
                        kind={KINDS.CAROUSEL}
                        label={t("buttons.viewAll")}
                        size={SIZES.UNSET}
                        url={ctaUrl}
                        newTab={ctaNewTab}
                        show={true}
                        overrideFocusBorder={false}
                        analytics={{
                            eventName: EVENTS.SHOP_DIRECTORY_CATEGORY_LIST_CLICK,
                            uiContext,
                            eventProps: { ...eventProps, title: "View All" },
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default CardCarouselHeader;
