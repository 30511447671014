import classNames from "classnames/bind";
import React from "react";

import { COLOR, COLORS } from "../../component.constants";
import { ICON, ICONS } from "../../icons";
import IconOverlay from "../../icons/icon-overlay";
import Splitter, { Props as SplitterProps } from "../splitter";
import styles from "../splitter.module.scss";

export interface Section {
    headline: string;
    body: string;
    icon: keyof ICON;
    iconColor: keyof COLOR;
    overlayColor: keyof COLOR;
}

export type Props = Omit<SplitterProps, "children"> & {
    sections: Section[];
};

const cx = classNames.bind(styles);

const SplitterIcons: React.FC<Props> = (props: Props) => {
    const { sections, ...rest } = props;

    const numberedSectionClassName = cx({
        [styles.numberedSection2Cols]: sections.length === 2,
        [styles.numberedSection3Cols]: sections.length === 3,
        [styles.numberedSection4Cols]: sections.length === 4,
    });

    return (
        <Splitter {...rest}>
            {sections.map((section, index) => {
                const { icon, iconColor, body, headline, overlayColor } = section;
                const Icon = ICONS[icon];

                return (
                    <div className={numberedSectionClassName} key={`section-${index}`}>
                        <div className={styles.icon}>
                            <IconOverlay color={COLORS[overlayColor]}>
                                <Icon color={COLORS[iconColor]} width={32} height={32} />
                            </IconOverlay>
                        </div>
                        <h3 className={styles.headline}>{headline}</h3>
                        <p className={styles.body}>{body}</p>
                    </div>
                );
            })}
        </Splitter>
    );
};

export default SplitterIcons;
