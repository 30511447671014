import { BuilderConfig } from "../../../builder/types";
import { ACCENT_COLORS } from "../../component.constants";
import SplitterNumbers, { Props } from "./splitter-numbers";

export const splitterNumbersBuilderConfig = {
    name: "SplitterNumbers",
    inputs: [
        {
            name: "title",
            type: "string",
            defaultValue: "Title",
            required: true,
        },
        {
            name: "subtitle",
            type: "string",
            defaultValue: "Subtitle",
            required: true,
        },
        {
            name: "accentColor",
            type: "string",
            enum: Object.values(ACCENT_COLORS),
            defaultValue: ACCENT_COLORS.ORCA_PASTEL,
            required: true,
        },
        {
            name: "fullWidth",
            type: "boolean",
            defaultValue: false,
            required: true,
        },
        {
            name: "showNumbers",
            type: "boolean",
            defaultValue: true,
            required: true,
        },
        {
            name: "sections",
            type: "list",
            required: true,
            defaultValue: [
                {
                    headline: "Placeholder headline",
                    body: "Placeholder body",
                },
                {
                    headline: "Placeholder headline",
                    body: "Placeholder body",
                },
            ],
            subFields: [
                {
                    name: "headline",
                    type: "string",
                },
                {
                    name: "body",
                    type: "string",
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: SplitterNumbers,
    config: splitterNumbersBuilderConfig,
};

export default builderConfig;
