import React, { useState, useEffect } from "react";

import useViewport from "../../hooks/useViewport";
import { ReviewProps } from "../../interfaces/trustpilot-reviews-interface";
import CardCarousel from "../carousel/carousel";
import { ReviewTile } from "./trustpilot-review-tile";
import styles from "./trustpilot.module.scss";

function breakpointSlidesValue(isMobile: boolean) {
    return isMobile
        ? { slidesToShow: 1, slidesToScroll: 1 }
        : { slidesToShow: 4, slidesToScroll: "auto" as const };
}

export interface Props {
    reviews: Array<ReviewProps>;
}
const Reviews: React.FC<Props> = ({ reviews }) => {
    const { isMobile } = useViewport();
    const [carouselProps, setCarouselProps] = useState(breakpointSlidesValue(isMobile));

    useEffect(() => {
        setCarouselProps(breakpointSlidesValue(isMobile));
    }, [isMobile]);

    return (
        <CardCarousel
            carouselType={"default"}
            slidesToShow={carouselProps.slidesToShow}
            slidesToScroll={carouselProps.slidesToScroll}
        >
            {reviews.map((review: ReviewProps) => (
                <ReviewTile review={review} />
            ))}
        </CardCarousel>
    );
};

export const TrustpilotReviewsList: React.FC<Props> = (props) => {
    const { reviews } = props;

    return (
        <div className={styles.trustpilotListContainer}>
            <Reviews reviews={reviews} />
        </div>
    );
};
