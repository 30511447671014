import classNames from "classnames/bind";
import React from "react";

import Splitter, { Props as SplitterProps } from "../splitter";
import styles from "../splitter.module.scss";

export interface Section {
    headline: string;
    body: string;
}

export type Props = Omit<SplitterProps, "children"> & {
    sections: Section[];
    showNumbers: boolean;
};

const cx = classNames.bind(styles);

const SplitterNumbers: React.FC<Props> = (props: Props) => {
    const { sections, showNumbers, ...rest } = props;
    return (
        <Splitter {...rest}>
            {sections.map((section, index) => {
                const numberedSectionClassName = cx({
                    [styles.numberedSection2Cols]: sections.length === 2,
                    [styles.numberedSection3Cols]: sections.length === 3,
                    [styles.numberedSection4Cols]: sections.length === 4,
                });

                return (
                    <div className={numberedSectionClassName} key={`section-${index}`}>
                        {showNumbers && <h1 className={styles.number}>{index + 1}</h1>}
                        <h3 className={styles.headline}>{section.headline}</h3>
                        <p className={styles.body}>{section.body}</p>
                    </div>
                );
            })}
        </Splitter>
    );
};

export default SplitterNumbers;
