import { SHOP_DIRECTORY_API_HOST } from "@/shared/constants";

import { AsyncPropsMapper, BuilderConfig } from "@/builder/types";

import { KINDS, SIZES } from "@/components/buttons/buttons.constants";

import { APICategoryProps } from "@/interfaces/category-interface";

import SuperWide, { Props } from "./super-wide";

const mapper: AsyncPropsMapper<{ data: Array<APICategoryProps> }> = ({ data }) => {
    return {
        categorySlug: data.data.map((category: APICategoryProps) => ({
            name: category.attributes.name,
            value: category.attributes.slug,
        })),
    };
};

export const SuperWideConfig = {
    name: "Super Wide",
    inputs: [
        {
            friendlyName: "Show on Index page",
            name: "showOnIndex",
            type: "boolean",
            defaultValue: false,
            required: true,
        },
        {
            friendlyName: "Show on Category page",
            name: "categorySlug",
            type: "dynamic-dropdown",
            required: false,
            options: {
                url: `${SHOP_DIRECTORY_API_HOST}/api/v1/categories?locale={{targeting.locale.0}}`,
                mapper: mapper.toString(),
            },
        },
        {
            name: "backgroundImage",
            type: "file",
            allowedFileTypes: ["jpg", "jpeg", "png"],
            required: true,
        },
        {
            name: "heading",
            type: "richText",
            required: true,
            defaultValue: "A nice heading",
        },
        {
            name: "body",
            type: "string",
            required: true,
            defaultValue: "Body content goes here",
        },
        {
            name: "ctas",
            type: "list",
            required: false,
            onChange: (options: Map<string, Props["ctas"]>): void => {
                if (options.get("ctas").length > 2) {
                    options.set("ctas", options.get("ctas").slice(0, 2));
                    alert("You've reached the maximum number of ctas.");
                }
            },
            defaultValue: [
                {
                    label: "Button Label",
                    url: "/",
                    kind: KINDS.PRIMARY,
                    size: SIZES.LARGE,
                    show: true,
                },
            ],
            subFields: [
                {
                    name: "label",
                    type: "string",
                    required: true,
                    defaultValue: "Button Label",
                },
                {
                    name: "url",
                    type: "string",
                    required: true,
                    defaultValue: "https://afterpay.com",
                },
                {
                    name: "kind",
                    type: "string",
                    enum: Object.values(KINDS),
                    defaultValue: KINDS.PRIMARY,
                    required: true,
                },
                {
                    name: "size",
                    type: "string",
                    enum: Object.values(SIZES),
                    defaultValue: SIZES.LARGE,
                    required: true,
                },
                {
                    friendlyName: "Hide when navigation is hidden",
                    helperText: "Hide this CTA when the query parameter hide_navigation=true",
                    name: "hideWhenNavigationHidden",
                    type: "boolean",
                    defaultValue: false,
                    required: false,
                },
                {
                    name: "forwardUtm",
                    type: "boolean",
                    defaultValue: false,
                    required: false,
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: SuperWide,
    config: SuperWideConfig,
};

export default builderConfig;
