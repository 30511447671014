import classNames from "classnames/bind";
import React from "react";

import { ACCENT_COLOR, SIZE } from "../../component.constants";
import styles from "./quoteHighlight.module.scss";

const cx = classNames.bind(styles);

export interface Props {
    author: string;
    authorInfo: string;
    backgroundColor: ACCENT_COLOR;
    text: string;
    size: SIZE;
}

export const QuoteHighlight: React.FC<Props> = (props: Props) => {
    const { author, authorInfo, backgroundColor, size, text } = props;

    const quoteHighlightContainerClassName = cx({
        [styles.quoteHighlightContainer]: true,
        [styles[`background${backgroundColor}`]]: true,
        [styles[`quoteHighlightContainer${size}`]]: true,
    });

    return (
        <div className={quoteHighlightContainerClassName}>
            <p className={styles.text}>{text}</p>
            <div className={styles.authorContainer}>
                <p className={styles.author}>{author}</p>
                <p className={styles.authorInfo}>{authorInfo}</p>
            </div>
        </div>
    );
};
