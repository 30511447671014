import { BuilderConfig } from "../../../builder/types";
import { ACCENT_COLORS } from "../../component.constants";
import SplitterInfographic, { Props } from "./splitter-infographics";

export const splitterInfographicBuilderConfig = {
    name: "SplitterInfographics",
    inputs: [
        {
            name: "title",
            type: "string",
            defaultValue: "Title",
            required: true,
        },
        {
            name: "subtitle",
            type: "string",
            defaultValue: "Subtitle",
            required: true,
        },
        {
            name: "accentColor",
            type: "string",
            enum: Object.values(ACCENT_COLORS),
            defaultValue: ACCENT_COLORS.ORCA_PASTEL,
            required: true,
        },
        {
            name: "fullWidth",
            type: "boolean",
            defaultValue: false,
            required: true,
        },
        {
            name: "sections",
            type: "list",
            required: true,
            defaultValue: [
                {
                    headline: "Placeholder headline",
                    body: "Placeholder body",
                },
                {
                    headline: "Placeholder headline",
                    body: "Placeholder body",
                },
            ],
            subFields: [
                {
                    name: "headline",
                    type: "string",
                    defaultValue: "Placeholder headline",
                },
                {
                    name: "body",
                    type: "string",
                    defaultValue: "Placeholder body",
                },
                {
                    name: "infographic",
                    type: "file",
                    required: true,
                    allowedFileTypes: ["jpeg", "jpg", "png"],
                    defaultValue:
                        "https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d",
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: SplitterInfographic,
    config: splitterInfographicBuilderConfig,
};

export default builderConfig;
