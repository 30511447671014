export type Breakpoint = {
    min: number;
    max: number;
};

export const DESKTOP_BREAKPOINT = {
    min: 1025,
    max: Number.POSITIVE_INFINITY,
};

export const TABLET_BREAKPOINT = {
    min: 768,
    max: 1024,
};

export const MOBILE_BREAKPOINT = {
    max: 767,
    min: 0,
};
