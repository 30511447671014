export const CarouselDesktopProps = {
    slidesToShow: 4,
    cellSpacing: 24,
    withoutControls: false,
    frameOverflow: "hidden",
    slideWidth: 1.0,
};

export const CarouselTabletProps = {
    slidesToShow: 3,
    cellSpacing: 20,
    withoutControls: false,
    frameOverflow: "hidden",
    slideWidth: 1.0,
};

export const CarouselMobileProps = {
    slidesToShow: 1,
    cellSpacing: 8,
    withoutControls: true,
    frameOverflow: "hidden",
    slideWidth: 0.675,
};
