import { Dispatch, SetStateAction, useState } from "react";

import { fadeIn } from "@/utils/transitions";

import { StoreProps } from "@/interfaces/stores-interface";

export interface offerCallbacksProps {
    offerButtonClickHandler: (store: StoreProps) => void;
    isOfferModalsVisible: Record<string, boolean>;
    isOfferModalsFading: Record<string, boolean>;
    setOfferModalsVisible: Dispatch<SetStateAction<Record<string, boolean>>>;
    setOfferModalsFading: Dispatch<SetStateAction<Record<string, boolean>>>;
}
const useOfferCallbacks = (stores: StoreProps[]): offerCallbacksProps => {
    const initialStates = {};

    for (const index in stores) {
        initialStates[stores[index].id] = false;
    }
    const [isOfferModalsVisible, setOfferModalsVisible] = useState(initialStates);
    const [isOfferModalsFading, setOfferModalsFading] = useState(initialStates);

    const offerButtonClickHandler = (store) => {
        fadeIn(
            (state) => setOfferModalsVisible((prevState) => ({ ...prevState, [store.id]: state })),
            (state) => setOfferModalsFading((prevState) => ({ ...prevState, [store.id]: state }))
        );
    };
    return {
        offerButtonClickHandler,
        isOfferModalsVisible,
        isOfferModalsFading,
        setOfferModalsVisible,
        setOfferModalsFading,
    };
};

export default useOfferCallbacks;
