import React, { Dispatch, SetStateAction } from "react";

import type { UI_CONTEXT } from "../../analytics/constants";
import EVENTS from "../../analytics/events";
import { StoreProps } from "../../interfaces/stores-interface";
import { HEADING_LENGTHS, SIZES } from "../../shared/constants";
import { truncateWithEllipsis } from "../../utils/strings";
import { KINDS as BUTTON_KINDS } from "../buttons/buttons.constants";
import LinkButton from "../buttons/link-button";
import styles from "./offer.module.scss";
import { Overlay } from "./overlay";

export type OfferProps = {
    store: StoreProps;
    active?: boolean;
    setVisibleCallback:
        | Dispatch<SetStateAction<boolean>>
        | Dispatch<SetStateAction<Record<string, boolean>>>;
    setFadingCallback:
        | Dispatch<SetStateAction<boolean>>
        | Dispatch<SetStateAction<Record<string, boolean>>>;
    uiContext: UI_CONTEXT;
};

export const Offer: React.FC<OfferProps> = ({
    store,
    active,
    setVisibleCallback,
    setFadingCallback,
    uiContext,
}) => {
    const {
        id,
        name,
        hasLocations,
        offerText,
        offerUrl,
        offerTerms,
        offerTeaser,
        offerCtaTitle,
        offerActive,
        newLogoUrl,
        shopUrl,
        offer_affiliate_network,
    } = store;

    const visibleCallback = (state: boolean) => {
        setVisibleCallback((prevState) => ({ ...prevState, [store.id]: state }));
    };
    const fadingCallback = (state: boolean) => {
        setFadingCallback((prevState) => ({ ...prevState, [store.id]: state }));
    };

    const bodyContents = (
        <React.Fragment>
            <h2 className={styles.largeHeading}>
                {truncateWithEllipsis(offerTeaser, HEADING_LENGTHS.LARGE)}
            </h2>
            <h2 className={styles.regularHeading}>
                {truncateWithEllipsis(offerTeaser, HEADING_LENGTHS.REGULAR)}
            </h2>
            <h2 className={styles.smallHeading}>
                {truncateWithEllipsis(offerTeaser, HEADING_LENGTHS.REGULAR)}
            </h2>
            {offerText && <div className={styles.body}>{offerText}</div>}
            {offerTerms && <div className={styles.footNote}>{offerTerms}</div>}
            {offerCtaTitle && offerUrl && (
                <div className={styles.ctaButton}>
                    <LinkButton
                        disabled={false}
                        fluid
                        kind={BUTTON_KINDS.PRIMARY}
                        label={offerCtaTitle}
                        size={SIZES.REGULAR}
                        show
                        url={offerUrl}
                        newTab
                        analytics={{
                            eventProps: {
                                shopUrl,
                                merchantName: name,
                                merchantId: +id,
                                hasLocations,
                                hasOffer: offerActive,
                                adNetwork: offer_affiliate_network,
                                offerButton: true,
                                paid: !!offer_affiliate_network,
                                offerURL: offerUrl,
                            },
                            eventName: EVENTS.SHOP_DIRECTORY_CLICK,
                            uiContext: uiContext,
                        }}
                    />
                </div>
            )}
        </React.Fragment>
    );

    const footerContents = newLogoUrl ? (
        <div className={styles.logoContainer}>
            <img alt="logo" src={newLogoUrl} className={styles.logo} />
        </div>
    ) : null;

    return (
        <Overlay
            body={bodyContents}
            footer={footerContents}
            active={active}
            setVisibleCallback={visibleCallback}
            setFadingCallback={fadingCallback}
        />
    );
};
