module.exports = function ({ addUtilities }) {
    addUtilities({
        // 12x5 === 2.4x1
        ".aspectRatio12x5": {
            height: 0,
            "padding-top": "41.67%",
        },
        ".aspectRatio16x9": {
            height: 0,
            "padding-top": "56.25%",
        },
        ".aspectRatio1x1": {
            height: 0,
            "padding-top": "100%",
        },
        ".aspectRatio4x3": {
            height: 0,
            "padding-top": "75%",
        },
        ".aspectRatio3x4": {
            height: 0,
            "padding-top": "133.33%",
        },
    });
};
