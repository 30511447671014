import React from "react";

import Grid from "../layout/grid";
import { PartnerLogo, PartnerLogoProps } from "./partner-logo";
import styles from "./partnerLogos.module.scss";

export type Props = {
    header: string;
    logos: Array<PartnerLogoProps>;
};

export const PartnerLogos: React.FC<Props> = ({ header, logos }) => {
    const preview = logos.map(({ partnerLogoUrl, altText, partnerUrl, newTab }) => (
        <PartnerLogo
            partnerLogoUrl={partnerLogoUrl}
            altText={altText}
            partnerUrl={partnerUrl}
            newTab={newTab}
        />
    ));
    return (
        <>
            <div className={styles.partnerLogosHeaderContainer}>
                <p className={styles.partnerLogosHeader}>{header}</p>
            </div>
            <Grid fullHeight fullWidth>
                {preview}
            </Grid>
        </>
    );
};
