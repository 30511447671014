import classNames from "classnames/bind";
import React from "react";

import { HEADING_LENGTHS } from "@/shared/constants";

import { truncateWithEllipsis } from "@/utils/strings";

import ActionButton from "@/components/buttons/action-button";
import { KINDS, SIZES } from "@/components/buttons/buttons.constants";

import { useFeatureFlags } from "@/hooks/useFeatureFlags";

import GlobeIcon from "../../assets/icons/tile-icons/globe.svg";
import PinIcon from "../../assets/icons/tile-icons/pin.svg";
import SaleIcon from "../../assets/icons/tile-icons/sale.svg";
import styles from "./offerButton.module.scss";

type OfferIconProps = {
    online: boolean;
    inStore: boolean;
};

const OfferIcon: React.FC<OfferIconProps> = ({ inStore, online }) => {
    const { inStoreExclusiveShopTile } = useFeatureFlags();

    if (!inStoreExclusiveShopTile) {
        return null;
    }

    if (inStore && online) {
        return <SaleIcon />;
    }

    if (inStore) {
        return <PinIcon />;
    }

    if (online) {
        return <GlobeIcon />;
    }

    return null;
};

export type OfferButtonProps = {
    label: string;
    onClick: (event) => void;
    online: boolean;
    inStore: boolean;
};

const cx = classNames.bind(styles);

const LabelElement = ({ label }) => {
    const { inStoreExclusiveShopTile } = useFeatureFlags();

    const labelStyles = cx({
        [styles.offerText]: inStoreExclusiveShopTile,
    });

    return (
        <span className={labelStyles}>{truncateWithEllipsis(label, HEADING_LENGTHS.SMALL)}</span>
    );
};

const OfferButton: React.FC<OfferButtonProps> = ({ label, onClick, inStore, online }) => {
    const { inStoreExclusiveShopTile } = useFeatureFlags();

    return (
        <ActionButton
            label={<LabelElement label={label} />}
            labelElement={<OfferIcon inStore={inStore} online={online} />}
            chip={!!inStoreExclusiveShopTile}
            kind={KINDS.CARD_WHITE}
            size={SIZES.TINY}
            fluid={false}
            disabled={false}
            onClick={onClick}
        />
    );
};

export default OfferButton;
