import { BuilderConfig } from "../../builder/types";
import { TrustpilotContainer } from "./trustpilot-container";

export const trustpilotReviewsBuilderConfig = {
    name: "Trustpilot Reviews",
};

const builderConfig: BuilderConfig<unknown> = {
    component: TrustpilotContainer,
    config: trustpilotReviewsBuilderConfig,
};

export default builderConfig;
