import React from "react";

import styles from "./phone.module.scss";

export type Props = {
    headline: string;
    subHeadline: string;
    body: string;
    image: string;
    imageAltText: string;
    children?: Array<React.ReactNode>;
};

const Phone: React.FC<Props> = ({ headline, image, imageAltText, subHeadline, body, children }) => {
    return (
        <div className={styles.container}>
            <div className={styles.background}>
                <div className={styles.content}>
                    {subHeadline && <h4 className={styles.subHeadline}>{subHeadline}</h4>}
                    <div
                        className={styles.headline}
                        dangerouslySetInnerHTML={{ __html: headline }}
                    />
                    {body && <p className={styles.body}>{body}</p>}
                    <div className={styles.ctaContainer}>
                        {children &&
                            children.length &&
                            children.map((child, index) => (
                                <div
                                    className={styles.ctaChildContainer}
                                    key={`cta-child-container-${index}`}
                                >
                                    {child}
                                </div>
                            ))}
                    </div>
                </div>
                <div className={styles.phone}>
                    <img src={image} alt={imageAltText} />
                </div>
            </div>
        </div>
    );
};

export default Phone;
