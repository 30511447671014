import React from "react";

import { FILL, FILLS, TRANSFORM, TRANSFORMS } from "../../shared/constants";

interface Props {
    active: boolean;
    disabled: boolean;
    fill: FILL;
    transform: TRANSFORM;
}

const setFill = (disabled: boolean, fill: FILL): FILL => {
    fill = fill || FILLS.GRAY;
    return disabled ? fill : FILLS.BLACK;
};

const setTransform = (active: boolean, disabled: boolean, transform: TRANSFORM): TRANSFORM => {
    transform = transform || TRANSFORMS.ROTATE_180;
    return !disabled && active ? transform : null;
};

const Dropdown: React.FC<Props> = (props: Props) => {
    const { active, disabled, fill, transform } = props;
    const svgProps = {
        fill: setFill(disabled, fill),
        transform: setTransform(active, disabled, transform),
    };
    return (
        <svg
            {...svgProps}
            data-testid="dropdownIcon"
            width="10"
            height="6"
            viewBox="0 0 10 6"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.75593 5.12713C5.35716 5.58759 4.64284 5.58759 4.24407 5.12713L1.23682 1.65465C0.675945 1.00701 1.136 -8.62339e-07 1.99275 -7.87439e-07L8.00725 -2.61634e-07C8.864 -1.86735e-07 9.32406 1.00701 8.76318 1.65465L5.75593 5.12713Z"
                fill="fill"
                transform="transform"
            />
        </svg>
    );
};

export default Dropdown;
