/*
 * See stories/Modals/Modal.stories.tsx for example usage
 */
export const fadeIn = (
    setVisibleCallback: React.Dispatch<React.SetStateAction<boolean>>,
    setFadingCallback: React.Dispatch<React.SetStateAction<boolean>>
): void => {
    document.body.style.overflowY = "hidden";
    setVisibleCallback(true);
    runInFollowingAnimationFrame(() => setFadingCallback(true));
};

export const fadeOut = (
    setVisibleCallback: React.Dispatch<React.SetStateAction<boolean>>,
    setFadingCallback: React.Dispatch<React.SetStateAction<boolean>>,
    timeout = 1000
): void => {
    document.body.style.overflowY = "auto";
    setFadingCallback(false);
    setTimeout(() => runInFollowingAnimationFrame(() => setVisibleCallback(false)), timeout);
};

const runInFollowingAnimationFrame = (cb: () => void): void => {
    // The next animation frame will happen before render, which our component is still not visible,
    // therefore we need the following animation frame.
    requestAnimationFrame(() => requestAnimationFrame(() => cb()));
};
