import { isEmpty } from "lodash";
import React from "react";

import { getHostname, getSearchParams } from "@/utils/urlHelper";

import Heading from "@/components/heading/heading";

import type { UI_CONTEXT } from "../../../analytics/constants";
import EVENTS from "../../../analytics/events";
import LinkButton, { Props as LinkButtonProps } from "../../buttons/link-button";
import styles from "./superWideOverlay.module.scss";

export type Props = {
    backgroundImage: string;
    heading: string;
    body: string;
    ctas: LinkButtonProps[];
    uiContext: UI_CONTEXT;
};

const SuperWideOverlay: React.FC<Props> = ({ heading, body, backgroundImage, ctas, uiContext }) => {
    let image = backgroundImage;
    const imageHostname = getHostname(image);
    if (imageHostname === "cdn.builder.io") {
        const imageParams = getSearchParams(image);
        image = isEmpty(imageParams) ? `${image}?format=webp` : `${image}&format=webp`;
    }

    return (
        <div className={styles.container}>
            <div className={styles.backgroundContainer}>
                <div className={styles.background} style={{ backgroundImage: `url("${image}")` }} />
            </div>
            <div className={styles.overlay}>
                <Heading headingStyle={styles.overlayHeading} header={heading} />
                {body && <p className={styles.overlayBody}>{body}</p>}
                {ctas && (
                    <div className={styles.ctaContainer}>
                        {ctas.map((cta, index) => (
                            <div
                                data-testid="super-wide-overlay-button"
                                className={styles.ctaOne}
                                key={`cta-container-${index}`}
                            >
                                <LinkButton
                                    disabled={false}
                                    fluid={true}
                                    kind={cta.kind}
                                    label={cta.label}
                                    size={cta.size}
                                    show={true}
                                    url={cta.url}
                                    newTab={false}
                                    forwardUtm={cta.forwardUtm}
                                    analytics={{
                                        eventName: EVENTS.BANNER_CLICK,
                                        uiContext: uiContext,
                                        eventProps: {
                                            title: heading,
                                            description: body,
                                            outboundLink: cta.url,
                                        },
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SuperWideOverlay;
