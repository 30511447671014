import { BuilderConfig } from "../../../builder/types";
import { TextInput, Props } from "./text-input";

export const TextInputBuilderConfig = {
    name: "TextInput",
    inputs: [
        {
            name: "characterLimit",
            type: "number",
            defaultValue: 24,
        },
        {
            name: "disabled",
            type: "boolean",
            defaultValue: false,
        },
        {
            name: "helperText",
            type: "string",
            defaultValue: "Generic helper text might be a hint or suggestion.",
        },
        {
            name: "label",
            type: "string",
            defaultValue: "Input Label",
        },
        {
            name: "size",
            type: "string",
            enum: ["regular", "small"],
            defaultValue: "regular",
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: TextInput,
    config: TextInputBuilderConfig,
};

export default builderConfig;
