import { BuilderConfig } from "../../../builder/types";
import { ALIGNMENT } from "../image-interludes.constants";
import { ImageInterludeFull, Props } from "./image-interlude-full";

export const ImageInterludeFullBuilderConfig = {
    name: "Image Interlude Full",
    inputs: [
        {
            name: "alignment",
            type: "string",
            enum: Object.values(ALIGNMENT),
            defaultValue: ALIGNMENT.LEFT,
        },
        {
            name: "imageSmallWideA",
            type: "file",
            required: true,
            allowedFileTypes: ["jpeg", "jpg", "png"],
        },
        {
            name: "imageSmallWideAAltText",
            type: "string",
            required: true,
            defaultValue: "Small Image A",
        },
        {
            name: "imageSmallWideB",
            type: "file",
            required: true,
            allowedFileTypes: ["jpeg", "jpg", "png"],
        },
        {
            name: "imageSmallWideBAltText",
            type: "string",
            required: true,
            defaultValue: "Small Image B",
        },
        {
            name: "imageLargeWide",
            type: "file",
            required: true,
            allowedFileTypes: ["jpeg", "jpg", "png"],
        },
        {
            name: "imageLargeWideAltText",
            type: "string",
            required: true,
            defaultValue: "Large Image",
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: ImageInterludeFull,
    config: ImageInterludeFullBuilderConfig,
};

export default builderConfig;
