import { BuilderConfig } from "../../builder/types";
import { ACCENT_COLORS } from "../component.constants";
import { ContentDivider, Props } from "./content-divider";

export const ContentDividerBuilderConfig = {
    name: "Content Divider",
    inputs: [
        {
            name: "backgroundColor",
            type: "string",
            enum: Object.values(ACCENT_COLORS),
            defaultValue: ACCENT_COLORS.ORCA_PASTEL,
            required: true,
        },
        {
            name: "grids",
            type: "list",
            required: true,
            onChange: (options: Map<string, Props["grids"]>): void => {
                if (options.get("grids").length > 6) {
                    options.set("grids", options.get("grids").slice(0, 6));
                    alert("You've reached the maximum number of grids.");
                }
            },
            defaultValue: [
                {
                    header: "Placeholder Subheader",
                    body: "Placeholder Text Body",
                },
                {
                    header: "Placeholder Subheader",
                    body: "Placeholder Text Body",
                },
            ],
            subFields: [
                {
                    name: "header",
                    type: "string",
                    required: true,
                },
                {
                    name: "body",
                    type: "richText",
                    required: true,
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: ContentDivider,
    config: ContentDividerBuilderConfig,
};

export default builderConfig;
