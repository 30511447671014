import React from "react";

import { COLOR } from "../component.constants";

export interface Props {
    color: COLOR;
    width?: number;
    height?: number;
    viewBoxWidth?: number;
    viewBoxHeight?: number;
}

const ShopIcon: React.FC<Props> = ({ color, width, height, viewBoxWidth, viewBoxHeight }) => {
    return (
        <svg
            width={width ?? 64}
            height={height ?? 64}
            fill="none"
            viewBox={`0 0 ${viewBoxWidth ?? 64} ${viewBoxHeight ?? 64}`}
        >
            <path
                fill={color}
                d="M63.619 14.627l-8-13.334A2.667 2.667 0 0053.333 0H10.667A2.667 2.667 0 008.38 1.293l-8 13.334C.132 15.04.001 15.517 0 16a10.648 10.648 0 0010.621 10.667h.022a10.597 10.597 0 007.13-2.734 10.603 10.603 0 0014.219 0 10.602 10.602 0 0014.232 0A10.646 10.646 0 0064 16c0-.484-.132-.959-.381-1.373zM53.376 32a16.024 16.024 0 01-7.144-1.674l-.04.018a15.862 15.862 0 01-12.17.827 16.26 16.26 0 01-2.027-.837l-.03.013a15.834 15.834 0 01-12.168.824 16.23 16.23 0 01-2.024-.837A15.974 15.974 0 0110.643 32 16.407 16.407 0 018 31.76v26.907a2.667 2.667 0 002.667 2.667h16v-16h10.666v16h16A2.667 2.667 0 0056 58.667V31.75c-.866.155-1.744.239-2.624.25z"
            />
        </svg>
    );
};

export default ShopIcon;
