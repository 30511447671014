import React from "react";

import { COLOR } from "../component.constants";

export interface Props {
    color: COLOR;
    width?: number;
    height?: number;
    viewBoxWidth?: number;
    viewBoxHeight?: number;
}

const CreditCardIcon: React.FC<Props> = ({ color, width, height, viewBoxWidth, viewBoxHeight }) => {
    return (
        <svg
            width={width ?? 64}
            height={height ?? 64}
            viewBox={`0 0 ${viewBoxWidth ?? 64} ${viewBoxHeight ?? 64}`}
            fill="none"
        >
            <path
                d="M64 21.33V10.668a5.332 5.332 0 00-5.333-5.333H5.333A5.332 5.332 0 000 10.666V21.33h64zM0 29.334v24a5.332 5.332 0 005.333 5.333h53.334A5.332 5.332 0 0064 53.333v-24H0zm29.333 16H10.667V40h18.666v5.334zm24 0H42.667V40h10.666v5.334z"
                fill={color}
            />
        </svg>
    );
};

export default CreditCardIcon;
