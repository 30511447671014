import { axios } from "@/utils/axios";
import logger from "@/utils/logger";
import { throwOrReturnDefault } from "@/utils/requests";

import { APICollectionProps, CollectionProps } from "@/interfaces/collection-interface";
import {
    APICollectionV2Data,
    APICollectionV2Props,
    CollectionV2Props,
} from "@/interfaces/collection-v2-interface";
import { APIProductProps, ProductProps } from "@/interfaces/products-interface";

import { Locale } from "@/types/locale";

import { transformProduct, transformProducts } from "./categories";

const SHOP_DIRECTORY_API_HOST = process.env.NEXT_PUBLIC_SHOP_DIRECTORY_API_HOST;

export const transformCollection = (collection: APICollectionProps): CollectionProps => {
    return JSON.parse(
        JSON.stringify({
            id: collection.id,
            slug: collection.attributes.slug,
            type: collection.type,
            name: collection.attributes.name,
            parentId: collection.attributes.parent_id?.toString() || null,
            bannerImageUrl: collection.attributes.banner_image_url,
            highlighted: collection.attributes.highlighted,
            metaTitle: collection.attributes.meta_title,
            metaDescription: collection.attributes.meta_description,
            pageTitle: collection.attributes.page_title,
            pageDescription: collection.attributes.page_description,
            position: collection.attributes.position,
            isCollection: collection.attributes.is_collection,
            rootPageUrl: `/collections/${collection.attributes.slug}`,
            products: [],
        })
    );
};

const productsInCollectionV2 = ({ data, included }: APICollectionV2Data): Array<ProductProps> => {
    const childProductIds = data.relationships?.top_products?.data?.map((p) => p.id) ?? [];
    return included
        .reduce((accum, includedObj) => {
            if (includedObj.type === "products" && childProductIds.includes(includedObj.id)) {
                return [...accum, includedObj];
            }
            return accum;
        }, [] as Array<APIProductProps>)
        .map(transformProduct);
};

const collectionsInCollectionV2 = ({
    data,
    included,
}: APICollectionV2Data): Array<CollectionV2Props> => {
    const childCollectionIds = data.relationships?.children?.data?.map((p) => p.id) ?? [];
    return included
        .reduce((accum, includedObj) => {
            if (
                includedObj.type === "collection_v2" &&
                childCollectionIds.includes(includedObj.id)
            ) {
                return [...accum, includedObj];
            }
            return accum;
        }, [] as Array<APICollectionV2Props>)
        .map((data) => transformCollectionV2({ data, included }));
};

export const transformCollectionV2 = ({
    data,
    included = [],
}: APICollectionV2Data): CollectionV2Props => {
    return data
        ? JSON.parse(
              JSON.stringify({
                  id: data.id,
                  type: data.type,
                  name: data.attributes.name,
                  slug: data.attributes.slug,
                  parentId: data.attributes.parent_id?.toString() || null,
                  position: data.attributes.position,
                  priceRange: data.attributes.price_range,
                  totalProductCount: data.attributes.total_product_count,
                  source: data.attributes.source,
                  products: productsInCollectionV2({ data, included }),
                  collections: collectionsInCollectionV2({ data, included }),
              })
          )
        : null;
};

export const transformCollections = (
    collections: Array<APICollectionProps>
): Array<CollectionProps> => {
    if (!collections) {
        return [];
    }
    return collections.map(transformCollection);
};

export const transformCollectionsV2 = (
    collections: Array<APICollectionV2Props>
): Array<CollectionV2Props> => {
    if (!collections) {
        return [];
    }
    return collections.map((data) => transformCollectionV2({ data }));
};

export const fetchCollectionProducts = async (
    locale: Locale,
    slug: string
): Promise<Array<ProductProps>> => {
    const url = `${SHOP_DIRECTORY_API_HOST}/api/v1/categories/${slug}/products?locale=${locale}`;

    try {
        const collectionResponse = await axios(url);
        const { data } = await collectionResponse.data;
        return transformProducts(data);
    } catch (ex) {
        logger.logException(ex, "Failed to fetch category products");
        return [];
    }
};

export const fetchCollection = async (
    locale: Locale,
    slug: string | undefined
): Promise<CollectionProps> => {
    const url = `${SHOP_DIRECTORY_API_HOST}/api/v1/categories/${slug}?locale=${locale}`;

    if (!slug) {
        return null;
    }

    try {
        const collectionResponse = await axios(url);
        const { data } = await collectionResponse.data;
        return data ? transformCollection(data) : null;
    } catch (ex) {
        const errorDescription = `Failed to fetch collection to url=${url}`;
        logger.logException(ex, errorDescription);
        return throwOrReturnDefault(ex);
    }
};

export const fetchCollections = async (locale: Locale): Promise<Array<CollectionProps>> => {
    const collectionsEndpoint = `${SHOP_DIRECTORY_API_HOST}/api/v1/collections?locale=${locale}&per_page=1000`;
    try {
        const response = await axios(collectionsEndpoint);
        if (response.status === 200) {
            const { data } = await response.data;
            return transformCollections(data);
        } else {
            return response.data;
        }
    } catch (ex) {
        logger.logException(ex, "Failed to fetch collections");
        return [];
    }
};

export const getParentCollection = async (
    locale: Locale,
    parentId: string | null
): Promise<CollectionProps | null> => {
    if (parentId === null) return null;

    const collections = await fetchCollections(locale);
    return collections.find((collection) => collection.id === parentId) || null;
};

export const fetchCollectionV2 = async (
    locale: Locale,
    slug: string | undefined
): Promise<CollectionV2Props> => {
    const url = `${SHOP_DIRECTORY_API_HOST}/api/v2/collections/${slug}?locale=${locale}`;
    if (!slug) {
        return null;
    }
    try {
        const collectionResponse = await axios(url);
        const { data, included } = await collectionResponse.data;
        return data ? transformCollectionV2({ data, included }) : null;
    } catch (ex) {
        const errorDescription = `Failed to fetch collection to url=${url}`;
        logger.logException(ex, errorDescription);
        return throwOrReturnDefault(ex);
    }
};

export const fetchCollectionsV2 = async (locale: Locale): Promise<Array<CollectionV2Props>> => {
    const collectionsEndpoint = `${SHOP_DIRECTORY_API_HOST}/api/v2/collections?locale=${locale}&per_page=1000`;
    try {
        const response = await axios(collectionsEndpoint);
        if (response.status === 200) {
            const { data } = await response.data;
            return transformCollectionsV2(data);
        } else {
            return response.data;
        }
    } catch (ex) {
        logger.logException(ex, "Failed to fetch collections");
        return [];
    }
};

const transformMetadata = (meta): CollectionV2Props => {
    return meta
        ? {
              id: meta.collection.id,
              name: meta.collection.name,
              slug: meta.collection.slug,
              totalProductCount: meta.total,
              type: null,
              parentId: null,
              position: null,
              priceRange: null,
              source: null,
              products: null,
              collections: null,
          }
        : null;
};

export const fetchCollectionMetadataV2 = async (
    locale: Locale,
    slug: string | undefined
): Promise<CollectionV2Props> => {
    const url = `${SHOP_DIRECTORY_API_HOST}/api/v2/collections/${slug}/products?locale=${locale}`;
    if (!slug) {
        return null;
    }
    try {
        const collectionResponse = await axios(url);
        const { meta } = await collectionResponse.data;
        return meta ? transformMetadata(meta) : null;
    } catch (ex) {
        const errorDescription = `Failed to fetch collection to url=${url}`;
        logger.logException(ex, errorDescription);
        return throwOrReturnDefault(ex);
    }
};
