import React from "react";

import { COLOR } from "../component.constants";

export interface Props {
    color: COLOR;
    width?: number;
    height?: number;
    viewBoxWidth?: number;
    viewBoxHeight?: number;
}

const SupportIcon: React.FC<Props> = ({ color, width, height, viewBoxWidth, viewBoxHeight }) => {
    return (
        <svg
            width={width ?? 64}
            height={height ?? 64}
            fill="none"
            viewBox={`0 0 ${viewBoxWidth ?? 64} ${viewBoxHeight ?? 64}`}
        >
            <path
                fill={color}
                d="M54.665 9.333C48.532 3.2 40.532 0 31.998 0 23.465 0 15.465 3.2 9.332 9.333c-12.534 12.534-12.534 32.8 0 45.334C15.465 60.8 23.465 64 31.998 64c8.534 0 16.534-3.2 22.667-9.333 12.533-12.534 12.533-32.8 0-45.334zm1.067 34.934L47.465 36c.267-1.333.533-2.667.533-4 0-1.333-.266-2.667-.533-4l8.267-8.267c4 7.734 4 16.8 0 24.534zM8.265 19.733L16.532 28c-.267 1.333-.534 2.667-.534 4 0 1.333.267 2.667.534 4l-8.267 8.267c-4-7.734-4-16.8 0-24.534zm16.267 19.734c-2.134-1.867-3.2-4.534-3.2-7.467s1.066-5.6 3.2-7.467c1.866-2.133 4.533-3.2 7.466-3.2 2.934 0 5.6 1.067 7.467 3.2 2.133 1.867 3.2 4.534 3.2 7.467s-1.067 5.6-3.2 7.467c-4 4-10.933 4-14.933 0zm19.733-31.2l-8.267 8.266c-1.333-.266-2.666-.533-4-.533-1.333 0-2.666.267-4.266.533l-8-8.266c3.733-1.867 8-2.934 12.266-2.934 4.267 0 8.534 1.067 12.267 2.934zM19.732 55.733l8.266-8.266c1.334.266 2.667.533 4.267.533 1.6 0 2.667-.267 4.267-.533l8.266 8.266c-3.733 1.867-8 2.934-12.266 2.934-4.267 0-9.067-1.067-12.8-2.934z"
            />
            <defs>
                <clipPath id="prefix__clip0">
                    <path fill={color} d="M0 0h64v64H0z" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default SupportIcon;
