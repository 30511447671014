import React from "react";

import { TileProps } from "@/types/tile";

import { ReviewProps } from "../../interfaces/trustpilot-reviews-interface";
import { truncateWithEllipsis } from "../../utils/strings";
import { FiveStarsIcon } from "../icons/five-stars-icon";
import styles from "./trustpilot.module.scss";

const MAX_REVIEW_BODY_TEXT_LENGTH = 150;

export type ReviewTileProps = {
    review: ReviewProps;
} & TileProps;
export const ReviewTile: React.FC<ReviewTileProps> = ({ review }) => {
    const reviewDate = new Date(review.postedAt);
    return (
        <div className={styles.trustpilotReviewContainer} key={review.id}>
            <div className={styles.trustpilotRatingContainer}>
                <FiveStarsIcon />
                <div className={styles.curvedTabEdgeTop} />
            </div>
            <div className={styles.trustpilotReviewContent}>
                <p className={styles.reviewTitle}>{review.title}</p>
                <p className={styles.reviewBody}>
                    {truncateWithEllipsis(review.body, MAX_REVIEW_BODY_TEXT_LENGTH)}
                </p>
                <p className={styles.reviewInfo}>
                    <span className={styles.reviewAuthor}>{review.displayName}</span> &#8226;{" "}
                    {reviewDate.toDateString()}
                </p>
            </div>
        </div>
    );
};
