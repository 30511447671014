import { useRouter } from "next/router";
import React from "react";

import { visitStoreWebsite } from "@/shared/helpers/tracking-helper";

import { UI_CONTEXT } from "@/analytics/constants";
import EVENTS from "@/analytics/events";
import { trackUserEvent } from "@/analytics/tracking";

import { useCurrentLocale, useLanguageState } from "@/context/LanguageContext";

import { CollectionV2Props } from "@/interfaces/collection-v2-interface";

import styles from "./collection-v2-block.module.scss";

export type Props = {
    collection: CollectionV2Props;
    uiContext: UI_CONTEXT;
    ctaNewTab: boolean;
};

export const CollectionV2Block: React.FC<Props> = ({ collection, uiContext, ctaNewTab }) => {
    const { localization } = useLanguageState();
    const router = useRouter();
    const locale = useCurrentLocale();
    const goToCollectionUrl = () => {
        const outboundLink = `/collections/${collection.slug}`;
        const eventProps = {
            outboundLink,
            title: "View Collection",
            categoryName: collection.name,
            categoryID: collection.id,
        };
        trackUserEvent(
            localization.locale,
            router.asPath,
            uiContext,
            EVENTS.SHOP_DIRECTORY_COLLECTION_LIST_CLICK,
            eventProps
        );

        visitStoreWebsite("/" + locale + outboundLink, ctaNewTab);
    };

    return (
        <div role={"button"} onClick={goToCollectionUrl} className={styles.collectionBlock}>
            <div className={styles.imageBlock}>
                <img className={styles.largeImage} src={collection.products[0].mainImageUrl} />
                <div className={styles.rightImages}>
                    <img className={styles.smallImage} src={collection.products[1].mainImageUrl} />
                    <img className={styles.smallImage} src={collection.products[2].mainImageUrl} />
                    <img className={styles.smallImage} src={collection.products[3].mainImageUrl} />
                    {collection.products.length === 5 ? (
                        <img
                            className={styles.smallImage}
                            src={collection.products[4].mainImageUrl}
                        />
                    ) : (
                        <div className={styles.moreCount}>+ {collection.products.length - 4}</div>
                    )}
                </div>
            </div>
            <h3 className={styles.heading}>{collection.name}</h3>
            <p className={styles.priceRange}>{collection.priceRange}</p>
        </div>
    );
};
