import { BuilderConfig } from "../../../builder/types";
import { SearchInput, Props } from "./search-input";

export const SearchInputBuilderConfig = {
    name: "SearchInput",
    inputs: [
        {
            name: "disabled",
            type: "boolean",
            defaultValue: false,
        },
        {
            name: "placeholder",
            type: "string",
            defaultValue: "Search Input",
        },
        {
            name: "size",
            type: "string",
            enum: ["Regular", "Small"],
            defaultValue: "regular",
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: SearchInput,
    config: SearchInputBuilderConfig,
};

export default builderConfig;
