import classNames from "classnames/bind";
import React from "react";

import styles from "./imageText.module.scss";

export interface Props {
    imageSrc: string;
    imageAltText: string;
    accentColor?: string;
    alignment?: string;
    bgColor?: string;
    bodyText?: string;
    header?: string;
}

const cx = classNames.bind(styles);

const renderImage: React.FC<Props> = (props: Props) => {
    const { imageAltText, imageSrc } = props;

    const portraitClassName = cx({
        [styles.imageContainerPortrait]: true,
    });

    return (
        <img className={portraitClassName} data-testid="image" src={imageSrc} alt={imageAltText} />
    );
};

const renderText: React.FC<Props> = (props: Props) => {
    const { bodyText, header } = props;
    const headerClassName = cx({
        [styles.textContainerHeader]: true,
    });

    const bodyTextClassName = cx({
        [styles.textContainerBodyText]: true,
    });

    return (
        <>
            <h2 className={headerClassName}>{header}</h2>
            <div className={bodyTextClassName} dangerouslySetInnerHTML={{ __html: bodyText }} />
        </>
    );
};

export const ImageText: React.FC<Props> = (props: Props) => {
    const { accentColor, alignment, bgColor } = props;

    const imageTextContainerClassName = cx({
        [styles.imageTextContainer]: true,
        [styles[alignment]]: true,
        [styles[`bg${bgColor}`]]: true,
    });

    const imageContainerClassName = cx({
        [styles.imageContainer]: true,
        [styles[`accent${accentColor}`]]: true,
    });

    const textContainerClassName = cx({
        [styles.textContainer]: true,
    });

    return (
        <div className={imageTextContainerClassName}>
            <div className={imageContainerClassName}>{renderImage(props)}</div>
            <div data-testid="text-container" className={textContainerClassName}>
                {renderText(props)}
            </div>
        </div>
    );
};
