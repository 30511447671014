import { ACCENT_COLORS } from "../component.constants";

export const IMAGE_ALIGNMENT = {
    LEFT: "Left",
    RIGHT: "Right",
};

export const BG_COLORS = {
    NONE: "None",
    LEMON_PASTEL: ACCENT_COLORS.LEMON_PASTEL,
    ORCA_PASTEL: ACCENT_COLORS.ORCA_PASTEL,
};

export const PLACEHOLDER_IMAGE_URL =
    "https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd6d3bc814ffd47b182ec8345cc5438c0";
