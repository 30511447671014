import { withChildren } from "@builder.io/react";

import { AsyncPropsMapper, BuilderConfig } from "../../../builder/types";
import { APICategoryProps } from "../../../interfaces/category-interface";
import { SHOP_DIRECTORY_API_HOST } from "../../../shared/constants";
import { KINDS, SIZES } from "../../buttons/buttons.constants";
import Phone, { Props } from "./phone";

const mapper: AsyncPropsMapper<{ data: Array<APICategoryProps> }> = ({ data }) => {
    return {
        categorySlug: data.data.map((category: APICategoryProps) => ({
            name: category.attributes.name,
            value: category.attributes.slug,
        })),
    };
};

const PhoneWithChildren = withChildren(Phone);

export const FullBleedConfig = {
    name: "Phone",
    inputs: [
        {
            friendlyName: "Show on Index page",
            name: "showOnIndex",
            type: "boolean",
            defaultValue: false,
            required: true,
        },
        {
            friendlyName: "Show on Category page",
            name: "categorySlug",
            type: "dynamic-dropdown",
            required: false,
            options: {
                url: `${SHOP_DIRECTORY_API_HOST}/api/v1/categories?locale={{targeting.locale.0}}`,
                mapper: mapper.toString(),
            },
        },
        {
            name: "headline",
            type: "richText",
            defaultValue: "A Headline of Some Respectable Length.",
            required: true,
        },
        {
            name: "subHeadline",
            type: "string",
            defaultValue: "Subhead Support",
            required: true,
        },
        {
            name: "body",
            type: "string",
            defaultValue:
                "Supporting copy can have a varying length even at a moderate body copy size of 24px.",
            required: true,
        },
        {
            name: "image",
            type: "file",
            required: true,
            allowedFileTypes: ["jpeg", "jpg", "png"],
        },
        {
            name: "imageAltText",
            type: "string",
            defaultValue: "Image Description",
            required: true,
        },
    ],
    childRequirements: {
        message: "You can only put LinkButton",
        query: {
            "component.name": { $in: ["LinkButton"] },
        },
    },
    defaultChildren: [
        {
            "@type": "@builder.io/sdk:Element" as const,
            component: {
                name: "LinkButton",
                options: {
                    label: "Hello",
                    kind: KINDS.PRIMARY,
                    size: SIZES.REGULAR,
                    fluid: true,
                    disabled: false,
                    show: true,
                    url: "/",
                },
            },
        },
        {
            "@type": "@builder.io/sdk:Element" as const,
            component: {
                name: "LinkButton",
                options: {
                    label: "Hello",
                    kind: KINDS.SECONDARY,
                    size: SIZES.REGULAR,
                    fluid: true,
                    disabled: false,
                    show: true,
                    url: "/",
                },
            },
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: PhoneWithChildren,
    config: FullBleedConfig,
};

export default builderConfig;
