import Link from "next/link";
import React from "react";

import styles from "./partnerLogos.module.scss";

export interface PartnerLogoProps {
    partnerLogoUrl: string;
    altText: string;
    partnerUrl: string;
    newTab?: boolean;
}

export const PartnerLogo: React.FC<PartnerLogoProps> = (props: PartnerLogoProps) => {
    const { partnerLogoUrl, altText, partnerUrl, newTab } = props;

    return (
        <div className={styles.partnerLogoContainer}>
            <Link prefetch={false} href={partnerUrl}>
                <a target={newTab ? "_blank" : null}>
                    <img className={styles.partnerLogo} src={partnerLogoUrl} alt={altText} />
                </a>
            </Link>
        </div>
    );
};

PartnerLogo.defaultProps = {
    newTab: true,
};
