import React, { Dispatch, SetStateAction, useEffect } from "react";

import { fadeOut } from "@/utils/transitions";

import { COLORS } from "@/components/component.constants";
import Close from "@/components/icons/close-icon";

import styles from "./overlay.module.scss";

export type OverlayProps = {
    headerText?: string;
    body: React.ReactNode;
    footer?: React.ReactNode;
    active?: boolean;
    setVisibleCallback: Dispatch<SetStateAction<boolean>>;
    setFadingCallback: Dispatch<SetStateAction<boolean>>;
};

export const Overlay: React.FC<OverlayProps> = ({
    headerText,
    body,
    footer,
    active,
    setVisibleCallback,
    setFadingCallback,
}) => {
    const visible = active === undefined ? false : active;

    const closeModal = (e) => {
        if (e.key === "Escape") {
            return fadeOut(setVisibleCallback, setFadingCallback);
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", closeModal, false);
        return () => {
            document.removeEventListener("keydown", closeModal, false);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <div
                onClick={() => fadeOut(setVisibleCallback, setFadingCallback)}
                className={visible ? styles.overlay : styles.inactiveOverlay}
            />
            <div className={visible ? styles.modalContainer : styles.inactiveModalContainer}>
                <div className={styles.modalHeader}>
                    {headerText ? <h3 className={styles.modalHeaderText}>{headerText}</h3> : null}
                    <div
                        onClick={() => fadeOut(setVisibleCallback, setFadingCallback)}
                        className={styles.iconContainer}
                    >
                        <Close
                            color={COLORS.black}
                            width={20}
                            height={20}
                            viewBoxWidth={24}
                            viewBoxHeight={24}
                        />
                    </div>
                </div>
                <div className={styles.modalBody}>{body}</div>
                <div className={styles.modalFooter}>{footer ? footer : null}</div>
            </div>
        </React.Fragment>
    );
};
