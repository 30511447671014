import React from "react";

import { COLOR } from "../component.constants";

export interface Props {
    color: COLOR;
    width?: number | string;
    height?: number | string;
    viewBoxWidth?: number | string;
    viewBoxHeight?: number | string;
}

const CloseIcon: React.FC<Props> = ({ color, width, height, viewBoxWidth, viewBoxHeight }) => {
    return (
        <svg
            width={width ?? 64}
            height={height ?? 64}
            fill="none"
            viewBox={`0 0 ${viewBoxWidth ?? 64} ${viewBoxHeight ?? 64}`}
        >
            <path d="M2 2L22 22" stroke={color} strokeWidth="2" strokeLinecap="round" />
            <path d="M2 22L22 2" stroke={color} strokeWidth="2" strokeLinecap="round" />
        </svg>
    );
};

export default CloseIcon;
