import BankIcon from "./bank-icon";
import CloseIcon from "./close-icon";
import CreditCardIcon from "./credit-card-icon";
import EditIcon from "./edit-icon";
import MeterIcon from "./meter-icon";
import OpeningTimesIcon from "./opening-times-icon";
import ShopBagIcon from "./shop-bag-icon";
import ShopIcon from "./shop-icon";
import SupportIcon from "./support-icon";
import ThumbsupIcon from "./thumbsup-icon";

export const ICONS = {
    CreditCard: CreditCardIcon,
    OpeningTimes: OpeningTimesIcon,
    Support: SupportIcon,
    Shop: ShopIcon,
    Close: CloseIcon,
    Bank: BankIcon,
    Edit: EditIcon,
    ShopBag: ShopBagIcon,
    Meter: MeterIcon,
    Thumbsup: ThumbsupIcon,
} as const;
export type ICON = typeof ICONS[keyof typeof ICONS];
