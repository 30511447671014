export const KINDS = {
    PRIMARY: "primary",
    SECONDARY: "secondary",
    TERTIARY: "tertiary",
    GHOST: "ghost",
    BLACK: "black",
    WHITE: "white",
    CARD_WHITE: "cardWhite",
    CARD_BLACK: "cardBlack",
    SEARCH_SUGGESTION: "searchSuggestion",
    ICON: "icon",
    NAVIGATION: "navigation",
    CAROUSEL: "carousel",
    UNSET: "unset",
    CHIP: "chip",
} as const;

export const SIZES = {
    LARGE: "large",
    REGULAR: "regular",
    SMALL: "small",
    TINY: "tiny",
    UNSET: "unset", // Let the KIND do all the styling
} as const;

export type KIND = typeof KINDS[keyof typeof KINDS];
export type SIZE = typeof SIZES[keyof typeof SIZES];
