import { BuilderConfig } from "../../builder/types";
import { ACCENT_COLORS } from "../component.constants";
import Statistic, { Props } from "./statistic";
import { FONT_SIZES, KINDS } from "./statistic.constants";

export const statisticsBuilderConfig = {
    name: "Statistics",
    inputs: [
        {
            name: "kind",
            type: "string",
            enum: Object.values(KINDS),
            defaultValue: KINDS.TWO_BY_TWO,
            required: true,
        },
        {
            name: "accentColor",
            type: "string",
            enum: Object.values(ACCENT_COLORS),
            defaultValue: ACCENT_COLORS.ORCA_PASTEL,
            required: true,
        },
        {
            name: "fontSize",
            type: "string",
            enum: Object.values(FONT_SIZES),
            defaultValue: FONT_SIZES.LargestHeading,
            required: true,
        },
        {
            name: "statistics",
            type: "list",
            required: true,
            onChange: (options: Map<string, Props["statistics"]>): void => {
                if (options.get("statistics").length > 4) {
                    options.set("statistics", options.get("statistics").slice(0, 4));
                    alert("You've reached the maximum number of grids.");
                }
            },
            defaultValue: [
                {
                    metric: "200",
                    description: "Is another number that looks good usually.",
                },
                {
                    metric: "200",
                    description: "Is another number that looks good usually.",
                },
                {
                    metric: "200",
                    description: "Is another number that looks good usually.",
                },
                {
                    metric: "200",
                    description: "Is another number that looks good usually.",
                },
            ],
            subFields: [
                {
                    name: "metric",
                    type: "string",
                },
                {
                    name: "description",
                    type: "string",
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: Statistic,
    config: statisticsBuilderConfig,
};

export default builderConfig;
