import React, { ReactChild } from "react";

import { COLOR } from "../component.constants";
import AfterpaySilhouetteOverlay from "./overlay";

export interface Props {
    color: COLOR;
    children: ReactChild;
}

const IconOverlay: React.FC<Props> = (props: Props) => {
    return (
        <div className="relative">
            <div className="absolute" style={{ bottom: "-8px", left: "-8px" }}>
                <AfterpaySilhouetteOverlay color={props.color} />
            </div>
            <div className="relative">{props.children}</div>
        </div>
    );
};

export default IconOverlay;
