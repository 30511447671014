import { BuilderConfig } from "../../../builder/types";
import FullBleed, { Props } from "./full-bleed";

export const FullBleedConfig = {
    name: "Full Bleed Hero",
    inputs: [
        {
            name: "backgroundImage",
            type: "file",
            allowedFileTypes: ["jpg", "jpeg", "png"],
            required: true,
        },
        {
            name: "heading",
            type: "richText",
            required: true,
            defaultValue: "Main Heading",
        },
        {
            name: "body",
            type: "string",
            required: true,
            defaultValue: "Lorem Ipsum...",
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: FullBleed,
    config: FullBleedConfig,
};

export default builderConfig;
