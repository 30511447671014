import React from "react";

interface HeadingProps {
    header: string;
    headingStyle: string;
}
const heading: React.FC<HeadingProps> = ({ header, headingStyle }) => {
    header = `<b>${header}</b>`;
    return <h2 className={headingStyle} dangerouslySetInnerHTML={{ __html: header }}></h2>;
};

export default heading;
