import { useEffect, useState } from "react";
import useSWR from "swr";

import { axios } from "@/utils/axios";
import { nextApiHostForLocale } from "@/utils/locale";
import logger from "@/utils/logger";

import { useCurrentLocale } from "@/context/LanguageContext";

import { ReviewProps } from "@/interfaces/trustpilot-reviews-interface";

type SWRReviewsProps = {
    reviews: Array<ReviewProps>;
    totalReviews: number;
};

async function fetcher<T>(url: string, options?: Record<string, unknown>): Promise<T> {
    const res = await axios(url, options);
    return res.data;
}

export const useTrustpilotReviews = (path: string): SWRReviewsProps => {
    const [error, setError] = useState<Error | null>(null);
    const locale = useCurrentLocale();
    const host = nextApiHostForLocale(locale);
    const url = `${host}${path}?locale=${locale}`;
    const [reviews, setReviews] = useState<SWRReviewsProps>({
        reviews: [],
        totalReviews: 0,
    });
    const { data: swrData, error: swrError } = useSWR<SWRReviewsProps>(url, fetcher, {
        shouldRetryOnError: false,
    });
    if (swrError && swrError !== error) {
        logger.logException(swrError, "Unable to fetch trustpilot reviews data");
        setError(swrError);
    }
    useEffect(() => {
        if (swrData === undefined) {
            return;
        }
        try {
            setReviews({
                reviews: swrData.reviews,
                totalReviews: swrData.totalReviews,
            });
            setError(null);
        } catch (err) {
            const errorMessage = "Unable to transform trustpilot reviews";
            logger.logException(err, errorMessage);
            setError(new Error(errorMessage));
        }
    }, [swrData, swrError]);
    return reviews;
};
