import { withChildren } from "@builder.io/react";

import { BuilderConfig } from "../../builder/types";
import { KINDS as BUTTON_KINDS, SIZES } from "../buttons/buttons.constants";
import { ACCENT_COLORS as BG_COLORS, ACCENT_COLORS } from "../component.constants";
import { CtaCluster, Props } from "./cta-cluster";
import { KINDS } from "./cta-cluster.constants";

const CtaClusterWithChildren = withChildren(CtaCluster);

export const CtaClusterBuilderConfig = {
    name: "CTA Cluster",
    inputs: [
        {
            name: "kind",
            type: "string",
            enum: Object.values(KINDS),
            defaultValue: KINDS.FULL,
        },
        {
            name: "accentColor",
            type: "string",
            enum: Object.values(ACCENT_COLORS),
            defaultValue: ACCENT_COLORS.LEMON_PASTEL,
        },
        {
            name: "bgColor",
            type: "string",
            enum: Object.values(BG_COLORS),
            defaultValue: BG_COLORS.LEMON_DARK,
        },
        {
            name: "clusterASubheader",
            type: "string",
            required: true,
            defaultValue: "For new Afterpay customers",
        },
        {
            name: "clusterATextBody",
            type: "string",
            required: true,
            defaultValue:
                "New Afterpay customers will earn 500 bonus Qantas Points on sign up, then 1 point per $1 spent, up to a total of 5,000 points.",
        },
        {
            name: "clusterBSubheader",
            type: "string",
            required: true,
            defaultValue: "For new Afterpay customers",
        },
        {
            name: "clusterBTextBody",
            type: "string",
            required: true,
            defaultValue:
                "New Afterpay customers will earn 500 bonus Qantas Points on sign up, then 1 point per $1 spent, up to a total of 5,000 points.",
        },
        {
            name: "ctaContent",
            type: "string",
            required: true,
            defaultValue:
                "Already linked your Qantas Frequent Flyer number to your Afterpay account?",
        },
    ],
    childRequirements: {
        message: "You can only put LinkButton",
        query: {
            "component.name": { $in: ["LinkButton"] },
        },
    },
    defaultChildren: [
        {
            "@type": "@builder.io/sdk:Element" as const,
            component: {
                name: "LinkButton",
                options: {
                    label: "Hello",
                    kind: BUTTON_KINDS.PRIMARY,
                    size: SIZES.REGULAR,
                    fluid: true,
                    disabled: false,
                    show: true,
                },
            },
        },
        {
            "@type": "@builder.io/sdk:Element" as const,
            component: {
                name: "LinkButton",
                options: {
                    label: "Hello",
                    kind: BUTTON_KINDS.SECONDARY,
                    size: SIZES.REGULAR,
                    fluid: true,
                    disabled: false,
                    show: true,
                },
            },
        },
        {
            "@type": "@builder.io/sdk:Element" as const,
            component: {
                name: "LinkButton",
                options: {
                    label: "Hello",
                    kind: BUTTON_KINDS.SECONDARY,
                    size: SIZES.REGULAR,
                    fluid: true,
                    disabled: false,
                    show: true,
                },
            },
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: CtaClusterWithChildren,
    config: CtaClusterBuilderConfig,
};

export default builderConfig;
