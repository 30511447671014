import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

import { UI_CONTEXT } from "@/analytics/constants";
import EVENTS from "@/analytics/events";
import { trackUserEvent } from "@/analytics/tracking";

import { useLanguageState } from "@/context/LanguageContext";

import { useCollection, useCollectionProducts } from "@/hooks/useCollection";

import { CollectionProps } from "@/interfaces/collection-interface";
import { ProductProps } from "@/interfaces/products-interface";

import AppBlackOnMint from "../../assets/images/app-logos/App_BlackOnBondiMint.svg";
import styles from "./curatedCollection.module.scss";

export interface Props {
    slug: string;
    uiContext: UI_CONTEXT;
    initialCollection?: CollectionProps;
    initialCollectionProducts?: Array<ProductProps>;
}

const CuratedCollectionComponent: React.FC<Props> = ({
    slug,
    uiContext,
    initialCollection,
    initialCollectionProducts,
}) => {
    const collection = useCollection(slug, {
        initialState: initialCollection,
    });

    const products = useCollectionProducts(slug, {
        initialState: initialCollectionProducts,
    });

    const { localization } = useLanguageState();
    const router = useRouter();

    if (!collection || products.length === 0) {
        return null;
    }

    const sendAnalytics = async () => {
        trackUserEvent(
            localization.locale,
            router.asPath,
            uiContext,
            EVENTS.SHOP_DIRECTORY_COLLECTION_LIST_CLICK,
            {
                title: collection.name,
                position: collection.position,
            }
        );
    };

    return (
        <Link href={`/${localization.locale}/collections/${slug}`} prefetch={false}>
            <a onClick={sendAnalytics}>
                <div className={styles.container}>
                    <div className={styles.header}>
                        <div className={styles.logo}>
                            <AppBlackOnMint height={"40"} width={"40"} />
                        </div>
                        <div className={styles.infoContainer}>
                            <h2 className={styles.title}>{collection.name}</h2>
                            <div
                                className={styles.description}
                                dangerouslySetInnerHTML={{ __html: collection.pageDescription }}
                            />
                        </div>
                    </div>
                    <div className={styles.collection}>
                        {products.map(
                            (product, position) =>
                                position < 4 && (
                                    <div className={styles.product} key={`product-${position}`}>
                                        <img alt={product.name} src={product.mainImageUrl} />
                                    </div>
                                )
                        )}
                    </div>
                </div>
            </a>
        </Link>
    );
};

export const CuratedCollection: React.FC<Props> = (props) => {
    if (!props.slug) {
        return null;
    }

    return <CuratedCollectionComponent {...props} />;
};
