import { BuilderConfig } from "../../../builder/types";
import { PLACEHOLDER_IMAGE_URL } from "../image-text.constants";
import { ImageText, Props } from "./image-text";

export const ImageTextBuilderConfig = {
    name: "ImageText",
    inputs: [
        {
            name: "accentColor",
            type: "string",
            enum: ["LemonDark", "LemonPastel", "OrcaDark", "OrcaPastel", "Plum"],
            defaultValue: "LemonDark",
        },
        {
            name: "alignment",
            type: "string",
            enum: ["Left", "Right"],
            defaultValue: "Left",
        },
        {
            name: "bgColor",
            type: "string",
            enum: ["None", "LemonPastel", "OrcaPastel"],
            defaultValue: "None",
        },
        {
            name: "header",
            type: "string",
            defaultValue: "Placeholder header",
        },
        {
            name: "bodyText",
            type: "richText",
            defaultValue: "This is placeholder text",
        },
        {
            name: "imageSrc",
            type: "file",
            defaultValue: PLACEHOLDER_IMAGE_URL,
            required: true,
            allowedFileTypes: ["jpeg", "png"],
        },
        {
            name: "imageAltText",
            type: "string",
            required: true,
            defaultValue: "Image Description",
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: ImageText,
    config: ImageTextBuilderConfig,
};

export default builderConfig;
