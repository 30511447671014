import { BuilderConfig } from "../../builder/types";
import { Divider, Props } from "./divider";

export const DividerBuilderConfig = {
    name: "Divider",
    inputs: [
        {
            name: "kind",
            type: "string",
            enum: ["Hairline", "Dashed", "Solid"],
            defaultValue: "solid",
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: Divider,
    config: DividerBuilderConfig,
};

export default builderConfig;
