/*
    Fix for iOS zoom on input elements: https://thingsthemselves.com/no-input-zoom-in-safari-on-iphone-the-pixel-perfect-way/
*/
module.exports = function ({ addUtilities }) {
    const newUtilities = {
        ".no-zoom-dropdown-input": {
            height: "5.3332rem",
            width: "133.33%",
            "margin-right": "-33.33%",
            transform: "scale(0.75)",
            "transform-origin": "top left",
            "font-size": "1.3333rem",
            "line-height": "1.99995rem",
        },
        ".no-zoom-text-input": {
            height: "3.9999rem",
            width: "133.33%",
            "margin-right": "-33.33%",
            transform: "scale(0.75)",
            "transform-origin": "left",
            "padding-top": "0.625rem",
            "font-size": "1.1666375rem",
            "line-height": "1.8332875rem",
        },
    };
    addUtilities(newUtilities, {
        variants: ["responsive"],
    });
};
