import { BuilderConfig } from "../../../builder/types";
import TextHeader, { Props } from "./text-header";

export const TextHeaderConfig = {
    name: "TextHeader",
    inputs: [
        {
            name: "header",
            type: "text",
            defaultValue: "Header",
            required: true,
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: TextHeader,
    config: TextHeaderConfig,
};

export default builderConfig;
