import { useState, useEffect, useCallback } from "react";

const useWindowDimensions = (): {
    width: number;
    height: number;
    hasWindow: boolean;
} => {
    const hasWindow = typeof window === "object";
    const [width, setWidth] = useState<number>(hasWindow ? window.innerWidth : 0);
    const [height, setHeight] = useState<number>(hasWindow ? window.innerHeight : 0);

    const handleResize = useCallback(() => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }, []);

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, [handleResize]);

    return {
        width,
        height,
        hasWindow,
    };
};

export default useWindowDimensions;
