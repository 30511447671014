import React from "react";

import styles from "./appHighlight.module.scss";

export interface Props {
    backImage: string;
    backImageAltText: string;
    body: string;
    children: Array<React.ReactNode>;
    frontImage: string;
    frontImageAltText: string;
    header: string;
}

export const AppHighlight: React.FC<Props> = (props: Props) => {
    const { backImage, backImageAltText, body, children, frontImage, frontImageAltText, header } =
        props;

    const renderButtons = (): React.ReactNode => {
        return (
            <div className={styles.buttonContainer}>
                {children?.map((child, index) => {
                    return (
                        <div key={`button-${index}`} className={styles.buttonChild}>
                            {child}
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderImages = (): React.ReactNode => {
        return (
            <div className={styles.appHighlightImageContainer}>
                <div className={styles.appImageChildContainer}>
                    <img alt={frontImageAltText} src={frontImage} className={styles.frontImage} />
                </div>
                <div className={styles.appImageChildContainer}>
                    <img alt={backImageAltText} src={backImage} className={styles.backImage} />
                </div>
            </div>
        );
    };

    const renderText = (): React.ReactNode => {
        return (
            <div className={styles.textContainer}>
                <h2 className={styles.textContainerHeader}>{header}</h2>
                <p className={styles.textContainerBody}>{body}</p>
                {renderButtons()}
            </div>
        );
    };

    return (
        <div className={styles.appHighlightContainer}>
            {renderImages()}
            {renderText()}
        </div>
    );
};
