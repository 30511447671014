import React, { FC, Fragment, useMemo } from "react";

import { UI_CONTEXT } from "@/analytics/constants";

import CardCarousel from "@/components/carousel/carousel";
import CardCarouselHeader from "@/components/carousel/carousel-header";
import { CollectionV2Block } from "@/components/carousel/product-collection-v2/collection-v2-block";
import { productTilesForCollection } from "@/components/product-tiles/stack-product-tile";

import { useCollectionV2 } from "@/hooks/useCollection";

import { CollectionV2Props } from "@/interfaces/collection-v2-interface";

export type Props = {
    slug: string;
    title?: string;
    ctaNewTab: boolean;
    uiContext: UI_CONTEXT;
    showViewMore: boolean;
    showParentCollectionProducts: boolean;
};

const collectionBlocksForCollection = (
    collection: CollectionV2Props,
    uiContext: UI_CONTEXT,
    ctaNewTab: boolean
): Array<React.ReactElement> => {
    return collection.collections.map((collection) => (
        <CollectionV2Block
            key={collection.id}
            collection={collection}
            uiContext={uiContext}
            ctaNewTab={ctaNewTab}
        />
    ));
};

const ProductCollectionV2Carousel: FC<Props> = ({
    slug,
    title,
    ctaNewTab,
    uiContext,
    showViewMore,
    showParentCollectionProducts,
}) => {
    const collection = useCollectionV2(slug);
    const carouselItems = useMemo<{ items: Array<React.ReactElement>; ctaType: string }>(() => {
        const ret = { ctaType: "collections", items: [] };
        if (!collection) {
            // Too few products to show a collection
            return ret;
        }

        if (showParentCollectionProducts) {
            // Only show products from parent collection
            ret.items = productTilesForCollection(collection, uiContext);
            return ret;
        }

        if (collection.collections.length > 1) {
            // Show collection of collections carousel
            ret.ctaType = "collections-list";
            ret.items = collectionBlocksForCollection(collection, uiContext, ctaNewTab);
            return ret;
        }

        if (collection.collections.length === 1) {
            // Only show products from the single child collection
            ret.items = productTilesForCollection(collection.collections[0], uiContext);
            return ret;
        }

        // No children to show so defaulting to the parent's products
        ret.items = productTilesForCollection(collection, uiContext);
        return ret;
    }, [showParentCollectionProducts, collection, ctaNewTab, uiContext]);

    if (carouselItems.items.length === 0) {
        return null;
    }
    if (carouselItems.ctaType == "collections-list")
        return (
            <Fragment>
                <CardCarouselHeader
                    title={title || collection.name}
                    ctaUrl={`/${carouselItems.ctaType}/${collection.slug}`}
                    eventProps={{
                        categoryId: collection.id,
                        categoryName: collection.name,
                    }}
                    ctaNewTab={ctaNewTab}
                    uiContext={uiContext}
                    showViewMore={showViewMore}
                />
                <CardCarousel carouselType={"collection"}>{carouselItems.items}</CardCarousel>
            </Fragment>
        );
    return (
        <Fragment>
            <CardCarouselHeader
                title={title || collection.name}
                ctaUrl={`/${carouselItems.ctaType}/${collection.slug}`}
                eventProps={{
                    categoryId: collection.id,
                    categoryName: collection.name,
                }}
                ctaNewTab={ctaNewTab}
                uiContext={uiContext}
                showViewMore={showViewMore}
            />
            <CardCarousel carouselType={"v2"}>{carouselItems.items}</CardCarousel>
        </Fragment>
    );
};

const ProductCollectionV2CarouselGuard: React.FC<Props> = (props) => {
    if (!props.slug) {
        return null;
    }
    return <ProductCollectionV2Carousel {...props} />;
};

export default ProductCollectionV2CarouselGuard;
