import { useEffect, useState } from "react";
import useSWR from "swr";

import { SHOP_DIRECTORY_API_HOST } from "@/shared/constants";

import { axios } from "@/utils/axios";
import logger from "@/utils/logger";

import { transformProducts } from "@/pages/api/store-directory/categories";
import {
    transformCollection,
    transformCollections,
    transformCollectionV2,
} from "@/pages/api/store-directory/collections";

import { useCurrentLocale, useLanguageState } from "@/context/LanguageContext";

import { APICollectionProps, CollectionProps } from "@/interfaces/collection-interface";
import { APICollectionV2Data, CollectionV2Props } from "@/interfaces/collection-v2-interface";
import { APIProductProps, ProductProps } from "@/interfaces/products-interface";

import { Config } from "@/types/hooks";

const CATEGORIES_BASE_URL = `${SHOP_DIRECTORY_API_HOST}/api/v1/categories`;
const COLLECTIONS_BASE_URL = `${SHOP_DIRECTORY_API_HOST}/api/v1/collections`;
const COLLECTIONS_V2_BASE_URL = `${SHOP_DIRECTORY_API_HOST}/api/v2/collections`;

async function fetcher<T>(url: string, options?: Record<string, unknown>): Promise<T> {
    const res = await axios(url, options);
    return res.data.data;
}

async function fetcherV2<T>(url: string, options?: Record<string, unknown>): Promise<T> {
    const res = await axios(url, options);
    return res.data;
}

export function useCollection(slug: string, config: Config<CollectionProps> = {}): CollectionProps {
    const [collection, setCollection] = useState<CollectionProps>(config.initialState);
    const locale = useCurrentLocale();
    const url = `${CATEGORIES_BASE_URL}/${slug}?locale=${locale}`;

    const { data, error } = useSWR<APICollectionProps>(url, fetcher, {
        shouldRetryOnError: false,
        ...config.swrConfig,
    });

    logger.logException(error, `Unable to fetch collection`);

    useEffect(() => {
        if (!data) {
            return;
        }

        try {
            setCollection(transformCollection(data));
        } catch (ex) {
            logger.logException(ex, `Unable to transform collection`);
        }
    }, [data]);

    return collection;
}

export function useCollectionV2(
    slug: string,
    config: Config<CollectionV2Props> = {}
): CollectionV2Props {
    const [collection, setCollection] = useState<CollectionV2Props>(config.initialState);
    const locale = useCurrentLocale();
    const url = `${COLLECTIONS_V2_BASE_URL}/${slug}?locale=${locale}`;

    const { data: swrData, error: swrError } = useSWR<APICollectionV2Data>(url, fetcherV2, {
        shouldRetryOnError: false,
        ...config.swrConfig,
    });

    logger.logException(swrError, `Unable to fetch v2 collection for slug: ${slug}`);

    useEffect(() => {
        if (!swrData) {
            return;
        }

        try {
            const transformedCollectionV2 = transformCollectionV2(swrData);
            setCollection(transformedCollectionV2);
        } catch (ex) {
            logger.logException(ex, `Unable to transform collection`);
        }
    }, [swrData]);

    return collection;
}

export function useCollections(): Array<CollectionProps> {
    const [collections, setCollections] = useState<Array<CollectionProps>>([]);
    const { localization } = useLanguageState();
    const url = `${COLLECTIONS_BASE_URL}?locale=${localization.locale}`;
    const { data, error } = useSWR<Array<APICollectionProps>>(url, fetcher, {
        shouldRetryOnError: false,
    });

    useEffect(() => {
        if (!data) {
            return;
        }

        try {
            setCollections(transformCollections(data));
        } catch (ex) {
            logger.logException(ex, `Unable to transform collections`);
        }
    }, [data]);

    logger.logException(error, `Unable to fetch collections`);

    return collections;
}

export function useCollectionProducts(
    slug: string,
    config: Config<Array<ProductProps>> = {}
): Array<ProductProps> {
    const [products, setProducts] = useState<Array<ProductProps>>(config.initialState ?? []);
    const { localization } = useLanguageState();
    const qs = config.queryString ? `&${config.queryString}` : "";
    const url = `${COLLECTIONS_V2_BASE_URL}/${slug}/products?locale=${localization.locale}${qs}`;
    const { data, error } = useSWR<Array<APIProductProps>>(url, fetcher, {
        shouldRetryOnError: false,
        ...config.swrConfig,
    });

    useEffect(() => {
        if (!data === null) setProducts([]);

        try {
            setProducts(transformProducts(data));
        } catch (ex) {
            logger.logException(ex, `Unable to transform collection products`);
        }
    }, [data]);

    logger.logException(error, `Unable to fetch collection products`);

    return products;
}
