import { withChildren } from "@builder.io/react";

import { BuilderConfig } from "../../builder/types";
import { KINDS as BUTTON_KINDS, SIZES } from "../buttons/buttons.constants";
import { ACCENT_COLORS } from "../component.constants";
import { CtaDivider, Props } from "./cta-divider";

const CtaDividerWithChildren = withChildren(CtaDivider);

export const CtaDividerBuilderConfig = {
    name: "CTA Divider",
    inputs: [
        {
            name: "text",
            type: "richText",
            defaultValue:
                "<p><strong>Get exclusive access!</strong> Be the first to access future product launches and exclusive offers.</p>",
        },
        {
            name: "backgroundColor",
            type: "string",
            enum: Object.values(ACCENT_COLORS),
            defaultValue: ACCENT_COLORS.NONE,
            required: true,
        },
    ],
    childRequirements: {
        message: "You can only put LinkButtons",
        query: {
            "component.name": { $in: ["LinkButton"] },
        },
    },
    defaultChildren: [
        {
            "@type": "@builder.io/sdk:Element" as const,
            component: {
                name: "LinkButton",
                options: {
                    label: "Explore deals",
                    kind: BUTTON_KINDS.PRIMARY,
                    size: SIZES.REGULAR,
                    show: true,
                    url: "/",
                },
            },
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: CtaDividerWithChildren,
    config: CtaDividerBuilderConfig,
};

export default builderConfig;
