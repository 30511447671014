import { useTranslation } from "next-i18next";
import React from "react";

import { TrustpilotLogo } from "../icons/trustpilot-logo";
import styles from "./trustpilot.module.scss";

export interface Props {
    totalReviewCount: number;
}

export const TrustpilotHeader: React.FC<Props> = (props) => {
    const { totalReviewCount } = props;
    const { t } = useTranslation();

    return (
        <div className={styles.trustpilotHeaderContainer}>
            <p className={styles.trustpilotContent}>
                <span>{t("trustpilot.header")}</span>
                <span className={styles.reviewsCount}>
                    {" "}
                    {totalReviewCount} {t("trustpilot.text")}
                </span>
            </p>
            <TrustpilotLogo />
        </div>
    );
};
