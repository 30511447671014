import classNames from "classnames/bind";
import React from "react";

import { ALIGNMENT } from "../image-interludes.constants";
import styles from "./imageInterludeFull.module.scss";

const cx = classNames.bind(styles);

export interface Props {
    alignment: ALIGNMENT;
    imageSmallWideA: string;
    imageSmallWideAAltText: string;
    imageSmallWideB: string;
    imageSmallWideBAltText: string;
    imageLargeWide: string;
    imageLargeWideAltText: string;
}

export const ImageInterludeFull: React.FC<Props> = (props: Props) => {
    const {
        alignment,
        imageSmallWideA,
        imageSmallWideAAltText,
        imageSmallWideB,
        imageSmallWideBAltText,
        imageLargeWide,
        imageLargeWideAltText,
    } = props;

    const imageInterludeContainerClassName = cx({
        [styles.imageInterludeContainer]: true,
        [styles.imageInterludeContainerLeft]: alignment === ALIGNMENT.LEFT,
        [styles.imageInterludeContainerRight]: alignment === ALIGNMENT.RIGHT,
    });

    const renderTopImages = () => {
        return (
            <div className={styles.imageInterludeContainerTop}>
                <div className={styles.wideImageContainer}>
                    <img src={imageSmallWideA} alt={imageSmallWideAAltText} />
                </div>
                <div className={styles.wideImageContainer}>
                    <img src={imageSmallWideB} alt={imageSmallWideBAltText} />
                </div>
            </div>
        );
    };

    const renderBottomImage = () => {
        return (
            <div className={styles.imageInterludeContainerBottom}>
                <img src={imageLargeWide} alt={imageLargeWideAltText} />
            </div>
        );
    };

    return (
        <div className={imageInterludeContainerClassName}>
            {renderTopImages()}
            {renderBottomImage()}
        </div>
    );
};
