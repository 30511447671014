import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import { UI_CONTEXT } from "@/analytics/constants";
import EVENTS from "@/analytics/events";

import LinkButton, { Props as LinkButtonProps } from "@/components/buttons/link-button";
import Heading from "@/components/heading/heading";

import styles from "./superWide.module.scss";

export interface CtaProps extends LinkButtonProps {
    hideWhenNavigationHidden?: boolean;
}

export interface Props {
    backgroundImage: string;
    heading: string;
    body: string;
    ctas: CtaProps[];
    uiContext: UI_CONTEXT;
}

const SuperWide: React.FC<Props> = ({ backgroundImage, heading, body, ctas, uiContext }) => {
    const router = useRouter();
    const [navHidden, setNavHidden] = useState<boolean>(router.query.hide_navigation === "true");

    useEffect(() => {
        if (typeof window !== "undefined") {
            setNavHidden(router.query.hide_navigation === "true");
        }
    }, [router.query.hide_navigation]);

    return (
        <div className={styles.container}>
            <div className={styles.backgroundContainer}>
                <div
                    className={styles.background}
                    style={{ backgroundImage: `url("${backgroundImage}")` }}
                />
            </div>
            <div className={styles.overlap}>
                <Heading headingStyle={styles.overlapHeading} header={heading} />
                {body && <p className={styles.overlapBody}>{body}</p>}
                {ctas && (
                    <div className={styles.ctaContainer}>
                        {ctas?.map((cta, index) => {
                            return (
                                <div className={styles.ctaOne} key={`cta-container-${index}`}>
                                    <LinkButton
                                        disabled={false}
                                        fluid={true}
                                        kind={cta.kind}
                                        label={cta.label}
                                        size={cta.size}
                                        show={!(navHidden && cta.hideWhenNavigationHidden)}
                                        url={cta.url}
                                        newTab={false}
                                        forwardUtm={cta.forwardUtm}
                                        analytics={{
                                            eventName: EVENTS.BANNER_CLICK,
                                            uiContext: uiContext,
                                            eventProps: {
                                                title: heading,
                                                description: body,
                                                outboundLink: cta.url,
                                            },
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SuperWide;
