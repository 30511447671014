import { BuilderConfig } from "../../../builder/types";
import { ACCENT_COLORS, COLORS } from "../../component.constants";
import { ICONS } from "../../icons";
import SplitterIcons, { Props } from "./splitter-icons";

export const splitterIconsBuilderConfig = {
    name: "SplitterIcons",
    inputs: [
        {
            name: "title",
            type: "string",
            defaultValue: "Title",
            required: true,
        },
        {
            name: "subtitle",
            type: "string",
            defaultValue: "Subtitle",
            required: true,
        },
        {
            name: "accentColor",
            type: "string",
            enum: Object.values(ACCENT_COLORS),
            defaultValue: ACCENT_COLORS.ORCA_PASTEL,
            required: true,
        },
        {
            name: "fullWidth",
            type: "boolean",
            defaultValue: false,
            required: true,
        },
        {
            name: "sections",
            type: "list",
            required: true,
            defaultValue: [
                {
                    headline: "Placeholder headline",
                    body: "Placeholder body",
                    icon: "CreditCard",
                    iconColor: "black",
                    overlayColor: "transparent",
                },
                {
                    headline: "Placeholder headline",
                    body: "Placeholder body",
                    icon: "CreditCard",
                    iconColor: "black",
                    overlayColor: "transparent",
                },
            ],
            subFields: [
                {
                    name: "headline",
                    type: "string",
                    defaultValue: "Placeholder headline",
                },
                {
                    name: "body",
                    type: "string",
                    defaultValue: "Placeholder body",
                },
                {
                    name: "icon",
                    type: "string",
                    enum: Object.keys(ICONS),
                    defaultValue: "CreditCard",
                },
                {
                    name: "iconColor",
                    type: "string",
                    enum: Object.keys(COLORS),
                    defaultValue: "black",
                },
                {
                    name: "overlayColor",
                    type: "string",
                    enum: Object.keys(COLORS),
                    defaultValue: "transparent",
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: SplitterIcons,
    config: splitterIconsBuilderConfig,
};

export default builderConfig;
