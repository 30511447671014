import React, { useRef } from "react";
import "scroll-behavior-polyfill";

import useViewport from "@/hooks/useViewport";

import IphoneShellHollow from "../../../assets/images/iphone/iphone-shell-hollow-corners.svg";
import styles from "./howItWorks.module.scss";

export interface Slide {
    header: string;
    subheader: string;
    body?: string;
    backgroundImage?: string;
    imageAltText?: string;
}

export type Props = {
    slides: Slide[];
};

const HowItWorks: React.FC<Props> = ({ slides }) => {
    const slidesContainerRef = useRef(null);
    const slideRowsRef = useRef([]);

    // TODO: this code is to fix a buggy behavior in chrome
    // - which the outer scroll won't trigger when inner container is set to scroll-snap-type mandatory
    // This workaround is to cancel scroll-snap-type only in chrome desktop
    const { isDesktop, hasWindow } = useViewport();

    const chromeRegex = /Chrome/;
    const googleIncRegex = /Google Inc/;
    const shouldHandleWheelEvent =
        hasWindow &&
        isDesktop &&
        chromeRegex.test(navigator.userAgent) &&
        googleIncRegex.test(navigator.vendor);

    const handleWheel = (e) => {
        if (!shouldHandleWheelEvent) {
            return;
        }

        const isLastInScroll =
            e.deltaY >= 0
                ? e.currentTarget === slideRowsRef.current[slides.length - 1]
                : e.currentTarget === slideRowsRef.current[0];

        if (isLastInScroll) {
            slidesContainerRef.current.style["scroll-snap-type"] = "none";
            slideRowsRef.current.forEach((rowRef) => (rowRef.style["scroll-snap-align"] = "none"));
        } else {
            slidesContainerRef.current.style["scroll-snap-type"] = "both mandatory";
            slideRowsRef.current.forEach(
                (rowRef, i) =>
                    (rowRef.style["scroll-snap-align"] = i !== slides.length - 1 ? "start" : "end")
            );
        }
    };
    // TODO: end of workaround code section

    const slideComponents = slides?.map(
        ({ backgroundImage, imageAltText, header, subheader, body }, idx) => (
            <div
                onWheel={handleWheel}
                className={styles.slideRow}
                key={`SlidesRow-${idx}`}
                ref={(el) => {
                    slideRowsRef.current[idx] = el;
                }}
            >
                <div className={styles.imageContainer}>
                    <div className={styles.mobileIphoneShellContainer}>
                        <IphoneShellHollow />
                    </div>
                    <div className={styles.imageSlideContainer} key={`${idx}-${backgroundImage}`}>
                        <img src={backgroundImage} alt={imageAltText} />
                    </div>
                </div>
                <div className={`${styles.contentContainer}`} key={`${idx}-${header}`}>
                    <h1 className={styles.header}>{header}</h1>
                    <p className={styles.subheader}>{subheader}</p>
                    <p className={styles.body}>{body}</p>
                </div>
            </div>
        )
    );

    return (
        <div className={styles.slidesContainer} ref={slidesContainerRef}>
            <div className={styles.iphoneShellContainer}>
                <IphoneShellHollow />
            </div>
            {slideComponents}
        </div>
    );
};

export default HowItWorks;
