export const KINDS = {
    TWO_BY_TWO: "twoByTwo",
    FOUR_BY_ONE: "fourByOne",
    THREE_BY_ONE: "threeByOne",
} as const;

export const FONT_SIZES = {
    LargestHeading: "LargestHeading",
    SecondLargestHeading: "SecondLargestHeading",
    MuchLargerHeading: "MuchLargerHeading",
    LargerHeading: "LargerHeading",
    LargeHeading: "LargeHeading",
};

export type KIND = typeof KINDS[keyof typeof KINDS];
export type FONT_SIZE = typeof FONT_SIZES[keyof typeof FONT_SIZES];
