import React from "react";

import Heading from "@/components/heading/heading";

import styles from "./full-bleed.module.scss";

export interface Props {
    backgroundImage: string;
    logoImage?: string | null | undefined;
    heading: string;
    body?: string;
}

const FullBleed: React.FC<Props> = ({ heading, body, backgroundImage, logoImage }) => {
    return (
        <div className={styles.fullBleed}>
            <div className={styles.backgroundContainer}>
                <div
                    className={styles.background}
                    style={{ backgroundImage: `url("${backgroundImage}")` }}
                />
            </div>
            <div className={styles.overlay}>
                {logoImage && <img alt="logo" src={logoImage} className={styles.logo} />}
                <Heading headingStyle={styles.overlayHeading} header={heading} />
                {body && <p className={styles.overlayBody}>{body}</p>}
            </div>
        </div>
    );
};

export default FullBleed;
