import classNames from "classnames/bind";
import React from "react";

import Grid from "../../components/layout/grid";
import { ACCENT_COLOR } from "../component.constants";
import { FONT_SIZE, FONT_SIZES, KIND, KINDS } from "./statistic.constants";
import styles from "./statistic.module.scss";

const cx = classNames.bind(styles);

interface Statistic {
    metric: string;
    description: string;
}

export interface Props {
    fullWidth: boolean;
    kind: KIND;
    fontSize?: FONT_SIZE;
    accentColor: ACCENT_COLOR;
    statistics: Statistic[];
}

const Statistic: React.FC<Props> = (props: Props) => {
    const {
        fullWidth,
        kind,
        fontSize = FONT_SIZES.LargestHeading,
        accentColor,
        statistics,
    } = props;
    const containerStyles = cx({
        [styles.twoByTwoContainer]: kind === KINDS.TWO_BY_TWO,
        [styles.threeByOneContainer]: kind === KINDS.THREE_BY_ONE,
        [styles.fourByOneContainer]: kind === KINDS.FOUR_BY_ONE,
        [styles[`accent${accentColor}`]]: true,
    });
    const metricStyles = cx({
        [styles[`${fontSize}`]]: true,
        [styles.metric]: true,
    });

    return (
        <div className={containerStyles}>
            <Grid fullWidth={fullWidth}>
                {statistics.map((statistic, index) => {
                    const statisticStyle = cx({
                        [styles.twoByTwo]: kind === KINDS.TWO_BY_TWO,
                        [styles.threeByOne]: kind === KINDS.THREE_BY_ONE,
                        [styles.fourByOne]: kind === KINDS.FOUR_BY_ONE,
                    });

                    return (
                        <div className={statisticStyle} key={index}>
                            <h2 className={metricStyles}>{statistic.metric}</h2>
                            <p className={styles.description}>{statistic.description}</p>
                        </div>
                    );
                })}
            </Grid>
        </div>
    );
};

export default Statistic;
