import classNames from "classnames/bind";
import React from "react";

import { KIND, SPACING, SPACINGS } from "./divider.constants";
import styles from "./divider.module.scss";

const cx = classNames.bind(styles);

export interface Props {
    kind: KIND;
    spacing?: SPACING;
}

export const Divider: React.FC<Props> = ({ kind, spacing = SPACINGS.LARGE }: Props) => {
    const dividerClassName = cx({
        [styles.divider]: true,
        [styles[kind]]: true,
        [styles[spacing]]: true,
    });
    return <div className={dividerClassName} />;
};
