import { SHOP_DIRECTORY_API_HOST } from "@/shared/constants";

import { AsyncPropsMapper, BuilderConfig } from "@/builder/types";

import { fetchCollection, fetchCollectionProducts } from "@/pages/api/store-directory/collections";

import { APICollectionProps } from "@/interfaces/collection-interface";

import { CuratedCollection, Props } from "./curated-collection";

const mapper: AsyncPropsMapper<{ data: Array<APICollectionProps> }> = ({ data }) => {
    return {
        collection: data.data.map((collection: APICollectionProps) => ({
            name: collection.attributes.name,
            value: collection.attributes.slug,
        })),
    };
};

const builderConfig: BuilderConfig<Props> = {
    asyncProps: async (ctx, props) => {
        return {
            initialCollection: await fetchCollection(ctx.locale, props.slug),
            initialCollectionProducts: await fetchCollectionProducts(ctx.locale, props.slug),
        };
    },
    component: CuratedCollection,
    config: {
        name: "Curated Collection",
        hideFromInsertMenu: true, // Deprecating this component
        inputs: [
            {
                name: "slug",
                type: "dynamic-dropdown",
                required: true,
                options: {
                    url: `${SHOP_DIRECTORY_API_HOST}/api/v1/collections?locale={{targeting.locale.0}}`,
                    mapper: mapper.toString(),
                },
            },
            {
                name: "initialCollection",
                type: "object",
                required: false,
                hideFromUI: true,
            },
            {
                name: "initialCollectionProducts",
                type: "list",
                required: false,
                hideFromUI: true,
                defaultValue: [],
            },
        ],
    },
};

export default builderConfig;
