import { BuilderConfig } from "../../../builder/types";
import TextBody, { Props } from "./text-body";

export const TextBodyConfig = {
    name: "TextBody",
    inputs: [
        {
            name: "body",
            type: "html",
            defaultValue: "Body Content",
            required: true,
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: TextBody,
    config: TextBodyConfig,
};

export default builderConfig;
