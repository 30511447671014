import { AsyncPropsMapper, BuilderConfig } from "../../../builder/types";
import { APICategoryProps } from "../../../interfaces/category-interface";
import { SHOP_DIRECTORY_API_HOST, SIZES } from "../../../shared/constants";
import Cta4x3, { Props } from "./cta-4x3";

const mapper: AsyncPropsMapper<{ data: Array<APICategoryProps> }> = ({ data }) => {
    return {
        categorySlug: data.data.map((category: APICategoryProps) => ({
            name: category.attributes.name,
            value: category.attributes.slug,
        })),
    };
};

export const DoubleHeadlineConfig = {
    name: "CTA 4x3",
    inputs: [
        {
            friendlyName: "Show on Index page",
            name: "showOnIndex",
            type: "boolean",
            defaultValue: false,
            required: true,
        },
        {
            friendlyName: "Show on Category page",
            name: "categorySlug",
            type: "dynamic-dropdown",
            required: false,
            options: {
                url: `${SHOP_DIRECTORY_API_HOST}/api/v1/categories?locale={{targeting.locale.0}}`,
                mapper: mapper.toString(),
            },
        },
        {
            name: "backgroundImage",
            type: "file",
            allowedFileTypes: ["jpg", "jpeg", "png"],
            required: true,
        },
        {
            name: "heading",
            type: "richText",
            defaultValue: "CTA 4x3 Heading",
        },
        {
            name: "headingSize",
            type: "string",
            enum: Object.values(SIZES),
            defaultValue: SIZES.REGULAR,
            required: true,
        },
        {
            name: "body",
            type: "string",
            defaultValue: "CTA 4x3 body text",
        },
        {
            name: "ctaLabel",
            type: "string",
            defaultValue: "Shop now",
            required: true,
        },
        {
            name: "url",
            type: "string",
            defaultValue: "/",
            required: true,
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: Cta4x3,
    config: DoubleHeadlineConfig,
};

export default builderConfig;
