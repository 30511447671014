import { BuilderConfig } from "../../builder/types";
import Chip, { Props } from "./chip";

export const ChipBuilderConfig = {
    name: "Chip",
    inputs: [
        {
            name: "label",
            type: "string",
            defaultValue: "On Sale",
        },
        {
            name: "size",
            type: "string",
            enum: ["large", "regular", "small"],
            defaultValue: "regular",
        },
        {
            name: "kind",
            type: "string",
            enum: ["dropdown", "single"],
            defaultValue: "single",
        },
        {
            name: "disabled",
            type: "boolean",
            defaultValue: false,
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: Chip,
    config: ChipBuilderConfig,
};

export default builderConfig;
