import { Fragment, FC } from "react";

import { UI_CONTEXT } from "@/analytics/constants";

import CardCarousel from "@/components/carousel/carousel";
import CardCarouselHeader from "@/components/carousel/carousel-header";
import { ProductTile } from "@/components/product-tiles/product-tile";

import { useCategoryProducts } from "@/hooks/useCategory";
import { useCollection } from "@/hooks/useCollection";

import { CollectionProps } from "@/interfaces/collection-interface";
import { ProductProps } from "@/interfaces/products-interface";

export type Props = {
    slug: string;
    initialCollection?: CollectionProps;
    initialCollectionProducts?: Array<ProductProps>;
    title?: string;
    ctaNewTab: boolean;
    uiContext: UI_CONTEXT;
    showViewMore: boolean;
};

const ProductsCarousel: FC<Props> = ({
    slug,
    title,
    ctaNewTab,
    uiContext,
    showViewMore,
    initialCollection,
    initialCollectionProducts,
}) => {
    const collection = useCollection(slug, {
        initialState: initialCollection,
    });

    const products = useCategoryProducts(slug, {
        initialState: initialCollectionProducts,
    });

    if (!collection || products.length === 0) {
        return null;
    }

    return (
        <Fragment>
            <CardCarouselHeader
                title={title || collection.name}
                subTitle={collection.pageTitle}
                ctaUrl={collection.rootPageUrl}
                eventProps={{
                    categoryId: collection.id,
                    categoryName: collection.name,
                }}
                ctaNewTab={ctaNewTab}
                uiContext={uiContext}
                showViewMore={showViewMore}
            />
            <CardCarousel carouselType={"default"}>
                {products.map((product, position) => (
                    <ProductTile
                        category={collection}
                        key={product.id}
                        product={product}
                        position={position}
                        uiContext={uiContext}
                    />
                ))}
            </CardCarousel>
        </Fragment>
    );
};

const ProductsCarouselGuard: React.FC<Props> = (props) => {
    if (!props.slug) {
        return null;
    }
    return <ProductsCarousel {...props} />;
};

export default ProductsCarouselGuard;
