import React from "react";

import { UI_CONTEXT } from "@/analytics/constants";

import { ProductTile } from "@/components/product-tiles/product-tile";

import { useCategory, useCategoryProducts } from "@/hooks/useCategory";

import { CategoryProps } from "@/interfaces/category-interface";
import { ProductProps } from "@/interfaces/products-interface";

import CardCarousel from "../carousel";
import CardCarouselHeader from "../carousel-header";

export type Props = {
    categorySlug: string;
    initialCategory?: CategoryProps;
    initialCategoryProducts?: Array<ProductProps>;
    title?: string;
    ctaNewTab: boolean;
    uiContext: UI_CONTEXT;
    showViewMore: boolean;
};

const PreviewProductCarousel: React.FC<Props> = ({
    categorySlug,
    title,
    ctaNewTab,
    uiContext,
    initialCategory,
    initialCategoryProducts,
    showViewMore,
}) => {
    const category = useCategory(categorySlug, {
        initialState: initialCategory,
    });

    const products = useCategoryProducts(categorySlug, {
        initialState: initialCategoryProducts,
    });

    if (!category) {
        return null;
    }

    return (
        <>
            <CardCarouselHeader
                title={title || category.name}
                ctaUrl={category.rootPageUrl}
                eventProps={{
                    categoryId: category.id,
                    categoryName: category.name,
                }}
                ctaNewTab={ctaNewTab}
                uiContext={uiContext}
                showViewMore={showViewMore}
            />
            <CardCarousel carouselType={"default"}>
                {products.map((product, position) => (
                    <ProductTile
                        category={category}
                        key={product.id}
                        product={product}
                        position={position}
                        uiContext={uiContext}
                    />
                ))}
            </CardCarousel>
        </>
    );
};

const PreviewProductCarouselGuard: React.FC<Props> = (props) => {
    if (!props.categorySlug) {
        return null;
    }
    return <PreviewProductCarousel {...props} />;
};

export default PreviewProductCarouselGuard;
