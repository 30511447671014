import { withChildren } from "@builder.io/react";

import { BuilderConfig } from "../../../builder/types";
import { KINDS, SIZES } from "../../buttons/buttons.constants";
import Phone, { Props } from "./phone";

const PhoneWithChildren = withChildren(Phone);

export const PhoneBuilderConfig = {
    name: "Phone Banner",
    inputs: [
        {
            name: "headline",
            type: "richText",
            defaultValue: "A Headline of Some Respectable Length.",
            required: false,
        },
        {
            name: "subHeadline",
            type: "string",
            defaultValue: "Subhead Support",
            required: false,
        },
        {
            name: "body",
            type: "string",
            defaultValue:
                "Supporting copy can have a varying length even at a moderate body copy size of 24px.",
            required: true,
        },
        {
            name: "image",
            type: "file",
            required: true,
            allowedFileTypes: ["jpeg", "jpg", "png"],
        },
        {
            name: "imageAltText",
            type: "string",
            defaultValue: "Image Description",
        },
    ],
    childRequirements: {
        message: "You can only put LinkButton",
        query: {
            "component.name": { $in: ["LinkButton"] },
        },
    },
    defaultChildren: [
        {
            "@type": "@builder.io/sdk:Element" as const,
            component: {
                name: "LinkButton",
                options: {
                    label: "Hello",
                    kind: KINDS.PRIMARY,
                    size: SIZES.REGULAR,
                    fluid: true,
                    disabled: false,
                    show: true,
                    url: "/",
                },
            },
        },
        {
            "@type": "@builder.io/sdk:Element" as const,
            component: {
                name: "LinkButton",
                options: {
                    label: "Hello",
                    kind: KINDS.SECONDARY,
                    size: SIZES.REGULAR,
                    fluid: true,
                    disabled: false,
                    show: true,
                    url: "/",
                },
            },
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: PhoneWithChildren,
    config: PhoneBuilderConfig,
};

export default builderConfig;
