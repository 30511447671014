import { AsyncPropsMapper, BuilderConfig } from "../../../builder/types";
import { APICategoryProps } from "../../../interfaces/category-interface";
import { SHOP_DIRECTORY_API_HOST } from "../../../shared/constants";
import Countdown, { Props } from "./countdown";

const generateDefaultDate = () => {
    const dateTime = new Date();
    dateTime.setHours(dateTime.getHours() + 12);
    return dateTime.getTime();
};

const mapper: AsyncPropsMapper<{ data: Array<APICategoryProps> }> = ({ data }) => {
    return {
        categorySlug: data.data.map((category: APICategoryProps) => ({
            name: category.attributes.name,
            value: category.attributes.slug,
        })),
    };
};

export const countdownBuilderConfig = {
    name: "Countdown",
    inputs: [
        {
            friendlyName: "Show on Index page",
            name: "showOnIndex",
            type: "boolean",
            defaultValue: false,
            required: true,
        },
        {
            friendlyName: "Show on Category page",
            name: "categorySlug",
            type: "dynamic-dropdown",
            required: false,
            options: {
                url: `${SHOP_DIRECTORY_API_HOST}/api/v1/categories?locale={{targeting.locale.0}}`,
                mapper: mapper.toString(),
            },
        },
        {
            name: "endDate",
            type: "date",
            required: true,
            defaultValue: generateDefaultDate(),
        },
        {
            name: "heading",
            type: "richText",
            required: true,
            defaultValue: "The countdown is on!",
        },
        {
            name: "body",
            type: "string",
            required: true,
            defaultValue: "Shop now and pay later with the latest and greatest stores.",
        },
        {
            name: "newTab",
            type: "boolean",
            defaultValue: false,
            required: true,
        },
        {
            name: "url",
            type: "string",
            defaultValue: "/",
            required: false,
        },
        {
            name: "ctaLabel",
            type: "string",
            defaultValue: "Shop Now",
            required: false,
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: Countdown,
    config: countdownBuilderConfig,
};

export default builderConfig;
