import React from "react";

import styles from "../text.module.scss";

export type Props = {
    header: string;
};

const TextHeader: React.FC<Props> = ({ header }) => {
    return <h1 className={styles.header}>{header}</h1>;
};

export default TextHeader;
