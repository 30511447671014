import { BuilderConfig } from "../../../builder/types";
import DoubleHeadline, { Props } from "./double-headline";

export const DoubleHeadlineConfig = {
    name: "Double Headline",
    inputs: [
        {
            name: "backgroundImage",
            type: "file",
            allowedFileTypes: ["jpg", "jpeg", "png"],
            required: true,
        },
        {
            name: "primaryHeading",
            type: "string",
            required: true,
        },
        {
            name: "secondaryHeading",
            type: "string",
            required: true,
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: DoubleHeadline,
    config: DoubleHeadlineConfig,
};

export default builderConfig;
