import React from "react";

import { useTrustpilotReviews } from "@/hooks/useTrustpilotReviews";

import { TrustpilotHeader } from "./trustpilot-header";
import { TrustpilotReviewsList } from "./trustpilot-reviews-list";
import styles from "./trustpilot.module.scss";

const DisplayTrustpilotReviews = () => {
    const { reviews, totalReviews } = useTrustpilotReviews("/api/trustpilot/trustpilotReviews");
    return (
        <>
            <TrustpilotHeader totalReviewCount={totalReviews} />
            <TrustpilotReviewsList reviews={reviews} />
        </>
    );
};

export const TrustpilotContainer: React.FC = () => {
    return (
        <div className={styles.trustpilotContainer}>
            <DisplayTrustpilotReviews />
        </div>
    );
};
