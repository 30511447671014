import classNames from "classnames";
import React, { MouseEventHandler } from "react";

import type { UI_CONTEXT } from "../../analytics/constants";
import { SIZES, FILL, TRANSFORM } from "../../shared/constants";
import { SIZE } from "../../types/components";
import Dropdown from "../icons/dropdown";
import { KIND, KINDS, MODIFIER, MODIFIERS } from "./chips.constants";
import styles from "./chips.module.scss";

export interface Props {
    disabled: boolean;
    fill?: FILL;
    handleClick?: MouseEventHandler;
    icon?: JSX.Element;
    kind: KIND;
    label: string;
    modifier?: MODIFIER;
    size: SIZE;
    transform?: TRANSFORM;
    isFilterOpen?: boolean;
    uiContext?: UI_CONTEXT;
    isActive?: boolean;
    testId?: string;
}

const Chip: React.FC<Props> = ({
    disabled,
    fill,
    handleClick,
    icon,
    kind,
    label,
    modifier,
    size,
    transform,
    isFilterOpen,
    isActive,
    testId,
}) => {
    const isIconPlusLabelModifier = modifier && modifier === MODIFIERS.ICON_PLUS_LABEL;
    const isInStoreModifier = modifier && modifier === MODIFIERS.IN_STORE;

    const dropdownProps = {
        active: isFilterOpen,
        disabled,
        fill,
        transform,
    };

    const chipStyle = classNames({
        [styles.disabled]: disabled,
        [styles.chip]: !disabled,
        [styles[size]]: true,
        [styles.chipActive]: isActive,
    });

    const labelStyle = classNames({
        [styles.labelPlusDropdown]: kind === KINDS.DROPDOWN && size !== SIZES.SMALL,
        [styles.labelPlusDropdownSmall]: kind === KINDS.DROPDOWN && size === SIZES.SMALL,
        [styles.iconPlusLabel]: isIconPlusLabelModifier && size !== SIZES.SMALL,
        [styles.iconPlusLabelSmall]: isIconPlusLabelModifier && size === SIZES.SMALL,
        [styles.inStoreLabel]: isInStoreModifier && size !== SIZES.SMALL,
        [styles.inStoreLabelSmall]: isInStoreModifier && size === SIZES.SMALL,
    });

    const clickCallback: MouseEventHandler = (e) => {
        handleClick && handleClick(e);
    };

    const dataAttributes = testId ? { "data-testid": testId } : {};

    return (
        <button
            className={chipStyle}
            role={"button"}
            onClick={(e) => clickCallback(e)}
            {...dataAttributes}
        >
            {isIconPlusLabelModifier && icon}
            {isInStoreModifier && icon}
            <span className={labelStyle}>{label}</span>
            {kind === KINDS.DROPDOWN && <Dropdown {...dropdownProps} />}
        </button>
    );
};

export default Chip;
