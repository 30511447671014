/* eslint-disable @typescript-eslint/no-var-requires */
const colors = {
    initial: "initial",
    transparent: "rgba(225,225,225,0)",

    black: "black",
    "grey-darkest": "#606a72",
    "grey-darker": "#bfc5ca" /* mid-gray */,
    "grey-dark": "#8a959e",
    grey: "#e7eaec",
    "grey-300": "#cbd5db",
    "grey-light": "#eef1f3",
    "grey-lighter": "#f1f5f8",
    "grey-lightest": "#fafbfc",
    "stroke-grey": "#e6eaec",
    "off-black": "#27323f",

    white: "#ffffff",
    aqua: "#17d3db",
    navy: "#0C6199",

    "red-darkest": "#3b0d0c",
    "red-darker": "#621b18",
    "red-dark": "#cc1f1a",
    red: "#e3342f",
    "red-light": "#f54040",
    "red-lighter": "#f9acaa",
    "red-lightest": "#fcebea",

    "orange-darkest": "#462a16",
    "orange-darker": "#613b1f",
    "orange-dark": "#de751f",
    orange: "#f6993f",
    "orange-light": "#faad63",
    "orange-lighter": "#fcd9b6",
    "orange-lightest": "#fff5eb",

    "yellow-darkest": "#453411",
    "yellow-darker": "#684f1d",
    "yellow-dark": "#f2d024",
    yellow: "#fede35",
    "yellow-light": "#fff382",
    "yellow-lighter": "#fff9c2",
    "yellow-lightest": "#fcfbeb",

    "green-darkest": "#0f2f21",
    "green-darker": "#1a4731",
    "green-dark": "#1f9d55",
    "green-discount": "#368071",
    green: "#38c172",
    "green-light": "#51d88a",
    "green-lighter": "#a2f5bf",
    "green-lightest": "#e3fcec",

    "teal-darkest": "#0d3331",
    "teal-darker": "#20504f",
    "teal-dark": "#38a89d",
    teal: "#4dc0b5",
    "teal-light": "#64d5ca",
    "teal-lighter": "#a0f0ed",
    "teal-lightest": "#e8fffe",

    "blue-darkest": "#12283a",
    "blue-darker": "#16a0d5" /* mid-blue  */,
    "blue-dark": "#2779bd",
    blue: "#3490dc",
    "blue-light": "#6cb2eb",
    "blue-lighter": "#bcdefa",
    "blue-lightest": "#eff8ff",
    "cyan-blue": "#EFF4FB",

    "indigo-darkest": "#191e38",
    "indigo-darker": "#2f365f",
    "indigo-dark": "#5661b3",
    indigo: "#6574cd",
    "indigo-light": "#7886d7",
    "indigo-lighter": "#b2b7ff",
    "indigo-lightest": "#e6e8ff",

    "purple-darkest": "#21183c",
    "purple-darker": "#382b5f",
    "purple-dark": "#794acf",
    purple: "#9561e2",
    "purple-light": "#a779e9",
    "purple-lighter": "#d6bbfc",
    "purple-lightest": "#f3ebff",

    "pink-darkest": "#451225",
    "pink-darker": "#6f213f",
    "pink-dark": "#eb5286",
    pink: "#f66d9b",
    "pink-light": "#fa7ea8",
    "pink-lighter": "#ffbbca",
    "pink-lightest": "#ffebef",

    "bright-pink": "#ec3b91",
    "bright-red": "#ef1231",
    "bright-green": "#70d95f",
    "gradient-light": "#62d0d9",
    "gradient-mid": "#73bbd8",
    "gradient-dark": "#51abde",
    "warm-yellow": "#f5cd40",
    "status-bar": "#0e568e",
    "black-10": "rgba(0, 0, 0, 0.1)",
    "black-20": "rgba(0, 0, 0, 0.2)",
    "black-30": "rgba(0, 0, 0, 0.3)",
    "black-40": "rgba(0, 0, 0, 0.4)",
    "black-50": "rgba(0, 0, 0, 0.5)",
    "black-60": "rgba(0, 0, 0, 0.6)",
    "black-70": "rgba(0, 0, 0, 0.7)",
    "black-80": "rgba(0, 0, 0, 0.8)",
    "black-90": "rgba(0, 0, 0, 0.9)",
    "off-black-50": "rgba(55, 55, 55, 0.5)",
    "off-black-60": "rgba(55, 55, 55, 0.6)",
    coral: "#fed8cc",
    gorse: "#ffe243",
    /* Custom colors */
    "lemon-dark": "#FFB600",
    "lemon-pastel": "#FFF9D9",
    mint: "#B2FCE4",
    "mint-hover": "#7FFAD2",
    "mint-press": "#4EF8C1",
    "mint-light": "#80D187",

    plum: "#EADDF2",
    "plum-hover": "#D4B9E4",
    "plum-press": "#BD94D6",

    orca: "#3375C9",
    "orca-hover": "#285C9F",
    "orca-press": "#1E4576",
    "orca-dark": "#0E245B",
    "orca-pastel": "#EFF4FB",

    fire: "#F5643B",
    "fire-hover": "#F33F0C",
    "fire-press": "#C2330A",
    "fire-enabled": "#CC3300",

    "ghost-hover": "#D6E4F5",
    "ghost-press": "#ADC9EB",

    gray10: "#1A1A1A",
    gray20: "#333333",
    gray30: "#4D4D4D",
    gray40: "#666666",
    gray45: "#646A6F",
    gray50: "#808080",
    gray60: "#999999",
    gray70: "#B3B3B3",
    gray80: "#CCCCCC",
    gray90: "#E6E6E6",
    gray100: "#F5F5F5",
    gray300: "#cbd5db",

    "ap-black": "#373737",
    "ap-yellow": "#fbdb56",
    "ap-blue": "#1b3667",
    "ap-secondary-blue": "#2d65b8",
    "ap-blush": "#e88184",
    "ap-nude": "#fcd8cd",
    "ap-mid-blue": "#99ceef",
    "ap-electric-teal": "#30e1d4",
    "ap-learn-green": "#bfe2ce",
    "ap-blue-dark": "#1F4066",
};

const plugin = require("tailwindcss/plugin");
const typography = require("./utils/typography");
const positioning = require("./utils/positioning");
const aspectRatios = require("./utils/aspect-ratios");
const scrollbarOverflow = require("./utils/scrollbar-overflow");
const inputs = require("./utils/inputs");

module.exports = {
    content: ["./pages/**/*.{js,ts,jsx,tsx}", "./components/**/*.{js,ts,jsx,tsx}"],
    prefix: "",
    important: false,
    separator: ":",
    theme: {
        screens: {
            sm: {
                max: "767px",
            },
            md: {
                min: "768px",
                max: "1024px",
            },
            lg: {
                min: "1025px",
            },
        },
        borderColor: global.Object.assign(
            {
                default: colors["grey-light"],
                "black-10": colors["black-10"],
                "bondi-mint": colors["mint"],
            },
            colors
        ),
        colors: colors,
        fontFamily: {
            italianplate: "Italian Plate No2 Expanded, Helvetica, Arial",
            roboto: ["Roboto", "sans"],
            gotham: ["Gotham A", "Gotham B", "gotham", "sans-serif"],
            merriweather: ["Merriweather", "serif"],
        },
        fontSize: {
            xxs: ".625rem", // 10px
            xs: ".75rem", // 12px
            sm: ".8125rem", // 13px
            md: ".875rem", // 14px
            base: "1rem", // 16px
            lg: "1.25rem", // 20px
            xl: "1.5rem", // 24px
            "2xl": "2rem", // 32px
            "3xl": "2.5rem", // 40px
            "4xl": "3rem", // 48px
            "5xl": "3.5rem", // 56px
            "6xl": "4rem", // 64px
            "7xl": "4.5rem", // 72px
            "8xl": "5rem", // 80px
        },
        fontWeight: {
            hairline: 100,
            thin: 200,
            light: 300,
            normal: 400,
            medium: 500,
            semibold: 600,
            bold: 700,
            extrabold: 800,
            black: 900,
        },
        leading: {
            micro: 0.75,
            none: 1,
            tiny: 1.1,
            tight: 1.25,
            normal: 1.5,
            12: "3rem",
            semimedium: 1.625,
            semiloose: 1.875,
            loose: 2,
        },
        tracking: {
            tight: "-0.05em",
            normal: "0",
            tiny: "0.03rem",
            wide: "0.05em",
            huge: "0.1em",
            xhuge: "0.2rem",
            mega: "0.25em",
            xmega: "0.33em",
        },
        svgFill: {
            current: "currentColor",
        },
        svgStroke: {
            current: "currentColor",
        },
        extend: {
            backdropFilter: {
                none: "none",
                "blur-2": "blur(2px)",
                "blur-32": "blur(32px)",
            },
            backgroundSize: {
                full: "100% 100%",
            },
            borderRadius: {
                none: "0",
                default: "0.25rem",
                xlg: ".75rem",
                xxlg: "1rem",
                xxxlg: "1.5rem",
            },
            borderWidth: {
                0: "0",
                "0-1": "0.06rem",
                1: ".125rem",
                10: "16px",
            },
            boxShadow: {
                default: "0 2px 4px 0 rgba(0,0,0,0.10)",
                md: "0 4px 8px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08)",
                lg: "0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08)",
                "xs-inner-grey80": "inset 0 0 0 0.5px rgba(204, 204, 204, 1)",
                "xs-inner-black": "inset 0 0 0 0.5px rgba(0, 0, 0, 1)",
                "sm-inner-mint": "inset 0 0 0 2px rgba(178, 252, 228, 1)",
                "sm-inner-black": "inset 0 0 0 2px rgba(0, 0, 0, 1)",
                outline: "0 0 0 3px rgba(52,144,220,0.5)",
            },
            filter: {
                // defaults to {}
                none: "none",
                grayscale: "grayscale(1)",
                invert: "invert(1)",
                sepia: "sepia(1)",
            },
            flex: {
                "1-quarter": "1 1 25%",
                "1-third": "1 1 33%",
                "1-half": "1 1 50%",
                2: "2 1 0%",
                half: "0 0 50%",
                full: "1 1 100%",
            },
            height: {
                px: "1px",
                "6-75": "1.6875rem",
                "11-5": "2.875rem",
                image: "7rem",
                21: "5.25rem",
                21.5: "5.375rem",
                38: "9.5rem",
                45: "11.25rem",
                47: "11.75rem",
                60: "15rem",
                82: "20.5rem",
                84: "21rem",
                140: "35rem",
                141: "35.25rem",
                144: "36rem",
                220: "55rem",
            },
            letterSpacing: {
                huge: "0.25em",
            },
            lineHeight: {
                micro: "0.75rem",
                tiny: "1.1rem",
                semiloose: "1.875rem",
                "3-5": "0.875rem",
                "5-5": "1.375rem",
                11: "2.75rem",
                12: "3rem",
                13: "3.25rem",
                14: "3.50rem",
                15: "3.75rem",
                16: "4rem",
                17: "4.25rem",
                18: "4.5rem",
                19: "4.75rem",
                20: "5rem",
                21: "5.25rem",
                22: "5.5rem",
            },
            maxHeight: {
                8: "2rem",
                75: "18.75rem",
                220: "55rem",
                small: "400px",
                xsmall: "250px",
            },
            minHeight: {
                10: "2.5rem",
                47: "11.75rem",
            },
            maxWidth: {
                xxxxxs: "4rem",
                xxxxs: "8rem",
                xxxs: "10rem",
                xxs: "16rem",
                xs: "19.5rem",
                vs: "28rem", // I'm sorry I  couldn't think of another abbreviation, please forgive me
                sm: "30rem",
                smd: "32rem",
                md: "40rem",
                mdl: "48rem",
                lg: "50rem",
                xl: "60rem",
                xlg: "64rem",
                "2xl": "70rem",
                "3xl": "80rem",
                "4xl": "90rem",
                "5xl": "100rem",
                app: "1440px", // Same as body max width
            },
            opacity: {
                15: ".15",
                95: ".95",
            },
            spacing: {
                full: "100%",
                "-full": "-100%",
                "1-25": "0.3125rem",
                15: "3.75rem",
                17: "4.25rem",
                18: "4.5rem",
                22: "5.5rem",
                28: "7rem",
                30: "7.5rem",
                34: "8.5rem",
                42: "10.5rem",
                43: "10.75rem",
                46: "11.5rem",
                57: "14.25rem",
                100: "25rem",
                120: "30rem",
                124: "31rem",
                128: "32rem",
                360: "90rem",
            },
            width: {
                "4-5": "1.125rem",
                "6-75": "1.6875rem",
                "11-5": "2.875rem",
                "1/10": "10%",
                "3/10": "30%",
                "7/10": "70%",
                "9/10": "90%",
                "15/100": "15%",
                21.5: "5.375rem",
                27.5: "6.875rem",
                34: "8.5rem",
                41: "10.5rem",
                47: "11.75rem",
                50: "12.5rem",
                65: "16.25rem",
                70: "17.5rem",
                86: "21.5rem",
                106: "26.5625rem",
                116: "29rem",
                130: "32.5rem",
                200: "50rem",
                209: "52.25rem",
            },
            minWidth: {
                8: "2rem",
                21.5: "5.375rem",
                30: "7.5rem",
                41: "10.25rem",
                70: "17.5rem",
            },
            zIndex: {
                1: 1,
                2: 2,
                3: 3,
                5: 5,
                999: 999,
                9999: 9999,
                max: 2147483647,
            },
        },
    },
    corePlugins: {},
    plugins: [
        plugin(typography),
        plugin(positioning),
        plugin(aspectRatios),
        plugin(scrollbarOverflow),
        plugin(inputs),
        require("tailwindcss-filters"),
    ],
    future: {
        removeDeprecatedGapUtilities: true,
        purgeLayersByDefault: true,
    },
};
