import { withChildren } from "@builder.io/react";

import { BuilderConfig } from "../../../builder/types";
import { SIZES } from "../../../shared/constants";
import Cta4x3, { Props } from "./cta-4x3";

const Cta4x3WithChildren = withChildren(Cta4x3);

export const CTA4x3Config = {
    name: "Cta4x3",
    inputs: [
        {
            name: "backgroundImage",
            type: "file",
            allowedFileTypes: ["jpg", "jpeg", "png"],
            required: true,
        },
        {
            name: "heading",
            type: "richText",
            defaultValue: "CTA 4x3 Heading",
        },
        {
            name: "headingSize",
            type: "string",
            enum: Object.values(SIZES),
            defaultValue: SIZES.REGULAR,
            required: true,
        },
        {
            name: "body",
            type: "string",
            defaultValue: "CTA 4x3 body text",
        },
        {
            name: "ctaLabel",
            type: "string",
            defaultValue: "Shop now",
            required: true,
        },
        {
            name: "url",
            type: "string",
            defaultValue: "/",
            required: true,
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: Cta4x3WithChildren,
    config: CTA4x3Config,
};

export default builderConfig;
