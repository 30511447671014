import { SHOP_DIRECTORY_API_HOST } from "@/shared/constants";

import { AsyncPropsMapper, BuilderConfig } from "@/builder/types";

import { fetchCollection, fetchCollectionProducts } from "@/pages/api/store-directory/collections";

import { APICollectionData, APICollectionProps } from "@/interfaces/collection-interface";

import ProductsCarousel, { Props } from "./products-carousel";

const mapper: AsyncPropsMapper<APICollectionData> = ({ data }) => {
    const { included = [] } = data;
    const allResults = data.data.concat(included);
    return {
        collection: allResults.map((collection: APICollectionProps) => ({
            name: collection.attributes.name,
            value: collection.attributes.slug,
        })),
    };
};

const builderConfig: BuilderConfig<Props> = {
    asyncProps: async (ctx, props) => {
        return {
            initialCollection: await fetchCollection(ctx.locale, props.slug),
            initialCollectionProducts: await fetchCollectionProducts(ctx.locale, props.slug),
        };
    },
    component: ProductsCarousel,
    config: {
        name: "Products Carousel",
        hideFromInsertMenu: true, // Deprecating in favour of products-carousel-v2
        inputs: [
            {
                name: "slug",
                type: "dynamic-dropdown",
                required: true,
                options: {
                    url: `${SHOP_DIRECTORY_API_HOST}/api/v1/collections?locale={{targeting.locale.0}}`,
                    mapper: mapper.toString(),
                },
            },
            {
                name: "initialCollection",
                type: "object",
                required: false,
                hideFromUI: true,
            },
            {
                name: "initialCollectionProducts",
                type: "list",
                required: false,
                hideFromUI: true,
                defaultValue: [],
            },
            {
                name: "title",
                type: "string",
                required: false,
                defaultValue: "",
            },
            {
                name: "ctaNewTab",
                type: "boolean",
                required: true,
                defaultValue: false,
            },
            {
                name: "showViewMore",
                type: "boolean",
                required: true,
                defaultValue: false,
            },
        ],
    },
};

export default builderConfig;
