export const FiveStarsIcon: React.FC = () => {
    return (
        <svg
            width="112"
            height="16"
            viewBox="0 0 112 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0)">
                <path
                    d="M15.1446 5.43892L10.8286 4.81192L8.89758 0.900922C8.55958 0.217922 7.44158 0.217922 7.10358 0.900922L5.17358 4.81192L0.856585 5.43892C0.0395848 5.55792 -0.291415 6.56592 0.302585 7.14592L3.42658 10.1899L2.68958 14.4889C2.55058 15.3029 3.40658 15.9279 4.14058 15.5429L8.00058 13.5139L11.8616 15.5439C12.5896 15.9249 13.4526 15.3099 13.3126 14.4899L12.5756 10.1909L15.6996 7.14692C16.2926 6.56592 15.9616 5.55792 15.1446 5.43892Z"
                    fill="#F5643B"
                />
            </g>
            <g clipPath="url(#clip1)">
                <path
                    d="M39.1446 5.43892L34.8286 4.81192L32.8976 0.900922C32.5596 0.217922 31.4416 0.217922 31.1036 0.900922L29.1736 4.81192L24.8566 5.43892C24.0396 5.55792 23.7086 6.56592 24.3026 7.14592L27.4266 10.1899L26.6896 14.4889C26.5506 15.3029 27.4066 15.9279 28.1406 15.5429L32.0006 13.5139L35.8616 15.5439C36.5896 15.9249 37.4526 15.3099 37.3126 14.4899L36.5756 10.1909L39.6996 7.14692C40.2926 6.56592 39.9616 5.55792 39.1446 5.43892Z"
                    fill="#F5643B"
                />
            </g>
            <g clipPath="url(#clip2)">
                <path
                    d="M63.1446 5.43892L58.8286 4.81192L56.8976 0.900922C56.5596 0.217922 55.4416 0.217922 55.1036 0.900922L53.1736 4.81192L48.8566 5.43892C48.0396 5.55792 47.7086 6.56592 48.3026 7.14592L51.4266 10.1899L50.6896 14.4889C50.5506 15.3029 51.4066 15.9279 52.1406 15.5429L56.0006 13.5139L59.8616 15.5439C60.5896 15.9249 61.4526 15.3099 61.3126 14.4899L60.5756 10.1909L63.6996 7.14692C64.2926 6.56592 63.9616 5.55792 63.1446 5.43892Z"
                    fill="#F5643B"
                />
            </g>
            <g clipPath="url(#clip3)">
                <path
                    d="M87.1446 5.43892L82.8286 4.81192L80.8976 0.900922C80.5596 0.217922 79.4416 0.217922 79.1036 0.900922L77.1736 4.81192L72.8566 5.43892C72.0396 5.55792 71.7086 6.56592 72.3026 7.14592L75.4266 10.1899L74.6896 14.4889C74.5506 15.3029 75.4066 15.9279 76.1406 15.5429L80.0006 13.5139L83.8616 15.5439C84.5896 15.9249 85.4526 15.3099 85.3126 14.4899L84.5756 10.1909L87.6996 7.14692C88.2926 6.56592 87.9616 5.55792 87.1446 5.43892Z"
                    fill="#F5643B"
                />
            </g>
            <g clipPath="url(#clip4)">
                <path
                    d="M111.145 5.43892L106.829 4.81192L104.898 0.900922C104.56 0.217922 103.442 0.217922 103.104 0.900922L101.174 4.81192L96.8566 5.43892C96.0396 5.55792 95.7086 6.56592 96.3026 7.14592L99.4266 10.1899L98.6896 14.4889C98.5506 15.3029 99.4066 15.9279 100.141 15.5429L104.001 13.5139L107.862 15.5439C108.59 15.9249 109.453 15.3099 109.313 14.4899L108.576 10.1909L111.7 7.14692C112.293 6.56592 111.962 5.55792 111.145 5.43892Z"
                    fill="#F5643B"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
                <clipPath id="clip1">
                    <rect width="16" height="16" fill="white" transform="translate(24)" />
                </clipPath>
                <clipPath id="clip2">
                    <rect width="16" height="16" fill="white" transform="translate(48)" />
                </clipPath>
                <clipPath id="clip3">
                    <rect width="16" height="16" fill="white" transform="translate(72)" />
                </clipPath>
                <clipPath id="clip4">
                    <rect width="16" height="16" fill="white" transform="translate(96)" />
                </clipPath>
            </defs>
        </svg>
    );
};
