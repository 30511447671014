import React from "react";

import { COLOR } from "../component.constants";

export interface Props {
    color: COLOR;
    width?: number;
    height?: number;
    viewBoxWidth?: number;
    viewBoxHeight?: number;
}

const ShopBagIcon: React.FC<Props> = ({ color, width, height, viewBoxWidth, viewBoxHeight }) => {
    return (
        <svg
            width={width ?? 32}
            height={height ?? 32}
            fill="none"
            viewBox={`0 0 ${viewBoxWidth ?? 24} ${viewBoxHeight ?? 24}`}
        >
            <path
                fill={color}
                d="M23 22.7L21 7.9C20.9627 7.65767 20.8427 7.43569 20.6605 7.27167C20.4782 7.10765 20.2449 7.01167 20 7H17V5C17 3.67392 16.4732 2.40215 15.5355 1.46447C14.5979 0.526784 13.3261 0 12 0C10.6739 0 9.40215 0.526784 8.46447 1.46447C7.52678 2.40215 7 3.67392 7 5V7H4C3.75509 7.01167 3.52175 7.10765 3.33951 7.27167C3.15726 7.43569 3.03732 7.65767 3 7.9L1 22.9C1.00677 23.1782 1.07508 23.4514 1.2 23.7C1.41741 23.9004 1.70444 24.008 2 24H22C22.1334 24.008 22.267 23.9876 22.392 23.9402C22.5169 23.8928 22.6304 23.8194 22.7249 23.7249C22.8194 23.6304 22.8928 23.5169 22.9402 23.392C22.9876 23.267 23.008 23.1334 23 23V22.7ZM9 5C9 4.20435 9.31607 3.44129 9.87868 2.87868C10.4413 2.31607 11.2044 2 12 2C12.7956 2 13.5587 2.31607 14.1213 2.87868C14.6839 3.44129 15 4.20435 15 5V7H9V5Z"
            />
        </svg>
    );
};

export default ShopBagIcon;
