import classNames from "classnames/bind";
import React from "react";

import { ACCENT_COLORS as BG_COLORS } from "../component.constants";
import Grid from "../layout/grid";
import { KINDS } from "./cta-cluster.constants";
import styles from "./ctaCluster.module.scss";

const cx = classNames.bind(styles);

export interface Props {
    bgColor: string;
    children: Array<React.ReactNode>;
    clusterASubheader: string;
    clusterATextBody: string;
    clusterBSubheader: string;
    clusterBTextBody: string;
    ctaContent: string;
    kind: string;
}

export const CtaCluster: React.FC<Props> = (props: Props) => {
    const {
        bgColor,
        children,
        clusterASubheader,
        clusterATextBody,
        clusterBSubheader,
        clusterBTextBody,
        ctaContent,
        kind,
    } = props;

    const ctaClusterContainerClassName = cx({
        [styles.ctaClusterContainer]: true,
        [styles.ctaClusterContainerFull]: kind === KINDS.FULL,
        [styles.ctaClusterContainerIndented]: kind === KINDS.INDENTED,
        [styles.bgNone]: bgColor === BG_COLORS.NONE,
        [styles.bgLemonDark]: bgColor === BG_COLORS.LEMON_DARK,
        [styles.bgLemonPastel]: bgColor === BG_COLORS.LEMON_PASTEL,
        [styles.bgOrcaDark]: bgColor === BG_COLORS.ORCA_DARK,
        [styles.bgOrcaPastel]: bgColor === BG_COLORS.ORCA_PASTEL,
    });

    const renderClusters = () => {
        return (
            <div className={styles.clusterContainer}>
                <div className={styles.clusterContainerChild}>
                    <h2 className={styles.clusterSubheader}>{clusterASubheader}</h2>
                    <p className={styles.clusterTextBody}>{clusterATextBody}</p>
                    {children[0]}
                </div>

                <div className={styles.clusterContainerChild}>
                    <h2 className={styles.clusterSubheader}>{clusterBSubheader}</h2>
                    <p className={styles.clusterTextBody}>{clusterBTextBody}</p>
                    {children[1]}
                </div>
            </div>
        );
    };

    return (
        <div className={ctaClusterContainerClassName}>
            <Grid fullWidth={true}>
                {renderClusters()}
                <div className={styles.ctaContainer}>
                    <p className={styles.content}>{ctaContent}</p>
                    {children[2]}
                </div>
            </Grid>
        </div>
    );
};
