import classNames from "classnames/bind";
import { useRouter } from "next/router";
import React, { ChangeEvent, KeyboardEvent } from "react";

import { UI_CONTEXTS } from "@/analytics/constants";
import { trackUserEvent } from "@/analytics/tracking";

import { useLanguageState } from "@/context/LanguageContext";

import EVENTS from "../../../analytics/events";
import { SIZE, TYPE, TYPES } from "../input.constants";
import styles from "./searchInput.module.scss";

const cx = classNames.bind(styles);

export interface Props {
    disabled?: boolean;
    placeholder: string;
    httpCorrelationId: string;
    searchVersion: string;
    size?: SIZE;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
    type: TYPE;
}

export const SearchInput: React.FC<Props> = ({
    disabled,
    placeholder,
    size,
    onKeyDown,
    onChange,
    type,
    httpCorrelationId,
    searchVersion,
}) => {
    const { localization } = useLanguageState();
    const router = useRouter();

    const inputWrapperClassName = cx({
        [styles.inputWrapper]: true,
        [styles[`inputWrapper${size}`]]: true,
    });

    const inputTextClassName = cx({
        [styles.input]: true,
        [styles.inputNumber]: type == TYPES.NUMBER,
        [styles.disabled]: disabled,
        [styles[size]]: true,
    });

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            trackUserEvent(
                localization.locale,
                router.asPath,
                UI_CONTEXTS.SEARCH,
                EVENTS.SHOP_DIRECTORY_SEARCH_BAR_CLICK,
                {
                    searchVersion,
                    searchTerm: e.currentTarget.value,
                    resultsCount: 0,
                    httpCorrelationId,
                }
            );
        }
        return onKeyDown(e);
    };

    return (
        <div className={inputWrapperClassName}>
            <input
                className={inputTextClassName}
                disabled={disabled}
                onChange={onChange}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                type={type}
                data-testid="search-input"
            />
        </div>
    );
};
