export const KINDS = {
    DROPDOWN: "dropdown",
    SINGLE: "single",
} as const;

export const MODIFIERS = {
    LABEL: "label",
    ICON_PLUS_LABEL: "iconPlusLabel",
    IN_STORE: "inStoreLabel",
} as const;

export type KIND = typeof KINDS[keyof typeof KINDS];
export type MODIFIER = typeof MODIFIERS[keyof typeof MODIFIERS];
