import { BuilderConfig } from "../../../builder/types";
import { ACCENT_COLORS, SIZES } from "../../component.constants";
import { QuoteHighlight, Props } from "./quote-highlight";

export const QuoteHighlightBuilderConfig = {
    name: "Quote Highlight",
    inputs: [
        {
            name: "author",
            type: "string",
            defaultValue: "Author",
            required: true,
        },
        {
            name: "authorInfo",
            type: "string",
            defaultValue: "Author Info",
            required: true,
        },
        {
            name: "backgroundColor",
            type: "string",
            enum: Object.values(ACCENT_COLORS),
            defaultValue: ACCENT_COLORS.ORCA_PASTEL,
        },
        {
            name: "size",
            type: "string",
            enum: Object.values(SIZES),
            defaultValue: SIZES.SMALL,
        },
        {
            name: "text",
            type: "string",
            defaultValue: "Quote content",
            required: true,
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: QuoteHighlight,
    config: QuoteHighlightBuilderConfig,
};

export default builderConfig;
