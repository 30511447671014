export const KINDS = {
    HAIRLINE: "Hairline",
    DASHED: "Dashed",
    DOTTED: "Dotted",
    SOLID: "Solid",
} as const;

export const SPACINGS = {
    SMALL: "Small",
    LARGE: "Large",
} as const;

export type KIND = typeof KINDS[keyof typeof KINDS];
export type SPACING = typeof SPACINGS[keyof typeof SPACINGS];
