import classNames from "classnames/bind";
import React from "react";

import { ACCENT_COLOR } from "../component.constants";
import styles from "./ctaDivider.module.scss";

const cx = classNames.bind(styles);

export interface Props {
    text: string;
    backgroundColor: ACCENT_COLOR;
    children: React.ReactNode;
}

export const CtaDivider: React.FC<Props> = (props: Props) => {
    const { children, text, backgroundColor } = props;

    const ctaDividerContainerClassName = cx({
        [styles.dividerContainer]: true,
        [styles[`background${backgroundColor}`]]: true,
    });

    return (
        <div className={ctaDividerContainerClassName}>
            <div className={styles.ctaDividerContent} dangerouslySetInnerHTML={{ __html: text }} />
            {children && <div className={styles.childrenContainer}>{children}</div>}
        </div>
    );
};
