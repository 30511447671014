import { AsyncPropsMapper, BuilderConfig } from "../../../builder/types";
import { APICategoryProps } from "../../../interfaces/category-interface";
import { SHOP_DIRECTORY_API_HOST } from "../../../shared/constants";
import DoubleHeadline, { Props } from "./double-headline";

const mapper: AsyncPropsMapper<{ data: Array<APICategoryProps> }> = ({ data }) => {
    return {
        categorySlug: data.data.map((category: APICategoryProps) => ({
            name: category.attributes.name,
            value: category.attributes.slug,
        })),
    };
};

export const DoubleHeadlineConfig = {
    name: "Double Headline",
    inputs: [
        {
            friendlyName: "Show on Index page",
            name: "showOnIndex",
            type: "boolean",
            defaultValue: false,
            required: true,
        },
        {
            friendlyName: "Show on Category page",
            name: "categorySlug",
            type: "dynamic-dropdown",
            required: false,
            options: {
                url: `${SHOP_DIRECTORY_API_HOST}/api/v1/categories?locale={{targeting.locale.0}}`,
                mapper: mapper.toString(),
            },
        },
        {
            name: "backgroundImage",
            type: "file",
            allowedFileTypes: ["jpg", "jpeg", "png"],
            required: true,
        },
        {
            name: "primaryHeading",
            type: "string",
            required: true,
        },
        {
            name: "secondaryHeading",
            type: "string",
            required: true,
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: DoubleHeadline,
    config: DoubleHeadlineConfig,
};

export default builderConfig;
