import React from "react";

import { UI_CONTEXT, UI_CONTEXTS } from "@/analytics/constants";

import CardCarousel from "@/components/carousel/carousel";
import CardCarouselHeader from "@/components/carousel/carousel-header";
import { Offer } from "@/components/modals/offer";
import useOfferCallbacks from "@/components/modals/offerCallbacks";
import { StoreTile } from "@/components/store-tiles/store-tile";

import { useCategory, useCategoryStores } from "@/hooks/useCategory";

import { CategoryProps } from "@/interfaces/category-interface";
import { StoreProps } from "@/interfaces/stores-interface";

export type Props = {
    categorySlug: string;
    initialCategory?: CategoryProps;
    initialCategoryStores?: Array<StoreProps>;
    title?: string;
    ctaNewTab: boolean;
    uiContext: UI_CONTEXT;
};

const StoresCarousel: React.FC<Props> = ({
    title,
    ctaNewTab,
    uiContext,
    categorySlug,
    initialCategory,
    initialCategoryStores,
}) => {
    const category = useCategory(categorySlug, {
        initialState: initialCategory,
    });

    const stores = useCategoryStores(categorySlug, {
        initialState: initialCategoryStores,
    });

    const {
        isOfferModalsVisible,
        isOfferModalsFading,
        setOfferModalsVisible,
        setOfferModalsFading,
        offerButtonClickHandler,
    } = useOfferCallbacks(stores);

    if (!category || !stores || stores.length === 0) {
        return null;
    }

    const offerModals = stores.map((store) => {
        return (
            isOfferModalsVisible[store.id] && (
                <Offer
                    store={store}
                    active={isOfferModalsFading[store.id]}
                    setVisibleCallback={setOfferModalsVisible}
                    setFadingCallback={setOfferModalsFading}
                    uiContext={uiContext}
                />
            )
        );
    });

    return (
        <React.Fragment>
            <CardCarouselHeader
                title={title || category.name}
                eventProps={{
                    categoryId: category.id,
                    categoryName: category.name,
                }}
                ctaUrl={category.rootPageUrl}
                ctaNewTab={ctaNewTab}
                uiContext={uiContext || UI_CONTEXTS.HOMEPAGE_TILE}
            />
            <CardCarousel carouselType={"default"}>
                {stores.map((store, position) => (
                    <StoreTile
                        category={category}
                        key={store.id}
                        store={store}
                        analytics={{
                            uiContext: uiContext || UI_CONTEXTS.HOMEPAGE_TILE,
                            eventProps: {
                                categoryId: category.id,
                                categoryName: category.name,
                            },
                        }}
                        position={position}
                        offerButtonClickHandler={offerButtonClickHandler}
                    />
                ))}
            </CardCarousel>
            {offerModals}
        </React.Fragment>
    );
};

const StoresCarouselGuard: React.FC<Props> = (props) => {
    if (!props.categorySlug) {
        return null;
    }
    return <StoresCarousel {...props} />;
};

export default StoresCarouselGuard;
