import { useEffect, useState } from "react";

import {
    Breakpoint,
    MOBILE_BREAKPOINT,
    TABLET_BREAKPOINT,
    DESKTOP_BREAKPOINT,
} from "@/shared/breakpoint.constants";

import useWindowDimensions from "./useWindowDimensions";

const isInBreakpoint = (width: number, breakpoint: Breakpoint): boolean => {
    return width >= breakpoint.min && width <= breakpoint.max;
};

type ViewportData = {
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
    hasWindow: boolean;
};
const useViewport = (): ViewportData => {
    const empty = {
        isMobile: false,
        isTablet: false,
        isDesktop: false,
        hasWindow: false,
    };
    const { hasWindow, width } = useWindowDimensions();
    const [viewportData, setViewportData] = useState<ViewportData>(empty);

    useEffect(() => {
        const data = hasWindow
            ? {
                  isMobile: isInBreakpoint(width, MOBILE_BREAKPOINT),
                  isTablet: isInBreakpoint(width, TABLET_BREAKPOINT),
                  isDesktop: isInBreakpoint(width, DESKTOP_BREAKPOINT),
                  hasWindow: true,
              }
            : empty;
        setViewportData(data);
    }, [hasWindow, width, MOBILE_BREAKPOINT, TABLET_BREAKPOINT, DESKTOP_BREAKPOINT]);

    return viewportData;
};

export default useViewport;
