import classNames from "classnames/bind";
import React from "react";

import LinkButton, { Props as LinkButtonProps } from "../../buttons/link-button";
import styles from "./imageTextCluster.module.scss";

export interface Props {
    largeImage: string;
    smallImageTop: string;
    smallImageBottom: string;
    largeImageAltText: string;
    smallImageTopAltText: string;
    smallImageBottomAltText: string;
    accentColor?: string;
    alignment?: string;
    bgColor?: string;
    bodyText?: string;
    header?: string;
    ctaProps?: LinkButtonProps;
}

const cx = classNames.bind(styles);

const renderImageCluster: React.FC<Props> = (props: Props) => {
    const {
        largeImage,
        largeImageAltText,
        smallImageBottom,
        smallImageBottomAltText,
        smallImageTop,
        smallImageTopAltText,
    } = props;

    return (
        <>
            <div className={styles.clusterContainerSmall}>
                <div className={styles.clusterContainerSmallTop}>
                    <div className={styles.clusterImagePlaceholder} />
                    <img
                        data-testid="cluster-image"
                        alt={smallImageTopAltText}
                        className={styles.clusterImage}
                        src={smallImageTop}
                    />
                </div>
                <div className={styles.clusterContainerSmallBottom}>
                    <div className={styles.clusterImagePlaceholder} />
                    <img
                        data-testid="cluster-image"
                        alt={smallImageBottomAltText}
                        className={styles.clusterImage}
                        src={smallImageBottom}
                    />
                </div>
            </div>
            <div className={styles.clusterContainerLarge}>
                <div className={styles.clusterImagePlaceholder} />
                <img
                    data-testid="cluster-image"
                    alt={largeImageAltText}
                    className={styles.clusterImage}
                    src={largeImage}
                />
            </div>
        </>
    );
};

const renderText: React.FC<Props> = (props: Props) => {
    const { bodyText, header } = props;

    return (
        <>
            <h2 className={styles.textContainerHeader}>{header}</h2>
            <p
                className={styles.textContainerBodyText}
                dangerouslySetInnerHTML={{ __html: bodyText }}
            />
        </>
    );
};

export const ImageTextCluster: React.FC<Props> = (props: Props) => {
    const { alignment, ctaProps } = props;

    const imageTextContainerClassName = cx({
        [styles.imageTextContainer]: true,
        [styles[alignment]]: true,
    });

    return (
        <div data-testid="image-text-cluster-container" className={imageTextContainerClassName}>
            <div className={styles.clusterContainer}>{renderImageCluster(props)}</div>
            <div data-testid="text-container" className={styles.textContainer}>
                {renderText(props)}

                <div className={styles.buttonContainer}>
                    <LinkButton {...ctaProps} />
                </div>
            </div>
        </div>
    );
};
