import { BuilderConfig } from "@/builder/types";

import Countdown, { Props } from "./countdown";

const generateDefaultDate = () => {
    const dateTime = new Date();
    dateTime.setHours(dateTime.getHours() + 12);
    return dateTime.getTime();
};

export const countdownBuilderConfig = {
    name: "Countdown Banner",
    inputs: [
        {
            name: "endDate",
            type: "date",
            required: true,
            defaultValue: generateDefaultDate(),
        },
        {
            name: "heading",
            type: "richText",
            required: true,
            defaultValue: "The countdown is on!",
        },
        {
            name: "body",
            type: "string",
            required: true,
            defaultValue: "Shop now and pay later with the latest and greatest stores.",
        },
        {
            name: "newTab",
            type: "boolean",
            defaultValue: false,
            required: true,
        },
        {
            name: "url",
            type: "string",
            defaultValue: "/",
            required: false,
        },
        {
            name: "ctaLabel",
            type: "string",
            defaultValue: "Shop Now",
            required: false,
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: Countdown,
    config: countdownBuilderConfig,
};

export default builderConfig;
