import React from "react";

import { COLOR } from "../component.constants";

export interface Props {
    color: COLOR;
    width?: number;
    height?: number;
    viewBoxWidth?: number;
    viewBoxHeight?: number;
}

const ShopBagIcon: React.FC<Props> = ({ color, width, height, viewBoxWidth, viewBoxHeight }) => {
    return (
        <svg
            width={width ?? 32}
            height={height ?? 32}
            fill="none"
            viewBox={`0 0 ${viewBoxWidth ?? 24} ${viewBoxHeight ?? 24}`}
        >
            <path
                fill={color}
                d="M12 0C5.383 0 0 5.383 0 12C0 18.617 5.383 24 12 24C18.617 24 24 18.617 24 12C24 5.383 18.617 0 12 0ZM12.618 14L8.895 6.553L7.105 7.447L10.382 14H2.202C2.07 13.354 2 12.685 2 12C2 6.486 6.486 2 12 2C17.514 2 22 6.486 22 12C22 12.685 21.93 13.354 21.798 14H12.618Z"
            />
        </svg>
    );
};

export default ShopBagIcon;
