export type Dict<T> = {
    [key: string]: T;
};

export const getHostname = (url: string): string | null => {
    try {
        const { hostname } = new URL(url);
        return hostname;
    } catch (e) {
        return null;
    }
};

export const getSearchParams = (url: string): Dict<string> | null => {
    try {
        const { searchParams } = new URL(url);
        const params = Object.fromEntries(searchParams);
        return params;
    } catch (e) {
        return null;
    }
};
