import classNames from "classnames/bind";
import { FormikErrors, FormikTouched as FormikTouchedType } from "formik";
import React, { ChangeEventHandler, useState } from "react";

import { SIZE, SIZES, TYPE, TYPES } from "../input.constants";
import styles from "./input.module.scss";

const cx = classNames.bind(styles);

type FormikError = string | string[] | FormikErrors<unknown> | FormikErrors<unknown>[];
type FormikTouched = boolean | FormikTouchedType<unknown> | FormikTouchedType<unknown>[];

export interface TextInputProps {
    type: TYPE;
    fullWidth?: boolean;
    label?: string;
    size?: SIZE;
    touched?: FormikTouched;
    errors?: FormikError;
}

type FormikInout = Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    "size"
>;

export type Props = TextInputProps & FormikInout;

const ValidationError: React.FC<{ errors: FormikError }> = ({ errors }) => {
    const normalisedErrors = Array.isArray(errors) ? errors : [errors];
    return (
        <>
            {normalisedErrors.map((err, idx) => (
                <div className={styles.errorText} key={`${idx}-${err}`}>
                    <p>{err}</p>
                </div>
            ))}
        </>
    );
};

export const TextInput: React.FC<Props> = ({
    fullWidth,
    label,
    size,
    disabled,
    onChange,
    errors,
    touched,
    value,
    type,
    ...rest
}) => {
    const showError = errors && touched;

    const [isFilled, setIsFilled] = useState<boolean>(!!value);

    const inputWrapperClassName = cx({
        [styles.inputWrapper]: true,
        [styles.fullWidth]: fullWidth,
    });

    const labelWrapperClassName = cx({
        [styles.labelWrapper]: true,
        [styles.disabled]: disabled && disabled === true,
        [styles.labelWrapperError]: showError,
    });

    const labelClassName = cx({
        [styles.label]: true,
        [styles.disabled]: disabled && disabled === true,
        [styles.filled]: isFilled && isFilled === true,
        [styles[size]]: size === SIZES.SMALL,
        [styles.labelError]: showError,
    });

    const inputTextClassName = cx({
        [styles.inputText]: true,
        [styles.inputNumber]: type === TYPES.NUMBER,
        [styles.disabled]: disabled && disabled === true,
        [styles.filled]: isFilled && isFilled === true,
        [styles[size]]: size === SIZES.SMALL,
    });

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        onChange && onChange(e);
        setIsFilled(!!e.target?.value.length);
    };

    return (
        <div className={inputWrapperClassName}>
            <label className={labelWrapperClassName}>
                <span className={labelClassName} data-testid="floating-label">
                    {label}
                </span>
                <input
                    data-testid="text-input"
                    className={inputTextClassName}
                    disabled={disabled}
                    onChange={handleChange}
                    value={value}
                    type={type}
                    {...rest}
                />
            </label>
            {showError && <ValidationError errors={errors} />}
        </div>
    );
};
