import { useEffect, useState } from "react";
import useSWR from "swr";

import { axios } from "@/utils/axios";
import logger from "@/utils/logger";

import {
    transformCategories,
    transformCategory,
    transformProducts,
} from "@/pages/api/store-directory/categories";

import { useCurrentLocale } from "@/context/LanguageContext";

import { APICategoryProps, CategoryProps } from "@/interfaces/category-interface";
import { APIProductProps, ProductProps } from "@/interfaces/products-interface";
import { APIStoreProps, StoreProps, transformStores } from "@/interfaces/stores-interface";

import { Config } from "@/types/hooks";

const SHOP_DIRECTORY_API_HOST = process.env.NEXT_PUBLIC_SHOP_DIRECTORY_API_HOST;
const BASE_URL = `${SHOP_DIRECTORY_API_HOST}/api/v1/categories`;

async function fetcher<T>(url: string, options?: Record<string, unknown>): Promise<T> {
    const res = await axios(url, options);
    return res.data.data;
}

export function useFetch<T, ApiT>(
    url: string,
    config: Config<T>,
    transformFunc: (res: ApiT) => T
): T {
    const [transformedData, setTransformedData] = useState<T>(config.initialState);

    const { data, error } = useSWR<ApiT>(url, fetcher, {
        shouldRetryOnError: false,
        ...config.swrConfig,
    });

    logger.logException(error, `Unable to fetch category`);

    useEffect(() => {
        try {
            if (!data) return;
            setTransformedData(transformFunc(data));
        } catch (err) {
            logger.logException(err, `Unable to transform category`);
        }
    }, [data]);

    return transformedData;
}

export function useCategory(slug: string, config: Config<CategoryProps> = {}): CategoryProps {
    const locale = useCurrentLocale();
    const url = `${BASE_URL}/${slug}?locale=${locale}`;

    return useFetch<CategoryProps, APICategoryProps>(url, config, transformCategory);
}

export function useCategories(): Array<CategoryProps> {
    const locale = useCurrentLocale();
    const url = `${BASE_URL}?locale=${locale}`;
    const config = { initialState: [] };

    return useFetch<Array<CategoryProps>, Array<APICategoryProps>>(
        url,
        config,
        transformCategories
    );
}

export function useCategoryStores(
    categorySlug: string,
    config: Config<Array<StoreProps>> = {}
): Array<StoreProps> {
    const locale = useCurrentLocale();

    const qs = config.queryString ? `&${config.queryString}` : "";
    const url = `${BASE_URL}/${categorySlug}/stores?locale=${locale}${qs}`;

    return useFetch<Array<StoreProps>, Array<APIStoreProps>>(url, config, transformStores);
}

export function useCategoryProducts(
    category: string,
    config: Config<Array<ProductProps>> = {}
): Array<ProductProps> {
    const locale = useCurrentLocale();
    const url = `${BASE_URL}/${category}/products?locale=${locale}`;
    config.initialState = config.initialState || [];

    return useFetch<Array<ProductProps>, Array<APIProductProps>>(url, config, transformProducts);
}
