export const SIZES = {
    REGULAR: "Regular",
    SMALL: "Small",
} as const;

export const TYPES = {
    TEXT: "text",
    NUMBER: "number",
    EMAIL: "email",
} as const;

export type SIZE = typeof SIZES[keyof typeof SIZES];
export type TYPE = typeof TYPES[keyof typeof TYPES];
