import { withChildren } from "@builder.io/react";

import { BuilderConfig } from "../../builder/types";
import { KINDS, SIZES } from "../buttons/buttons.constants";
import { AppHighlight, Props } from "./app-highlight";

const AppHighlightWithChildren = withChildren(AppHighlight);

export const AppHighlightBuilderConfig = {
    name: "App Highlight",
    inputs: [
        {
            name: "body",
            type: "string",
            defaultValue:
                "The latest offers from your favorite brands & be the first to know about new drops right at your fingertips.",
        },
        {
            name: "header",
            type: "string",
            defaultValue: "Get the App",
        },
        {
            name: "frontImage",
            type: "file",
            required: true,
            allowedFileTypes: ["jpg", "jpeg", "png"],
        },
        {
            name: "frontImageAltText",
            type: "string",
            required: true,
            defaultValue: "Image A",
        },
        {
            name: "backImage",
            type: "file",
            required: true,
            allowedFileTypes: ["jpg", "jpeg", "png"],
        },
        {
            name: "backImageAltText",
            type: "string",
            required: true,
            defaultValue: "Image B",
        },
    ],
    childRequirements: {
        message: "You can only put LinkButtons",
        query: {
            "component.name": { $in: ["LinkButton"] },
        },
    },
    defaultChildren: [
        {
            "@type": "@builder.io/sdk:Element" as const,
            component: {
                name: "LinkButton",
                options: {
                    label: "Hello",
                    kind: KINDS.PRIMARY,
                    size: SIZES.REGULAR,
                    fluid: true,
                    disabled: false,
                    url: "/",
                    newTab: false,
                },
            },
        },
        {
            "@type": "@builder.io/sdk:Element" as const,
            component: {
                name: "LinkButton",
                options: {
                    label: "Hello",
                    kind: KINDS.SECONDARY,
                    size: SIZES.REGULAR,
                    fluid: true,
                    disabled: false,
                    url: "/",
                    newTab: false,
                },
            },
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: AppHighlightWithChildren,
    config: AppHighlightBuilderConfig,
};

export default builderConfig;
