import { BuilderConfig } from "../../builder/types";
import { KINDS, SIZES } from "./buttons.constants";
import LinkButton, { Props } from "./link-button";

export const LinkButtonBuilderConfig = {
    name: "LinkButton",
    inputs: [
        {
            name: "label",
            type: "string",
            defaultValue: "Login",
        },
        {
            name: "url",
            type: "string",
            defaultValue: "/",
        },
        {
            name: "newTab",
            type: "boolean",
            defaultValue: false,
        },
        {
            name: "kind",
            type: "string",
            enum: Object.values(KINDS),
            defaultValue: KINDS.PRIMARY,
        },
        {
            name: "size",
            type: "string",
            enum: Object.values(SIZES),
            defaultValue: SIZES.REGULAR,
        },
        {
            name: "fluid",
            type: "boolean",
            defaultValue: false,
        },
        {
            name: "disabled",
            type: "boolean",
            defaultValue: false,
        },
        {
            name: "show",
            type: "boolean",
            defaultValue: true,
        },
        {
            name: "forwardUtm",
            type: "boolean",
            defaultValue: false,
            required: false,
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: LinkButton,
    config: LinkButtonBuilderConfig,
};

export default builderConfig;
