import React from "react";

import { COLOR } from "../component.constants";

export interface Props {
    color: COLOR;
    width?: number;
    height?: number;
    viewBoxWidth?: number;
    viewBoxHeight?: number;
}

const ThumbsupIcon: React.FC<Props> = ({ color, width, height, viewBoxWidth, viewBoxHeight }) => {
    return (
        <svg
            width={width ?? 32}
            height={height ?? 32}
            fill="none"
            viewBox={`0 0 ${viewBoxWidth ?? 24} ${viewBoxHeight ?? 24}`}
        >
            <path
                d="M4 11H1C0.734784 11 0.48043 11.1054 0.292893 11.2929C0.105357 11.4804 0 11.7348 0 12L0 23C0 23.2652 0.105357 23.5196 0.292893 23.7071C0.48043 23.8946 0.734784 24 1 24H4V11Z"
                fill={color}
            />
            <path
                d="M22.539 10.4C22.1636 9.96107 21.6975 9.60865 21.1729 9.36697C20.6483 9.1253 20.0776 9.00011 19.5 9H13V4C13 1.794 12.206 2.08553e-08 10 2.08553e-08C9.78057 -4.47777e-05 9.56722 0.0720842 9.39283 0.205268C9.21845 0.338452 9.09271 0.525297 9.035 0.737L6 11V24H18.426C19.3828 24.0045 20.3091 23.664 21.0353 23.041C21.7615 22.4181 22.2389 21.5543 22.38 20.608L23.456 13.608C23.5431 13.0388 23.5062 12.4575 23.3479 11.9038C23.1897 11.3502 22.9137 10.8372 22.539 10.4Z"
                fill={color}
            />
        </svg>
    );
};

export default ThumbsupIcon;
