module.exports = function ({ addUtilities }) {
    const newUtilities = {
        ".vertical-center": {
            top: "50%",
            transform: "translateY(-50%)",
        },
        ".horizontal-center": {
            left: "50%",
            transform: "translateX(-50%)",
        },
    };
    addUtilities(newUtilities, {
        variants: ["responsive"],
    });
};
