import { useRouter } from "next/router";
import React, { KeyboardEventHandler } from "react";

import { truncateWithEllipsis } from "@/utils/strings";

import type { UI_CONTEXT } from "@/analytics/constants";
import EVENTS from "@/analytics/events";
import { trackUserEvent } from "@/analytics/tracking";

import { useLanguageState } from "@/context/LanguageContext";

import { CategoryProps } from "@/interfaces/category-interface";
import { CollectionProps } from "@/interfaces/collection-interface";
import { CollectionV2Props } from "@/interfaces/collection-v2-interface";
import { ProductProps } from "@/interfaces/products-interface";

import { TileProps } from "@/types/tile";

import styles from "./sideProductTiles.module.scss";

export type ProductTileProps = {
    product: ProductProps;
    category: CategoryProps | CollectionProps | CollectionV2Props;
    parentCategory?: CategoryProps | CollectionProps;
    uiContext: UI_CONTEXT;
} & TileProps;

export const ProductTile: React.FC<ProductTileProps> = ({
    product,
    position,
    category,
    uiContext,
    tabIndex = 0,
}) => {
    const MAX_PRODUCT_NAME_TEXT_LENGTH = 50;

    const router = useRouter();

    const { localization } = useLanguageState();

    const {
        linkToMerchant,
        merchantName,
        merchantId,
        mainImageUrl,
        name,
        originalPriceFormatted,
        priceFormatted,
    } = product;

    const eventProps = {
        position,
        merchantId: +merchantId,
        productName: name,
        productPrice: priceFormatted,
        merchantName: merchantName,
        categoryName: category.name,
        collectionName: category.name,
    };

    const goToShopUrl = () => {
        trackUserEvent(
            localization.locale,
            router.asPath,
            uiContext,
            EVENTS.SHOP_DIRECTORY_PRODUCT_CLICK,
            eventProps
        );

        window.open(linkToMerchant, "_blank");
    };

    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
        if (e.key === "Enter" || e.key === "Space") {
            goToShopUrl();
        }
    };

    return (
        <div
            tabIndex={tabIndex}
            role={"button"}
            onClick={goToShopUrl}
            onKeyDown={handleKeyDown}
            className={styles.productTileContainer}
        >
            <img role={"button"} className={styles.smallImage} src={mainImageUrl} />
            <div className={styles.productTileBottomContainer}>
                <div className={styles.productTileBottomWrapper}>
                    <p className={styles.productMerchantName}>{merchantName}</p>
                    <p className={styles.productTileName}>
                        {truncateWithEllipsis(name, MAX_PRODUCT_NAME_TEXT_LENGTH)}
                    </p>
                    <p
                        className={
                            originalPriceFormatted
                                ? styles.productPriceDiscounted
                                : styles.productPrice
                        }
                    >
                        {priceFormatted}
                        {originalPriceFormatted ? (
                            <span className={styles.productOriginalPrice}>
                                {originalPriceFormatted}
                            </span>
                        ) : null}
                    </p>
                </div>
            </div>
        </div>
    );
};
