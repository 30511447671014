import { v4 } from "uuid";

import { BuilderConfig } from "../../../builder/types";
import { ACCENT_COLORS } from "../../component.constants";
import { MessageBanner, Props } from "../message-banner";

export const StatusBannerBuilderConfig = {
    name: "Status Banner",
    inputs: [
        {
            name: "backgroundColor",
            type: "string",
            enum: [ACCENT_COLORS.MINT, ACCENT_COLORS.FIRE, ACCENT_COLORS.BLACK],
            defaultValue: ACCENT_COLORS.MINT,
        },
        {
            name: "cookieName",
            type: "string",
            defaultValue: v4(),
            required: true,
            hideFromUI: true,
        },
        {
            name: "text",
            type: "richText",
            defaultValue:
                "<b>Covid 19</b> Please note delivery delays from retailers may occur during this time due to increased safety measures.",
            required: true,
        },
        {
            name: "messageLink",
            type: "url",
            required: false,
        },
        {
            name: "newTab",
            type: "boolean",
            defaultValue: true,
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: MessageBanner,
    config: StatusBannerBuilderConfig,
};

export default builderConfig;
