import React from "react";

import styles from "./double-headline.module.scss";

export interface Props {
    primaryHeading: string;
    secondaryHeading?: string;
    backgroundImage: string;
    logoImage?: string | null | undefined;
}

const DoubleHeadline: React.FC<Props> = ({
    primaryHeading,
    secondaryHeading,
    backgroundImage,
    logoImage,
}) => {
    return (
        <div className={styles.doubleHeadline}>
            <div className={styles.backgroundContainer}>
                <div
                    className={styles.background}
                    style={{ backgroundImage: `url("${backgroundImage}")` }}
                />
            </div>
            <div className={styles.overlay}>
                {logoImage && <img alt="logo" src={logoImage} className={styles.logo} />}
                <h2 className={styles.overlayHeading}>{primaryHeading}</h2>
                <p className={styles.overlayBody}>{secondaryHeading}</p>
            </div>
        </div>
    );
};

export default DoubleHeadline;
