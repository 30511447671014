module.exports = function ({ addBase, addUtilities, config }) {
    addBase({
        "h1, h2, h3, h4, h5, p, span, a, li, select, option": {
            fontFamily: config("theme.fontFamily.italianplate"),
            fontStyle: "normal",
        },
        "h1, h2, h3, h4, h5": {
            fontWeight: config("theme.fontWeight.bold"),
        },
        "p, span": {
            fontWeight: config("theme.fontWeight.normal"),
        },
    });
    const newUtilities = {
        ".heading8XL": {
            fontSize: config("theme.fontSize.8xl"),
            lineHeight: config("theme.lineHeight.22"),
        },
        ".heading7XL": {
            fontSize: config("theme.fontSize.7xl"),
            lineHeight: config("theme.lineHeight.20"),
        },
        ".heading6XL": {
            fontSize: config("theme.fontSize.6xl"),
            lineHeight: config("theme.lineHeight.18"),
        },
        ".heading5XL": {
            fontSize: config("theme.fontSize.5xl"),
            lineHeight: config("theme.lineHeight.16"),
        },
        ".heading4XL": {
            fontSize: config("theme.fontSize.4xl"),
            lineHeight: config("theme.lineHeight.14"),
        },
        ".heading3XL": {
            fontSize: config("theme.fontSize.3xl"),
            lineHeight: config("theme.lineHeight.13"),
        },
        ".heading2XL": {
            fontSize: config("theme.fontSize.2xl"),
            lineHeight: config("theme.lineHeight.10"),
        },
        ".headingXL": {
            fontSize: config("theme.fontSize.xl"),
            lineHeight: config("theme.lineHeight.8"),
        },
        ".headingL": {
            fontSize: config("theme.fontSize.lg"),
            lineHeight: config("theme.lineHeight.7"),
        },
        ".headingM": {
            fontSize: config("theme.fontSize.md"),
            lineHeight: config("theme.lineHeight.6"),
        },
        ".headingS": {
            fontSize: config("theme.fontSize.sm"),
            lineHeight: config("theme.lineHeight.5-5"),
        },
        ".headingXS": {
            fontSize: config("theme.fontSize.xs"),
            lineHeight: config("theme.lineHeight.5"),
        },
        ".headingXXS": {
            fontSize: config("theme.fontSize.xxs"),
            lineHeight: config("theme.lineHeight.4"),
        },
        ".content3XL": {
            fontSize: config("theme.fontSize.3xl"),
            lineHeight: config("theme.lineHeight.13"),
        },
        ".content2XL": {
            fontSize: config("theme.fontSize.2xl"),
            lineHeight: config("theme.lineHeight.10"),
        },
        ".contentXL": {
            fontSize: config("theme.fontSize.xl"),
            lineHeight: config("theme.lineHeight.8"),
        },
        ".contentL": {
            fontSize: config("theme.fontSize.lg"),
            lineHeight: config("theme.lineHeight.7"),
        },
        ".contentM": {
            fontSize: config("theme.fontSize.md"),
            lineHeight: config("theme.lineHeight.6"),
        },
        ".contentS": {
            fontSize: config("theme.fontSize.sm"),
            lineHeight: config("theme.lineHeight.5-5"),
        },
        ".contentXS": {
            fontSize: config("theme.fontSize.xs"),
            lineHeight: config("theme.lineHeight.5"),
        },
        ".contentXXS": {
            fontSize: config("theme.fontSize.xxs"),
            lineHeight: config("theme.lineHeight.4"),
        },
    };
    addUtilities(newUtilities, {
        variants: ["responsive"],
    });
};
