import classNames from "classnames/bind";
import React from "react";

import { SIZES } from "@/shared/constants";

import type { UI_CONTEXT } from "@/analytics/constants";
import EVENTS from "@/analytics/events";

import Heading from "@/components/heading/heading";

import { SIZE } from "@/types/components";

import { KINDS as BUTTON_KINDS } from "../../buttons/buttons.constants";
import LinkButton from "../../buttons/link-button";
import styles from "./cta-4x3.module.scss";

export interface Props {
    backgroundImage: string;
    heading: string;
    body?: string;
    headingSize: SIZE;
    ctaLabel: string;
    url: string;
    uiContext: UI_CONTEXT;
}
const Cta4x3: React.FC<Props> = ({
    heading,
    body,
    backgroundImage,
    headingSize,
    ctaLabel,
    url,
    uiContext,
}) => {
    const headingStyle = classNames({
        [styles.overlayHeadingSmall]: headingSize === SIZES.SMALL,
        [styles.overlayHeadingRegular]: headingSize === SIZES.REGULAR,
        [styles.overlayHeadingLarge]: headingSize === SIZES.LARGE,
    });

    return (
        <div className={styles.cta4x3}>
            <div className={styles.backgroundContainer}>
                <div
                    className={styles.background}
                    style={{ backgroundImage: `url("${backgroundImage}")` }}
                />
            </div>
            <div className={styles.overlay}>
                <Heading headingStyle={headingStyle} header={heading} />
                {body && <p className={styles.overlayBody}>{body}</p>}
                <div className={styles.buttonsContainer}>
                    <LinkButton
                        show
                        size={SIZES.REGULAR}
                        disabled={false}
                        newTab
                        fluid={false}
                        kind={BUTTON_KINDS.PRIMARY}
                        label={ctaLabel}
                        url={url}
                        analytics={{
                            eventName: EVENTS.BANNER_CLICK,
                            uiContext,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Cta4x3;
