import { BuilderConfig } from "../../builder/types";
import { PLACEHOLDER_LOGO_URL } from "./partner-logo.constants";
import { PartnerLogos, Props } from "./partner-logos";

export const PartnerLogoBuilderConfig = {
    name: "PartnerLogos",
    inputs: [
        {
            name: "header",
            type: "string",
            required: true,
        },
        {
            name: "logos",
            type: "list",
            subFields: [
                {
                    name: "partnerUrl",
                    type: "string",
                },
                {
                    name: "partnerLogoUrl",
                    type: "file",
                    required: true,
                    allowedFileTypes: ["jpeg", "png"],
                },
                {
                    name: "altText",
                    type: "string",
                    required: true,
                },
                {
                    name: "newTab",
                    type: "boolean",
                    required: false,
                },
            ],
            defaultValue: [
                {
                    partnerUrl: "https://www.afterpay.com",
                    partnerLogoUrl: PLACEHOLDER_LOGO_URL,
                    altText: "Partner Logo 1",
                    newTab: true,
                },
                {
                    partnerUrl: "https://www.afterpay.com",
                    partnerLogoUrl: PLACEHOLDER_LOGO_URL,
                    altText: "Partner Logo 2",
                },
                {
                    partnerUrl: "https://www.afterpay.com",
                    partnerLogoUrl: PLACEHOLDER_LOGO_URL,
                    altText: "Partner Logo 3",
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: PartnerLogos,
    config: PartnerLogoBuilderConfig,
};

export default builderConfig;
