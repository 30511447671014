import { BuilderConfig } from "../../../builder/types";
import { ACCENT_COLORS, SIZES } from "../../component.constants";
import { TextHighlight, Props } from "./text-highlight";

export const TextHighlightBuilderConfig = {
    name: "Text Highlight",
    inputs: [
        {
            name: "size",
            type: "string",
            enum: Object.values(SIZES),
            defaultValue: SIZES.SMALL,
        },
        {
            name: "highlights",
            type: "list",
            required: true,
            onChange: (options: Map<string, Props["highlights"]>): void => {
                if (options.get("highlights").length > 2) {
                    options.set("highlights", options.get("highlights").slice(0, 2));
                    alert("You've reached the maximum number of highlights.");
                }
            },
            defaultValue: [
                {
                    text: "Placeholder Text",
                    backgroundColor: ACCENT_COLORS.ORCA_PASTEL,
                },
            ],
            subFields: [
                {
                    name: "text",
                    type: "richText",
                    required: true,
                },
                {
                    name: "backgroundColor",
                    type: "string",
                    enum: Object.values(ACCENT_COLORS),
                    defaultValue: ACCENT_COLORS.ORCA_PASTEL,
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: TextHighlight,
    config: TextHighlightBuilderConfig,
};

export default builderConfig;
