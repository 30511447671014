import React from "react";

import { COLOR } from "../component.constants";

export interface Props {
    color: COLOR;
    width?: number;
    height?: number;
    viewBoxWidth?: number;
    viewBoxHeight?: number;
}

const OpeningTimesIcon: React.FC<Props> = ({
    color,
    width,
    height,
    viewBoxWidth,
    viewBoxHeight,
}) => {
    return (
        <svg
            width={width ?? 64}
            height={height ?? 64}
            fill="none"
            viewBox={`0 0 ${viewBoxWidth ?? 64} ${viewBoxHeight ?? 64}`}
        >
            <g fill={color}>
                <path d="M48 64a16 16 0 100-32 16 16 0 000 32zm-2.667-26.667h5.334v8h8v5.334H45.333V37.333z" />
                <path d="M61.333 8H48V2.667a2.667 2.667 0 10-5.333 0V8H21.333V2.667a2.667 2.667 0 10-5.333 0V8H2.667A2.667 2.667 0 000 10.667v48a2.667 2.667 0 002.667 2.666h24V56H5.333V18.667h53.334v10.666H64V10.667A2.667 2.667 0 0061.333 8z" />
            </g>
            <defs>
                <clipPath id="prefix__clip0">
                    <path fill={color} d="M0 0h64v64H0z" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default OpeningTimesIcon;
