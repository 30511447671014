module.exports = function ({ addUtilities }) {
    const newUtilities = {
        ".scrollbar-x-none": {
            overflowX: "scroll",
            scrollbarWidth: "none",
            "-ms-overflow-style": "none",
            "&::-webkit-scrollbar": {
                display: "none",
            },
        },
    };
    addUtilities(newUtilities, {
        variants: ["responsive"],
    });
};
