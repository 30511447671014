import { BuilderConfig } from "../../../builder/types";
import { ALIGNMENT } from "../image-interludes.constants";
import { ImageInterludeHalf, Props } from "./image-interlude-half";

export const ImageInterludeHalfBuilderConfig = {
    name: "Image Interlude Half",
    inputs: [
        {
            name: "alignment",
            type: "string",
            enum: Object.values(ALIGNMENT),
            defaultValue: ALIGNMENT.LEFT,
        },
        {
            name: "imageSmallSq",
            type: "file",
            required: true,
            allowedFileTypes: ["jpeg", "jpg", "png"],
        },
        {
            name: "imageSmallSqAltText",
            type: "string",
            required: true,
            defaultValue: "Small Image",
        },
        {
            name: "imageLargeSq",
            type: "file",
            required: true,
            allowedFileTypes: ["jpeg", "jpg", "png"],
        },
        {
            name: "imageLargeSqAltText",
            type: "string",
            required: true,
            defaultValue: "Large Image",
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: ImageInterludeHalf,
    config: ImageInterludeHalfBuilderConfig,
};

export default builderConfig;
