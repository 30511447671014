import { BuilderConfig } from "../../../builder/types";
import { KINDS, SIZES } from "../../buttons/buttons.constants";
import { PLACEHOLDER_IMAGE_URL } from "../image-text.constants";
import { ImageTextCluster, Props } from "./image-text-cluster";

export const ImageTextClusterBuilderConfig = {
    name: "ImageTextCluster",
    inputs: [
        {
            name: "alignment",
            type: "string",
            enum: ["Left", "Right"],
            defaultValue: "Left",
        },
        {
            name: "header",
            type: "string",
            defaultValue: "Placeholder header",
        },
        {
            name: "bodyText",
            type: "richText",
            defaultValue: "This is placeholder text",
        },
        {
            name: "largeImage",
            type: "file",
            defaultValue: PLACEHOLDER_IMAGE_URL,
            required: true,
            allowedFileTypes: ["jpeg", "png"],
        },
        {
            name: "largeImageAltText",
            type: "string",
            required: true,
            defaultValue: "Image Description",
        },
        {
            name: "smallImageTop",
            type: "file",
            defaultValue: PLACEHOLDER_IMAGE_URL,
            required: true,
            allowedFileTypes: ["jpeg", "png"],
        },
        {
            name: "smallImageTopAltText",
            type: "string",
            required: true,
            defaultValue: "Image Description",
        },
        {
            name: "smallImageBottom",
            type: "file",
            defaultValue: PLACEHOLDER_IMAGE_URL,
            required: true,
            allowedFileTypes: ["jpeg", "png"],
        },
        {
            name: "smallImageBottomAltText",
            type: "string",
            required: true,
            defaultValue: "Image Description",
        },
        {
            name: "ctaProps",
            type: "object",
            required: false,
            defaultValue: {
                disabled: false,
                fluid: true,
                kind: KINDS.PRIMARY,
                label: "Shop now",
                size: SIZES.REGULAR,
                url: "/",
                newTab: false,
                show: true,
            },
            subFields: [
                {
                    name: "label",
                    type: "string",
                },
                {
                    name: "url",
                    type: "string",
                },
                {
                    name: "new tab",
                    type: "boolean",
                },
                {
                    name: "kind",
                    type: "string",
                    enum: Object.values(KINDS),
                },
                {
                    name: "size",
                    type: "string",
                    enum: Object.values(SIZES),
                },
                {
                    name: "fluid",
                    type: "boolean",
                },
                {
                    name: "disabled",
                    type: "boolean",
                },
                {
                    name: "show",
                    type: "boolean",
                },
                {
                    name: "forwardUtm",
                    type: "boolean",
                    defaultValue: false,
                    required: false,
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: ImageTextCluster,
    config: ImageTextClusterBuilderConfig,
};

export default builderConfig;
