import React from "react";

import type { UI_CONTEXT } from "@/analytics/constants";

import { CategoryProps } from "@/interfaces/category-interface";
import { CollectionProps } from "@/interfaces/collection-interface";
import { CollectionV2Props } from "@/interfaces/collection-v2-interface";
import { ProductProps } from "@/interfaces/products-interface";

import { ProductTile } from "./side-product-tile";

type Props = {
    products: Array<ProductProps>;
    uiContext: UI_CONTEXT;
    category: CategoryProps | CollectionProps | CollectionV2Props;
};

export const StackProductTile: React.FC<Props> = (props) => {
    const { products, category, uiContext } = props;
    return (
        <div>
            {products.map((product) => (
                <ProductTile
                    key={product.id}
                    product={product}
                    uiContext={uiContext}
                    category={category}
                />
            ))}
        </div>
    );
};

export const productTilesForCollection = (
    collection: CollectionV2Props,
    uiContext: UI_CONTEXT
): Array<React.ReactElement> => {
    if (collection.products.length < 6) {
        // Too few products for a carousel
        return [];
    }
    const ret_val = [];
    for (let i = 0; i < Math.floor((collection.products.length + 1) / 2); i++) {
        ret_val.push(
            <StackProductTile
                products={collection.products.slice(2 * i, 2 * i + 2)}
                uiContext={uiContext}
                category={collection}
            />
        );
    }
    return ret_val;
};
