import { SHOP_DIRECTORY_API_HOST } from "@/shared/constants";

import { AsyncPropsMapper, BuilderConfig } from "@/builder/types";

import { APICollectionsV2Data, APICollectionV2Props } from "@/interfaces/collection-v2-interface";

import ProductCollectionV2Carousel, { Props } from "./carousel";

const mapper: AsyncPropsMapper<APICollectionsV2Data> = ({ data }) => {
    const { included = [] } = data;
    const allResults = data.data.concat(included);

    return {
        collection: allResults.map((collection: APICollectionV2Props) => ({
            name: collection.attributes.name,
            value: collection.attributes.slug,
        })),
    };
};

const builderConfig: BuilderConfig<Props> = {
    component: ProductCollectionV2Carousel,
    config: {
        name: "Products Carousel v2",
        inputs: [
            {
                name: "slug",
                type: "dynamic-dropdown",
                required: true,
                options: {
                    url: `${SHOP_DIRECTORY_API_HOST}/api/v2/collections?locale={{targeting.locale.0}}`,
                    mapper: mapper.toString(),
                },
            },
            {
                name: "title",
                type: "string",
                required: false,
                defaultValue: "",
            },
            {
                name: "showParentCollectionProducts",
                friendlyName: "Only show products from parent collection",
                type: "boolean",
                required: true,
                defaultValue: false,
            },
            {
                name: "showViewMore",
                friendlyName: "Show view more button",
                type: "boolean",
                required: true,
                defaultValue: true,
            },
            {
                name: "ctaNewTab",
                friendlyName: "View more button opens in a new tab",
                type: "boolean",
                required: true,
                defaultValue: true,
            },
        ],
    },
};

export default builderConfig;
