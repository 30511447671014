import classNames from "classnames/bind";
import React from "react";

import { ACCENT_COLOR } from "../component.constants";
import Grid from "../layout/grid";
import styles from "./contentDivider.module.scss";

const cx = classNames.bind(styles);

type Grid = {
    header: string;
    body: string;
};

export interface Props {
    backgroundColor: ACCENT_COLOR;
    grids: Grid[];
}

export const ContentDivider: React.FC<Props> = (props) => {
    const { backgroundColor, grids } = props;

    const contentDividerClassName = cx({
        [styles.contentDividerContainer]: true,
        [styles[`background${backgroundColor}`]]: true,
    });

    return (
        <div className={contentDividerClassName}>
            {grids.map(({ body, header }, index) => (
                <div className={styles.contentGridChild} key={`content-grid-${index}`}>
                    <h3 className={styles.contentGridHeader}>{header}</h3>
                    <div
                        className={styles.contentGridBody}
                        dangerouslySetInnerHTML={{ __html: body }}
                    />
                </div>
            ))}
        </div>
    );
};
