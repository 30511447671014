import { DEFAULT_SEARCH_PER_PAGE_VALUE } from "@/shared/constants";

import { axios } from "@/utils/axios";
import { hostForLocale } from "@/utils/locale";
import logger from "@/utils/logger";
import { throwOrReturnDefault } from "@/utils/requests";
import { transformImageLocationUrl } from "@/utils/strings";

import { APICategoryProps, CategoryProps } from "@/interfaces/category-interface";
import { APIProductProps, ProductProps } from "@/interfaces/products-interface";
import { StoreProps, StoresWithCountProps, transformStores } from "@/interfaces/stores-interface";

import { Locale } from "@/types/locale";

const SHOP_DIRECTORY_API_HOST = process.env.NEXT_PUBLIC_SHOP_DIRECTORY_API_HOST;

export const transformProducts = (products: Array<APIProductProps>): Array<ProductProps> => {
    if (!products) {
        return [];
    }
    return products.map(transformProduct);
};

export const transformProduct = (product: APIProductProps): ProductProps => {
    return {
        id: product.attributes.id,
        name: product.attributes.name,
        linkToMerchant: product.attributes.link_to_merchant,
        priceFormatted: product.attributes.price_formatted,
        originalPriceFormatted: product.attributes.original_price_formatted,
        inStock: product.attributes.in_stock,
        storeId: product.attributes.store_id,
        mainImageUrl: `${transformImageLocationUrl(product.attributes.main_image_url)}`,
        merchantId: product.attributes.merchant_id,
        merchantName: product.attributes.merchant_name,
        newMerchantLogoUrl: `${transformImageLocationUrl(
            product.attributes.new_merchant_logo_url
        )}`,
        merchantLogoUrl: `${transformImageLocationUrl(product.attributes.merchant_logo_url)}`,
        affiliate_network: product.attributes.affiliate_network,
    };
};

export const transformCategory = (category: APICategoryProps): CategoryProps => {
    return {
        id: category.id,
        name: category.attributes.name,
        slug: category.attributes.slug,
        parentId: category.attributes.parent_id?.toString() || null,
        description: category.attributes.page_description,
        metaTitle: category.attributes.meta_title,
        metaDescription: category.attributes.meta_description,
        rootPageUrl: `/categories/${category.attributes.slug}`,
    };
};

export const transformCategories = (categories: Array<APICategoryProps>): Array<CategoryProps> => {
    if (!categories) {
        return [];
    }
    return categories.map(transformCategory);
};

export const fetchCategory = async (
    locale: Locale,
    slug: string | undefined
): Promise<CategoryProps> => {
    if (!slug) {
        return null;
    }

    const url = `${SHOP_DIRECTORY_API_HOST}/api/v1/categories/${slug}?locale=${locale}`;

    try {
        const categoryResponse = await axios(url);
        const { data } = categoryResponse.data;
        return data ? transformCategory(data) : null;
    } catch (ex) {
        const errorDescription = `failed to fetch category to url=${url}`;
        logger.logException(ex, errorDescription);
        return throwOrReturnDefault(ex);
    }
};

export const fetchCategoryStores = async (
    locale: Locale,
    slug: string | undefined
): Promise<Array<StoreProps>> => {
    if (!slug) {
        return null;
    }

    const url = `${SHOP_DIRECTORY_API_HOST}/api/v1/categories/${slug}/stores?locale=${locale}`;
    try {
        const categoryResponse = await axios(url);
        const { data } = categoryResponse.data;
        return transformStores(data);
    } catch (err) {
        logger.logException(err, `failed to fetch category stores url=${url}`);
        return [];
    }
};

export const fetchStoresSearch = async (
    locale: Locale,
    searchQuery: string
): Promise<StoresWithCountProps> => {
    const host = hostForLocale(locale);
    const url = `${host}/api/stores/search?${searchQuery}&per_page=${DEFAULT_SEARCH_PER_PAGE_VALUE}&sort_by=relevance`;

    try {
        const response = await axios.get<StoresWithCountProps>(url);
        return response.data;
    } catch (err) {
        logger.logException(err, `failed to search stores url=${url}`);
        return { totalCount: 0, data: [] };
    }
};

export const fetchCategoryProducts = async (
    locale: Locale,
    slug: string
): Promise<Array<ProductProps>> => {
    const url = `${SHOP_DIRECTORY_API_HOST}/api/v1/categories/${slug}/products?locale=${locale}`;

    try {
        const categoryResponse = await axios(url);
        const { data } = categoryResponse.data;
        return transformProducts(data);
    } catch (err) {
        logger.logException(err, `failed to fetch category products url=${url}`);
        return [];
    }
};

export const fetchCategories = async (locale: Locale): Promise<Array<CategoryProps>> => {
    const categoryEndpoint = `${SHOP_DIRECTORY_API_HOST}/api/v1/categories?locale=${locale}&per_page=1000`;
    try {
        const response = await axios(categoryEndpoint);
        if (response.status === 200) {
            const { data } = response.data;
            return transformCategories(data);
        } else {
            return response.data;
        }
    } catch (err) {
        logger.logException(err, `failed to fetch categories categoryEndpoint=${categoryEndpoint}`);
        return [];
    }
};

export const getParentCategory = async (
    locale: Locale,
    parentId: string
): Promise<CategoryProps | null> => {
    let result = null;
    if (parentId) {
        const categories = await fetchCategories(locale);
        const parentCategory = categories.find((category) => category.id === parentId);
        if (parentCategory) {
            result = parentCategory;
        }
    }

    return result;
};

const fetchChildCategorySlugs = async (
    locale: Locale,
    subCategorySlugs: Array<string>
): Promise<Array<string>> => {
    const childCategorySlugs = [];

    for (const slug of subCategorySlugs) {
        const url = `${SHOP_DIRECTORY_API_HOST}/api/v1/categories/${slug}?locale=${locale}`;

        const response = await axios(url);
        const { included = [] } = response.data;

        const includedSlugs = included
            .map((category) => category.attributes.slug)
            .filter((slug) => slug?.length > 0);

        childCategorySlugs.push(...includedSlugs);
    }

    return childCategorySlugs;
};

export const fetchAllCategorySlugs = async (locale: Locale): Promise<Array<string>> => {
    const url = `${SHOP_DIRECTORY_API_HOST}/api/v1/categories?locale=${locale}`;
    try {
        const response = await axios(url);
        const { data, included = [] } = response.data;

        const topLevelCategorySlugs = data
            .map((category) => category.attributes.slug)
            .filter((slug) => slug?.length > 0);

        const subCategorySlugs = included
            .map((category) => category.attributes.slug)
            .filter((slug) => slug?.length > 0);

        const childCategorySlugs = await fetchChildCategorySlugs(locale, subCategorySlugs);

        const uniqueCategorySlugs = new Set([
            ...topLevelCategorySlugs,
            ...subCategorySlugs,
            ...childCategorySlugs,
        ]);

        return [...uniqueCategorySlugs];
    } catch (ex) {
        logger.logException(ex, `failed to fetch all category slugs url=${url}`);
        throw ex;
    }
};
