import React from "react";

import styles from "../text.module.scss";

export type Props = {
    body: string;
};

const TextBody: React.FC<Props> = ({ body }) => {
    return (
        <div className={`${styles.body} builder-text`} dangerouslySetInnerHTML={{ __html: body }} />
    );
};

export default TextBody;
