import { DateTime } from "luxon";
import { useTranslation } from "next-i18next";
import React, { useEffect, useState, useMemo } from "react";

import { SIZES } from "@/shared/constants";

import type { UI_CONTEXT } from "@/analytics/constants";
import EVENTS from "@/analytics/events";

import { KINDS as BUTTON_KINDS } from "@/components/buttons/buttons.constants";
import LinkButton from "@/components/buttons/link-button";

import styles from "./countdown.module.scss";

export interface Props {
    heading: string;
    body: string;
    endDate: string;
    newTab?: boolean;
    ctaLabel?: string;
    url?: string;
    uiContext: UI_CONTEXT;
}

const zeroPadToTwo = (number: number): string => number.toString().padStart(2, "0");

type DurationObject = {
    hours: number;
    minutes: number;
    seconds: number;
};

export const timeUntil = (jsDate: Date, now: Date = new Date()): DurationObject => {
    const endDate = DateTime.fromJSDate(jsDate);
    const timeLeft = endDate
        .diff(DateTime.fromJSDate(now), ["hours", "minutes", "seconds"])
        .toObject();
    return {
        hours: Math.max(0, timeLeft.hours),
        minutes: Math.max(0, timeLeft.minutes),
        seconds: Math.max(0, timeLeft.seconds),
    };
};
const Countdown: React.FC<Props> = ({
    heading,
    body,
    endDate,
    newTab,
    ctaLabel,
    url,
    uiContext,
}) => {
    const { t } = useTranslation();
    const endDateTime = useMemo(() => new Date(endDate), [endDate]);
    const [durationObject, setDurationObject] = useState<DurationObject>(timeUntil(endDateTime));

    useEffect(() => {
        setDurationObject(timeUntil(endDateTime));
        const id = setInterval(() => {
            const timeLeft = timeUntil(endDateTime);
            if (timeLeft.hours <= 0 && timeLeft.minutes <= 0 && timeLeft.seconds <= 0) {
                clearInterval(id);
            }
            setDurationObject(timeLeft);
        }, 1000);
        return () => clearInterval(id);
    }, [endDateTime]);

    const hoursLeft = durationObject.hours;
    const minutesLeft = durationObject.minutes;
    const finalSecondsLeft = Math.floor(durationObject.seconds);
    const hoursDescription = hoursLeft === 1 ? t("dateTime.hour") : t("dateTime.hours");
    const minutesDescription = minutesLeft === 1 ? t("dateTime.minute") : t("dateTime.minutes");
    const secondsDescription =
        finalSecondsLeft === 1 ? t("dateTime.second") : t("dateTime.seconds");

    return (
        <div className={styles.countdown}>
            <div className={styles.textContainer}>
                <h2 className={styles.heading}>{heading}</h2>
                <div className={styles.bodyContainer}>
                    <p className={styles.body}>{body}</p>
                    {ctaLabel && url && (
                        <div className={styles.buttonContainer}>
                            <LinkButton
                                show
                                size={SIZES.REGULAR}
                                disabled={false}
                                newTab={newTab}
                                fluid={false}
                                kind={BUTTON_KINDS.WHITE}
                                label={ctaLabel}
                                url={url}
                                analytics={{
                                    eventName: EVENTS.SHOP_DIRECTORY_CLICK,
                                    uiContext,
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.countDownContainer}>
                <div className={styles.unitContainer}>
                    <p className={styles.unitCount}>{zeroPadToTwo(hoursLeft)}</p>
                    <p className={styles.unitName}>{hoursDescription}</p>
                </div>
                <div className={styles.unitContainer}>
                    <p className={styles.unitCount}>{zeroPadToTwo(minutesLeft)}</p>
                    <p className={styles.unitName}>{minutesDescription}</p>
                </div>
                <div className={styles.unitContainer}>
                    <p className={styles.unitCount}>{zeroPadToTwo(finalSecondsLeft)}</p>
                    <p className={styles.unitName}>{secondsDescription}</p>
                </div>
            </div>
        </div>
    );
};

export default Countdown;
