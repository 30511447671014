import classNames from "classnames/bind";
import Link from "next/link";
import React from "react";

import logger from "@/utils/logger";

import { ACCENT_COLORS, COLORS } from "@/components/component.constants";
import CloseIcon from "@/components/icons/close-icon";

import { useCookies } from "@/hooks/useCookies";

import styles from "./messageBanner.module.scss";

const cx = classNames.bind(styles);

export interface Props {
    text: string;
    backgroundColor: string;
    messageLink?: string;
    newTab?: boolean;
    cookieName: string;
    builderState?: Record<string, Record<string, never>>;
}
export const MessageBanner: React.FC<Props> = ({
    text,
    backgroundColor,
    messageLink,
    newTab,
    cookieName,
    builderState,
}) => {
    if (!text || !cookieName) {
        const errorMessage = `Message banner received bad params, text=${
            text || "''"
        }, cookieName=${cookieName || "''"}`;

        logger.logError(errorMessage);
    }

    const { showCurrentBanner, hideCurrentBanner } = builderState?.state ?? {
        showCurrentBanner: true,
        hideCurrentBanner: () => null,
    };

    const [showBanner, setShowBanner] = useCookies(cookieName, "true");

    const dismissMessage = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        hideCurrentBanner();
        setShowBanner("false");
    };

    const messageBannerContainerClassName = cx({
        [styles.messageBannerContainer]: true,
        [styles[`background${backgroundColor}`]]: true,
    });

    const iconColor = backgroundColor === ACCENT_COLORS.MINT ? COLORS["black"] : COLORS["white"];
    const messageBannerContainer = (
        <div className={messageBannerContainerClassName}>
            {messageLink ? (
                <Link prefetch={false} href={messageLink}>
                    <a target={newTab ? "_blank" : null} className={styles.messageContainer}>
                        <div dangerouslySetInnerHTML={{ __html: text }} />
                    </a>
                </Link>
            ) : (
                <div className={styles.messageContainer}>
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                </div>
            )}
            <div className={styles.closeIconContainer}>
                {
                    <span
                        className={styles.closeIcon}
                        role="button"
                        tabIndex={0}
                        aria-label="Close"
                        onClick={dismissMessage}
                    >
                        <CloseIcon
                            color={iconColor}
                            width={16}
                            height={16}
                            viewBoxWidth={24}
                            viewBoxHeight={24}
                        />
                    </span>
                }
            </div>
        </div>
    );

    if (showBanner === "false" || !showCurrentBanner) {
        return null;
    }

    return messageBannerContainer;
};
