import React from "react";

import { COLOR } from "../component.constants";

export interface Props {
    color: COLOR;
    width?: number;
    height?: number;
    viewBoxWidth?: number;
    viewBoxHeight?: number;
}

const BankIcon: React.FC<Props> = ({ color, width, height, viewBoxWidth, viewBoxHeight }) => {
    return (
        <svg
            width={width ?? 32}
            height={height ?? 32}
            fill="none"
            viewBox={`0 0 ${viewBoxWidth ?? 24} ${viewBoxHeight ?? 24}`}
        >
            <path
                fill={color}
                d="M23 21H22V13H20V21H16V13H14V21H10V13H8V21H4V13H2V21H1C0.448 21 0 21.447 0 22C0 22.553 0.448 23 1 23H23C23.552 23 24 22.553 24 22C24 21.447 23.552 21 23 21Z"
            />
            <path
                fill={color}
                d="M23.479 6.122L12.479 0.122C12.18 -0.0400003 11.82 -0.0400003 11.521 0.122L0.521 6.122C0.2 6.297 0 6.634 0 7V10C0 10.553 0.448 11 1 11H23C23.552 11 24 10.553 24 10V7C24 6.634 23.8 6.297 23.479 6.122ZM12 8C10.895 8 10 7.105 10 6C10 4.895 10.895 4 12 4C13.105 4 14 4.895 14 6C14 7.105 13.105 8 12 8Z"
            />
        </svg>
    );
};

export default BankIcon;
