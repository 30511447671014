import classNames from "classnames/bind";
import React from "react";

import { ACCENT_COLOR, SIZE } from "../../component.constants";
import styles from "./textHighlight.module.scss";

const cx = classNames.bind(styles);

type Highlight = {
    backgroundColor: ACCENT_COLOR;
    text: string;
};

export interface Props {
    highlights: Highlight[];
    size: SIZE;
}

export const TextHighlight: React.FC<Props> = ({ highlights, size }) => {
    return (
        <div className={styles.textHighlightContainer}>
            {highlights.map(({ backgroundColor, text }, index) => {
                const textHighlightContainerClassName = cx({
                    [styles.container]: true,
                    [styles[`background${backgroundColor}`]]: true,
                    [styles[`container${size}`]]: true,
                });
                return (
                    <div
                        className={textHighlightContainerClassName}
                        key={`text-highlight-${index}`}
                    >
                        <div
                            className={styles.textHighlightContent}
                            dangerouslySetInnerHTML={{ __html: text }}
                        />
                    </div>
                );
            })}
        </div>
    );
};
