import classNames from "classnames/bind";
import React, { ReactNode } from "react";

import Grid from "../../components/layout/grid";
import { ACCENT_COLOR } from "../component.constants";
import styles from "./splitter.module.scss";

export interface Props {
    title: string;
    subtitle: string;
    accentColor: ACCENT_COLOR;
    children: ReactNode;
    fullWidth: boolean;
}

const cx = classNames.bind(styles);

const Splitter: React.FC<Props> = (props: Props) => {
    const { title, subtitle, children, accentColor, fullWidth } = props;

    const splitterContainerClassName = cx({
        [styles.splitterContainer]: true,
        [styles[`accent${accentColor}`]]: true,
    });

    return (
        <div className={splitterContainerClassName}>
            <Grid fullWidth={fullWidth}>
                <h1 className={styles.title}>{title}</h1>
                <h2 className={styles.subtitle}>{subtitle}</h2>
                {children}
            </Grid>
        </div>
    );
};

export default Splitter;
