import classNames from "classnames/bind";
import React from "react";

import { ALIGNMENT } from "../image-interludes.constants";
import styles from "./imageInterludeHalf.module.scss";

const cx = classNames.bind(styles);

export interface Props {
    alignment: ALIGNMENT;
    imageLargeSq: string;
    imageLargeSqAltText: string;
    imageSmallSq: string;
    imageSmallSqAltText: string;
}

export const ImageInterludeHalf: React.FC<Props> = (props: Props) => {
    const { alignment, imageLargeSq, imageLargeSqAltText, imageSmallSq, imageSmallSqAltText } =
        props;

    const imageInterludeContainerClassName = cx({
        [styles.imageInterludeContainer]: true,
        [styles.imageInterludeContainerLeft]: alignment === ALIGNMENT.LEFT,
        [styles.imageInterludeContainerRight]: alignment === ALIGNMENT.RIGHT,
    });

    const imageLargeContainerClassName = cx({
        [styles.squareImageContainer]: true,
        [styles.squareImageContainerLarge]: true,
    });

    const imageSmallSmContainerClassName = cx({
        [styles.squareImageContainer]: true,
        [styles.squareImageContainerSmall]: true,
    });

    return (
        <div className={imageInterludeContainerClassName}>
            <div className={imageLargeContainerClassName}>
                <img src={imageLargeSq} alt={imageLargeSqAltText} />
            </div>
            <div className={imageSmallSmContainerClassName}>
                <img src={imageSmallSq} alt={imageSmallSqAltText} />
            </div>
        </div>
    );
};
