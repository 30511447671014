import { BuilderConfig } from "../../builder/types";
import { ACCENT_COLORS } from "../component.constants";
import { EducationGrid, Props } from "./education-grids";
import { Props as EducationGridProps } from "./education-grids";
import { KINDS } from "./education-grids.constants";

export const EducationGridBuilderConfig = {
    name: "Education Grid",
    inputs: [
        {
            name: "kind",
            type: "string",
            enum: Object.values(KINDS),
            defaultValue: KINDS.FULL,
        },
        {
            name: "bgColor",
            type: "string",
            enum: Object.values(ACCENT_COLORS),
            defaultValue: ACCENT_COLORS.NONE,
        },
        {
            name: "grids",
            type: "list",
            required: true,
            onChange: (options: Map<string, EducationGridProps["grids"]>): void => {
                if (options.get("grids").length > 6) {
                    options.set("grids", options.get("grids").slice(0, 6));
                    alert("You've reached the maximum number of grids.");
                }
            },
            defaultValue: [
                {
                    subheader: "Placeholder Subheader",
                    textBody: "Placeholder Text Body",
                    image: "",
                    altText: "Image Alt Text",
                },
                {
                    subheader: "Placeholder Subheader",
                    textBody: "Placeholder Text Body",
                    image: "",
                    altText: "Image Alt Text",
                },
            ],
            subFields: [
                {
                    name: "subheader",
                    type: "string",
                },
                {
                    name: "textBody",
                    type: "string",
                },
                {
                    name: "image",
                    type: "file",
                    required: true,
                    allowedFileTypes: ["jpeg", "jpg", "png"],
                },
                {
                    name: "altText",
                    type: "string",
                    required: true,
                    defaultValue: "Education Grid Image",
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: EducationGrid,
    config: EducationGridBuilderConfig,
};

export default builderConfig;
