import { GOOGLE_MAPS_DIRECTIONS_URL } from "../shared/constants";
import { SHOP_DIRECTORY_API_HOST } from "../shared/constants";
import { currencyCodeToCurrencySymbolMap, localeCurrencySymbolMap } from "../translations/config";
import { Locale } from "../types/locale";

export const truncateWithEllipsis = (text: string, textLength: number): string => {
    return text.length <= textLength ? text : `${text.substring(0, textLength)}...`;
};

export const transformStoreLocationUrl = (url: string): string => {
    const regEx = /(https?:\/\/)(.*?)(\/)/g;
    return url.replace(regEx, "");
};

export const transformImageLocationUrl = (url: string): string => {
    const regEx = /^(http|\/\/).*/i;
    if (!url) {
        return url;
    }
    if (url.match(regEx) == null) {
        return SHOP_DIRECTORY_API_HOST + url;
    }
    return url;
};

export const generatePriceFromCurrencyCode = (amount: number, currencyCode: string): string => {
    const currencySymbol = currencyCodeToCurrencySymbolMap[currencyCode];
    return currencySymbol + amount.toFixed(2);
};

export const generatePriceFromLocale = (amount: number, locale: Locale): string => {
    const currencySymbol = localeCurrencySymbolMap[locale];
    return currencySymbol + amount.toFixed(2);
};

export const convertPriceToNumber = (price: string): number => {
    const strArr = Array.from(price);
    strArr.shift();
    return Number(strArr.join(""));
};

export const concatAddress = (address: string) => {
    const encodedAddress = address.replace(" ", "+");
    return GOOGLE_MAPS_DIRECTIONS_URL.concat(encodedAddress);
};

export const getNormalizedUrl = (url: string) => {
    const normalizedUrl = url.startsWith("http")
        ? url
        : `https:${url.startsWith(`//`) ? url : `//${url}`}`;

    return normalizedUrl;
};

export const encodeImageUrl = (url = "") => {
    const imageUrl = url
        .replace(/\\/g, "\\\\")
        .replace(/\(/g, "%28")
        .replace(/\)/g, "%29")
        .replace(/'/g, "\\'");
    return imageUrl;
};
